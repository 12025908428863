<template>
  <div id="app">
    <meta :name="domain" content="Resort, Hotel, Thermal, Villa, Halal, Islamisch, Islami, Türkei, Antalya"/>
    <title>Finden Sie jetzt ihre Traumhotel! "Halal", "Resort", "Hotel", "Thermal", "Villa"</title>
    <!--    <title>-->
    <!--      İslami Tatil Yerleri,-->
    <!--      İslami Oteller,-->
    <!--      Tesettur oteller-->
    <!--      İslami Otel,-->
    <!--      İslamiotel,-->
    <!--      Muhafazakar Tatil Yerleri,-->
    <!--      Muhafazakar Oteller,-->
    <!--      İslami Kaplıcalar.-->
    <!--      Efetour-->
    <!--      halal holiday-->
    <!--      halal reisen-->
    <!--      Helal tatil-->
    <!--      Balayi villa-->
    <!--      Korunakli villa-->
    <!--    </title>-->

    <!--    <div style="height: 60px; background: red; text-align: center; color: white; font-size: 25px">-->
    <!--      Testumgebung!-->
    <!--    </div>-->

    <div class="div-dsgvo-app" v-if="this.dsgvo !== 'true'">
      <label class="label-dsgvo-app">
        Diese Website nutzt Cookies, um Ihnen die bestmögliche Funktionalität zu bieten. Durch die weitere Nutzung der
        Webseite stimmen Sie der Verwendung zu.
      </label>
      <v-btn class="btn-dsgvo vbtn-linear-gradient" @click="confirm">Zustimmen</v-btn>
      <v-btn text class="btn-dsgvo" @click="zuDSGVO">
        Unsere Datenschutzerklärung<br> finden Sie hier
      </v-btn>

    </div>

    <v-app
    >
      <div v-if="userinfo.kontakt">
        <Header
            :pLogo="logo"
            :tel="userinfo.kontakt.tel"
            :mobile="userinfo.kontakt.mobile"
            :lang="lang"
            :pDomain="domain"
        />
      </div>

      <div id="nav">

      </div>
      <router-view
          id="router-view"
          :kontakt="userinfo.kontakt"
          :lang="lang"
          :pLogo="logo"
          :pDomain="domain"
          :pRechtliches="rechtliches"
      />

      <h1 style="display: none">{{domain}}</h1>
      <div v-if="domain==='holidaytimes.de'">
        <Footer3
            v-if="userinfo.kontakt"
            :kontakt="userinfo.kontakt"
            :pDomain="domain"
        />
      </div>

      <div v-if="domain==='efehotels.com'||domain==='ceeyuu.de'">
        <Footer2
            v-if="userinfo.kontakt"
            :kontakt="userinfo.kontakt"
            :pDomain="domain"
        />
      </div>

    </v-app>
  </div>

</template>

<script>
import Header from "./components/headerfooter/Header";
import Footer from "./components/headerfooter/Footer";
import Footer2 from "./components/headerfooter/Footer2";
import Footer3 from "./components/headerfooter/Footer3";
import CeeYuuFooter2 from "./components/headerfooter/CeeYuuFooter2";
import {getIDFromAdminUser, getLogo} from "./util/DBUtil";
import {getKontaktFromUserinfoByUserId, getRechtlichesByID} from "./util/userinfoDBUtil";
import {getdomain} from "./util/settingsUtil";



export default {
  components: {
    Footer,
    Footer2,
    Footer3,
    Header,
    CeeYuuFooter2
  },
  data() {
    return {
      dsgvo: "",
      mode: "",
      logo: "",
      userinfo: "",
      lang: 'DE',


      halalHotels: "",
      settings: "",

      showSkeleton: true,


      domain: "",
      rechtliches: ""

    }
  },
  methods: {
    zuDSGVO() {
      this.$router.push({path: "privacy"});
      window.scrollTo(0, 0);
    },
    confirm() {
      this.$cookie.set('_confirm', 'true', 365);
      this.dsgvo = this.$cookie.get('_confirm');
    }
  },

  async created() {


    this.dsgvo = this.$cookie.get('_confirm');

    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.lang);
    }
    if (!localStorage.getItem("currency")) {
      localStorage.setItem("currency", "EUR");
    }

    let user = await getIDFromAdminUser();
    let logo = await getLogo(user._id);
    this.logo = process.env.VUE_APP_IMAGES + logo.logo.picture;
    this.userinfo = await getKontaktFromUserinfoByUserId(user._id);
    this.rechtliches = await getRechtlichesByID(user._id);

    this.domain = await getdomain();


  },
  mounted() {
    window.scrollTo(0, 0);
  }
}

</script>


<style>
@import './index.css';
@import 'css/StartALT.css';

</style>
