<template>
  <div class="div-booking">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="div-background-header"/>

    <v-overlay :value="loadingb" opacity="0.65">

      <div style="text-align: center">
        <h2 style="color: white; font-size: 35px;">{{ pDomain }}</h2>
        <v-progress-circular
            v-if="loadingb"
            indeterminate
            color="primary"
            :size="50"
            :width="7"

        />
      </div>
    </v-overlay>

    <div class="div-body-booking">

      <div class="div-body-booking1">


        <!-- ------------------------------------------------ Links -------------------------------------------------->
        <div class="div-body-left-booking" v-if="halalPlace !== undefined && halalPlace.place">


          <!-- ------------------------------------------------ Hotelinfo -------------------------------------------------->
          <div class="div-guest-booking">
            <div class="div-hotelname-booking">
              <div>
                <img v-if="halalPlace.place"
                     class="img-halalplace-booking"
                     :src='halalPlace.place.photo'
                     alt="">
              </div>
              <div class="div-hotelname-booking1">

                <v-rating
                    v-if="halalPlace.place "
                    v-model="halalPlace.place.stars"
                    background-color="transparent"
                    color="#FFDF00"
                    small
                    readonly
                />

                <label v-if="halalPlace.place" class="label-placename-booking">{{ halalPlace.place.name }}</label>

                <div class="div-location-booking">
                  <v-icon color="black" small>mdi-map-marker-radius</v-icon>
                  <label v-if="halalPlace.place" style="font-size: 15px">{{ halalPlace.place.location.city }},
                    {{ halalPlace.place.location.subregion }},
                    {{ halalPlace.place.location.country }}
                  </label>
                </div>

              </div>
            </div>

            <div class="div-hotelname-booking1">
              <div class="div-guests-nights-booking">

                <span>
                  {{ anzahlPersonen }} {{ anzahlPersonen === 1 ? $t('Booking.Gast') : $t('Booking.Gaeste') }},
                </span>

                <span>
                  {{ anzahlNaechte }} {{ anzahlNaechte === 1 ? $t('Booking.Nacht') : $t('Booking.Naechte') }}
                </span>

              </div>
              <div>
                <div class="div-checkin-booking">
                  <div>
                    <label style="color: darkgrey;">
                      {{ check_facts[2].title }}
                    </label>
                  </div>
                  <div style="font-weight: bold">
                    {{ selectedstartdate | moment("ddd, DD. MMMM") }}
                  </div>
                  <div>
                    {{ check_facts[2].text }}
                  </div>
                </div>
                <div class="div-checkin-booking">
                  <div>
                    <label style="color: darkgrey;">
                      {{ check_facts[3].title }}
                    </label>
                  </div>
                  <div style="font-weight: bold">
                    {{ selectedenddate | moment("ddd, DD. MMMM") }}
                  </div>
                  <div>
                    {{ check_facts[3].text }}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- ------------------------------------------------ Räume -------------------------------------------------->
          <template v-for="(room,index) in gruppe">

            <div class="div-guest-booking">
              <div class="div-rooms-booking">
                <div class="div-roomtitle-booking">

                  <div v-if="selectedRoom">
                    <img
                        class="img-halalroom-booking"
                        :src='selectedRoom[index].room.photos[0]'
                        alt="">
                  </div>
                  <div>
                    <div v-if="gruppe.length>1">Gruppe {{ index + 1 }}</div>
                    <h3>
                      {{ selectedRoom[index].room.name }}
                    </h3>
                    <div class="div-persons-per-room-booking">
                      {{ room.erwachsene + room.kinder }}
                      <v-icon color="black" small style="margin-left: 5px">mdi-close</v-icon>
                      <v-icon color="black">mdi-account</v-icon>
                    </div>

                  </div>
                </div>

                <v-radio-group v-model="ratePlan[index]" v-if="selectedRoom[0].rate_plan.id !== null">
                  <template v-for="(item,n) in selectedRoom[index].rate_plans">

                    <div class="div-roomprice-booking" @click="calculatePrice">
                      <v-radio
                          :key="n"
                          :value="item.rate_plan.id"
                      >
                        <template v-slot:label>
                          <div class="div-roomprice-booking2">
                            <div class="div-roomprice-booking1">
                              {{ formatPrice(item.price) }}
                              <v-icon color="white" small style="margin-left: 5px">{{ currencyicon }}</v-icon>
                            </div>
                            <div>
                              <div style="font-weight: bold; font-size: 18px">
                                {{ item.rate_plan.meal_plan_name }}
                              </div>

                              <v-tooltip bottom close-delay="0">
                                <template v-slot:activator="{ on }">
                                  <div v-on="on" style="display: flex; cursor: pointer;">
                                    <v-icon color="var(--Secondary)" small>mdi-book-cancel</v-icon>
                                    <div class="div-storno-booking">
                                      {{ $t("Booking.Stornierungsbedingungen") }}
                                    </div>
                                  </div>
                                </template>
                                <div class="div-tooltip-storno-booking">
                                  <div v-if="item.rate_plan" style="text-align: start; max-width: 300px;"
                                       v-html="item.rate_plan.cancellation_policy_html">
                                  </div>

                                </div>
                              </v-tooltip>

                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </div>

                  </template>

                </v-radio-group>

                <v-radio-group v-model="ratePlan[index]" v-if="selectedRoom[0].rate_plan.id === null">
                  <template>

                    <div class="div-roomprice-booking" @click="calculatePrice">
                      <v-radio
                          :key="0"
                          :value="0"

                      >
                        <template v-slot:label>
                          <div class="div-roomprice-booking2">
                            <div class="div-roomprice-booking1">
                              {{ formatPrice(selectedRoom[index].price) }}
                              <v-icon color="white" small style="margin-left: 5px">{{ currencyicon }}</v-icon>
                            </div>
                            <div>
                              <div style="font-weight: bold; font-size: 18px">
                                {{ selectedRoom[index].rate_plan.meal_plan_name }}
                              </div>

                              <v-tooltip bottom close-delay="0">
                                <template v-slot:activator="{ on }">
                                  <div v-on="on" style="display: flex; cursor: pointer;">
                                    <v-icon color="var(--Secondary)" small>mdi-book-cancel</v-icon>
                                    <div class="div-storno-booking">
                                      {{ $t("Booking.Stornierungsbedingungen") }}
                                    </div>
                                  </div>
                                </template>
                                <div class="div-tooltip-storno-booking">
                                  <div v-if="selectedRoom[index].rate_plan" style="text-align: start; max-width: 300px;"
                                       v-html="selectedRoom[index].rate_plan.cancellation_policy_html">
                                  </div>

                                </div>
                              </v-tooltip>

                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </div>

                  </template>

                </v-radio-group>

              </div>

            </div>

          </template>


          <!-- ------------------------------------------------ Hauptgast -------------------------------------------------->

          <div class="div-guest-booking">

            <div style="text-align: start;">
              <h3>
                {{ $t("Booking.Hauptgast") }}

              </h3>
            </div>

            <div class="div-guest-name-booking">
              <v-select
                  v-model="hauptgast.anrede"
                  :items="anrede"
                  :item-value="'anredeid'"
                  :label="$t('Booking.Anrede')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkAnrede"
              />
              <v-text-field
                  v-model="hauptgast.vorname"
                  :label="$t('Booking.Vorname')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkVorname"
              />
              <v-text-field
                  v-model="hauptgast.nachname"
                  :label="$t('Booking.Nachname')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkNachname"
              />
            </div>
            <div class="div-guest-mail-booking">
              <v-text-field
                  v-model="hauptgast.email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('Booking.E-Mail')+'*'"
                  outlined
                  prepend-inner-icon="mdi-at"
                  @change="validateInputs"
                  :error="checkEmail"
                  class="vtext-email-booking"


              />
              <v-text-field
                  v-model="hauptgast.tel"
                  type="number"
                  :label="$t('Booking.Telefon')+'*'"
                  outlined
                  prepend-inner-icon="mdi-phone"
                  @change="validateInputs"
                  :error="checkTel"
                  class="vtext-email-booking"

              />
            </div>

            <div class="div-guest-adress-booking">
              <v-text-field
                  v-model="hauptgast.str"
                  :label="$t('Booking.Straße')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkStr"

              />
              <v-text-field
                  v-model="hauptgast.strnr"
                  :label="$t('Booking.Nr.')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkStrnr"

              />
            </div>
            <div class="div-guest-adrzz-booking">

              <v-text-field
                  v-model="hauptgast.addresszusatz"
                  :label="$t('Booking.Adresszusatz')"
                  outlined
              />
            </div>

            <div class="div-guest-plz-booking">
              <v-text-field
                  v-model="hauptgast.plz"
                  :label="$t('Booking.PLZ')+'*'"
                  outlined
                  type="number"
                  @change="validateInputs"
                  :error="checkPlz"
              />

              <v-text-field
                  v-model="hauptgast.ort"
                  :label="$t('Booking.Ort')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkOrt"
              />

            </div>

            <div>
              {{ $t("Booking.ZusatzlicheInfos") }}
            </div>
            <div class="div-notes-booking">
              <v-textarea
                  v-model="notes"
                  :label="$t('Booking.Informationen')"
                  outlined
                  auto-grow

              />
            </div>

            <div style="display: flex; align-items: center">
              <v-checkbox
                  v-model="checkAGB"
              ></v-checkbox>
              <a href="/agb" target="_blank">Ich akzeptiere die Allgemeinen Geschäftsbedingungen*</a>
            </div>


          </div>

        </div>
        <!-- ------------------------------------------------ RECHTS -------------------------------------------------->

        <div class="div-body-right-booking">

          <!-- ------------------------------------------------ TotelPrice Anfang -------------------------------------------------->


          <TotalPrice
              :checkin="selectedstartdate"
              :checkout="selectedenddate"
              :halalPlace="halalPlace"
              :halalPlaceGroups="halalPlace.groups"
              :gruppe="gruppe"
              :selectedRoom="selectedRoom"
              :gesamtpreis="gesamtpreis"
              :checkinput="checkinputs"
              :selectedRatePlan="selectedRatePlan"
              :showbutton="false"
              :eurogesamtpreis="price_eur"
              :europreisprogruppe="price_per_group"
              :trygesamtpreis="price_try"
              :trypreisprogruppe="price_per_group_try"
              :totalprice_int="totalprice_int"
              :key="totalprice_int"
          >

          </TotalPrice>


          <!-- ------------------------------------------------ TotelPrice ENDE -------------------------------------------------->


          <!-- ------------------------------------------------ Hinweise -------------------------------------------------->

          <div class="div-guest-booking" id="hinweise-booking" style="text-align: start"
               v-for="(cancle,n) in stornierung">

            Gruppe {{ n + 1 }}
            <h3>
              <v-icon color="error" style="position: unset">mdi-alert</v-icon>
              {{ $t("Booking.Stornierungsbedingungen") }}
            </h3>
            <div v-if="cancle" v-html="cancle"></div>
          </div>
        </div>


      </div>


      <div v-if="checkinputs || !checkAGB" class="div-book-booking">
        <h3>
          {{ $t("Booking.Pflichtfelder") }}
        </h3>

        <v-btn
            @click="notification('Bitte alle Felder ausfüllen!','error')"
            large
            style="color: white"
            class="vbtn-linear-gradient"
        >
          {{ $t("Button.Buchen") }}
        </v-btn>

      </div>


      <div v-if="!checkinputs && checkAGB ">

        <Paypal
            :price="getCurrency()==='TRY' ? price_eur: gesamtpreis"
            :descr="halalPlace.place.name"
            :order_id="getOrderID"
        />

      </div>


    </div>


  </div>

</template>

<script>
import {formatPrice, getCurrency, getCurrencyIcon} from "../util/currency";
import {onePlaceWithParameter, onePlaceWithParameterLang} from "../util/halalapi";
import {getAllAnrede, getoneAnrede} from "../util/Anrede";
import moment from 'moment';
import TotalPrice from "../components/card/TotalPrice";
import {postBooking} from '../util/bookingUtil';
import {postOrder} from "../util/orderUtil";
import {generateReservierungsnr} from "../util/GenerateId";
import Paypal from "../components/paypal/Paypal";
import {getOHPbyID} from "../util/ownhalalprovisionUtil";
import {getApprovalSettings, gethpr} from "../util/settingsUtil";
import {getKontaktFromUserinfoByUserId} from "../util/userinfoDBUtil";
import {
  MailToagencybyagency3,
  MailTocustomerbevorconfirmation7,
  MailTocustomerbycustomer1,
  MailTohotelbyall4
} from "../util/mailSend";
import {getAdminUser, getIDFromAdminUser} from "../util/DBUtil";
import {getGroups} from "../util/util";

moment.locale('de');


export default {
  name: "Booking",
  props: [
    'pLogo',
    'pDomain',
    'pRechtliches'
  ],
  components: {
    TotalPrice,
    Paypal
  },
  data() {
    return {
      lang: "",
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      loadingb: true,


      currencyicon: getCurrencyIcon(),
      ratePlan: [],
      hb_facts: [],
      place_adress: "",

      halalPlace: {},
      halalRoom: {},
      gruppe: [],
      anzahlPersonen: 0,
      anzahlNaechte: 0,

      checkin: "",
      checkout: "",
      groups: "",
      selectedstartdate: "",
      selectedenddate: "",


      gesamtpreis: 0,
      gesamtpreis_try: 0,

      selectedRoom: [{text: "Kein Raum ausgewählt!"}],
      selectedRatePlan: [{name: "", preis: 0, stornierung: ""}],
      stornierung: [],

      order_id: "",
      orid: "",
      OHP: "",


      anrede: [],
      hauptgast: {
        anrede: "",
        vorname: "",
        nachname: "",
        // email: "info@ceeyuu.de",
        email: "",
        tel: "",
        str: "",
        strnr: "",
        plz: "",
        ort: "",
        addresszusatz: ""
      },
      notes: "",
      hotel_owner: "",
      order_approval: "",


      checkinputs: true,
      checkAnrede: false,
      checkVorname: false,
      checkNachname: false,
      checkEmail: false,
      checkTel: false,
      checkStr: false,
      checkStrnr: false,
      checkPlz: false,
      checkOrt: false,
      checkAGB: false,


      place_usd: "",
      place_eur: "",
      place_try: "",
      offer_eur: [],
      offer_try: [],
      price_eur: 0,
      price_try: 0,
      price_per_group: 0,
      price_per_group_try: 0,
      totalprice_int: 0,


      userinfo: "",
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },

      check_facts: [
        {propertyType: false, title: "", text: ""},
        {stars: false, title: "", text: ""},
        {checkin: false, title: "", text: ""},
        {checkout: false, title: "", text: ""},
        {language: false, title: "", text: ""},
        {location: false, title: "", text: ""},
      ]
    };
  },
  computed: {
    getOrderID() {
      return this.orid;
    }
  },
  methods: {
    getCurrency,
    getCurrencyIcon,
    formatPrice,
    getoneAnrede,
    getGroups,
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    zuPlace(id) {
      this.$router.push({path: 'Place', query: {id: id}});
      window.scrollTo(0, 0);
    },

    calculatePrice() {
      let gprice = 0;
      this.selectedRatePlan = [];
      this.stornierung = [];


      this.ratePlan.map((item, index) => {
        if (item !== null && this.selectedRoom[index].rate_plans[0].rate_plan.id === null) {
          this.stornierung.push(this.selectedRoom[index].rate_plans[item].rate_plan.cancellation_policy_html);
          gprice += this.selectedRoom[index].rate_plans[item].price;
          this.selectedRatePlan.push({
            name: this.selectedRoom[index].rate_plans[item].rate_plan.meal_plan_name,
            // id: this.ratePlan[index],
            id: "",
            preis: this.selectedRoom[index].rate_plans[item].price,
            stornierung: this.selectedRoom[index].rate_plans[item].rate_plan.cancellation_policy_html
          });
        }
        if (item !== null && this.selectedRoom[index].rate_plans[0].rate_plan.id !== null) {
          this.selectedRoom[index].rate_plans.map(it => {
            if (it.rate_plan.id === item) {
              gprice += it.price;
              this.stornierung.push(it.rate_plan.cancellation_policy_html);
              this.selectedRatePlan.push({
                name: it.rate_plan.meal_plan_name,
                id: this.ratePlan[index],
                preis: it.price,
                stornierung: it.rate_plan.cancellation_policy_html
              });
            }
          })

        }
      })
      if (this.place_eur !== "") {

        this.offer_eur = [];
        let tmpSelectedRoom = [];
        this.selectedRoom.map(item => {
          tmpSelectedRoom.push({value: item, found: false})
        })

        this.selectedRoom.map((item, index) => {
          let rid = tmpSelectedRoom[index];

          this.place_eur.groups.map((it, i) => {
            if (index === i) {
              it.offers.map((offer, oindex) => {
                if (offer.room.id === rid.value.room.id) {

                  if (rid.found === false) {
                    this.offer_eur.push(offer);

                    rid.found = true;
                  }


                }
              })
            }
          })

        })
      }

      if (this.place_eur !== "") {
        let sum = 0;
        let price_per_group = [];
        let tmpselectedRatePlan = [];
        this.selectedRatePlan.map(item => {
          tmpselectedRatePlan.push({value: item, found: false})
        })


        this.selectedRatePlan.map((item, index) => {

          if (item.id !== "") {

            this.offer_eur[index].rate_plans.map((r_plan, rateindex) => {

              if (r_plan.rate_plan.id === tmpselectedRatePlan[index].value.id) {

                if (tmpselectedRatePlan[index].found === false) {

                  sum += r_plan.price;
                  price_per_group.push({price: r_plan.price});
                  tmpselectedRatePlan[index].found = true;
                }
              }

            })
          } else {

            sum += this.offer_eur[index].price;
            price_per_group.push({price: this.offer_eur[index].price});
          }

        })


        this.price_eur = sum;
        this.price_per_group = price_per_group;
      }


      this.gesamtpreis = gprice;


      if (this.halalPlace.place.location.country === "Türkei" || this.halalPlace.place.location.country === "Türkiye" || this.halalPlace.place.location.country === "Turkey") {
        this.calculatePriceTRY();
      }


      this.$forceUpdate();
    },

    calculatePriceTRY() {


      if (this.place_try !== "") {

        this.offer_try = [];
        let tmpSelectedRoom = [];
        this.selectedRoom.map(item => {
          tmpSelectedRoom.push({value: item, found: false})
        })

        this.selectedRoom.map((item, index) => {
          let rid = tmpSelectedRoom[index];

          this.place_try.groups.map((it, i) => {
            if (index === i) {
              it.offers.map((offer, oindex) => {
                if (offer.room.id === rid.value.room.id) {

                  if (rid.found === false) {
                    this.offer_try.push(offer);

                    rid.found = true;
                  }


                }
              })
            }
          })

        })
      }

      if (this.place_try !== "") {
        let sum = 0;
        let price_per_group = [];
        let tmpselectedRatePlan = [];
        this.selectedRatePlan.map(item => {
          tmpselectedRatePlan.push({value: item, found: false})
        })


        this.selectedRatePlan.map((item, index) => {

          this.offer_try[index].rate_plans.map((r_plan, rateindex) => {

            if (r_plan.rate_plan.id === tmpselectedRatePlan[index].value.id) {

              if (tmpselectedRatePlan[index].found === false) {

                sum += r_plan.price;
                price_per_group.push({price: r_plan.price});
                tmpselectedRatePlan[index].found = true;
              }
            }
          })

        })


        this.price_try = sum;
        this.price_per_group_try = price_per_group;
      }


      // this.gesamtpreis_try = gprice;
      this.$forceUpdate();
    },


    checkFacts() {
      this.hb_facts.map(((item, index) => {
        let text = item.split(": ");

        if (text[0] === "Tesis Tipi" || text[0] === "Unterkunftstyp" || text[0] === "Property type") {
          this.check_facts[0].propertyType = true;
          this.check_facts[0].title = text[0];
          this.check_facts[0].text = text[1];
        }

        if (text[0] === "Yıldız sayısı" || text[0] === "Sterne" || text[0] === "Stars") {
          this.check_facts[1].stars = true;
          this.check_facts[1].title = text[0];
          this.check_facts[1].text = text[1];
        }

        if (text[0] === "Giriş" || text[0] === "Check-in") {
          this.check_facts[2].checkin = true;
          this.check_facts[2].title = text[0];
          this.check_facts[2].text = text[1];
        }
        if (text[0] === "Çıkış" || text[0] === "Check-out") {
          this.check_facts[3].checkout = true;
          this.check_facts[3].title = text[0];
          this.check_facts[3].text = text[1];
        }
        if (text[0] === "Konuşulan di̇ller" || text[0] === "Gesprochene Sprachen" || text[0] === "Languages spoken") {
          this.check_facts[4].language = true;
          this.check_facts[4].title = text[0];
          this.check_facts[4].text = text[1];
        }
        if (text[0] === "Konum" || text[0] === "Lage" || text[0] === "Location") {
          this.check_facts[5].location = true;
          this.check_facts[5].title = text[0];
          this.check_facts[5].text = text[1];
        }

      }));
    },

    validateInputs() {
      let validate = true;

      if (this.hauptgast.anrede === "") {
        this.checkAnrede = true;
        validate = false;
      } else {
        this.checkAnrede = false;
      }

      if (validate === true && this.hauptgast.vorname === "") {
        this.checkVorname = true;
        validate = false;
      } else {
        this.checkVorname = false;
      }

      if (validate === true && this.hauptgast.nachname === "") {
        this.checkNachname = true;
        validate = false;
      } else {
        this.checkNachname = false;
      }

      if (validate === true && this.hauptgast.email === "") {
        this.checkEmail = true;
        validate = false;
      } else {
        this.checkEmail = false;
      }


      if (validate === true && this.hauptgast.tel === "") {
        this.checkTel = true;
        validate = false;
      } else {
        this.checkTel = false;
      }


      if (validate === true && this.hauptgast.str === "") {
        this.checkStr = true;
        validate = false;
      } else {
        this.checkStr = false;
      }


      if (validate === true && this.hauptgast.strnr === "") {
        this.checkStrnr = true;
        validate = false;
      } else {
        this.checkStrnr = false
      }


      if (validate === true && this.hauptgast.plz === "") {
        this.checkPlz = true;
        validate = false;
      } else {
        this.checkPlz = false;
      }


      if (validate === true && this.hauptgast.ort === "") {
        this.checkOrt = true;
        validate = false;
      } else {
        this.checkOrt = false;
      }


      if (validate === true) {
        this.checkinputs = false;
      }

      if (validate === false) {
        this.checkinputs = true;
      }

    },


    async setbooking(paypalresponse, payed_by) {


      let accommodations = [];
      this.gruppe.map((item, index) => {
        let erw = item.erwachsene + "";
        let kind = "";
        if (item.kinderalter.length > 0) {
          item.kinderalter.map((it, i) => {
            kind += it.age;
            if (item.kinderalter.length > (i)) {
              kind += ",";
            }
          });

        }
        let g = "";
        if (kind !== "") {
          g = erw + "," + kind;
        } else {
          g = erw;
        }
        accommodations.push({
          group: g,
          room_id: this.selectedRoom[index].room.id,

          rate_plan_id: this.selectedRatePlan[index].id

        });

        accommodations.map(item => {
              if (item.rate_plan_id === null) {
                item.rate_plan_id = 0
              }
            }
        )

      })
      if (this.lang === "TR") {
        this.lang = "DE";
      }
      let obj = {
        test: true,
        title: this.hauptgast.anrede,
        first_name: this.hauptgast.vorname,
        last_name: this.hauptgast.nachname,
        email: this.userinfo.kontakt.email,
        phone: this.userinfo.kontakt.mobile,
        notes: this.notes,
        country_code: this.lang,

        stages_attributes: [{
          checkin_on: this.checkin,
          checkout_on: this.checkout,
          place_id: this.halalPlace.place.id,
          accommodations: accommodations
        }],

        payment_attributes: {
          currency: getCurrency()
        },
      };
      let or_state = "";
      let delay = false;
      let booked = "";
      let halalerror = "";

      this.selectedRoom.map(item => {
        if (item.confirmation === "delayed") {
          delay = true;
        }
      })
      let confirmed = "";

      if (this.order_approval === false || this.hotel_owner === '1' || delay) {
        confirmed = "notConfirmed";
        if (this.order_approval && delay) {
          or_state = '7'
        }
        else{
          or_state = '0'
        }


      } else {
        confirmed = "isConfirmed"
      }


      //Payed_by 0 ist gleich Paypal
      if (this.order_approval && (payed_by === '0')) {
        if (this.hotel_owner !== '1') {
          booked = await postBooking(obj);
          if (!booked.error) {
            delete booked.stage.place.photos;
            booked.stage.rooms.map(item => {
              delete item.photos;
            });

            if (delay) {
              or_state = "7"
            } else {
              or_state = "2";
            }
          } else {
            halalerror = "1";
            booked = obj;
            or_state = "0";
          }

        } else {
          or_state = "7"
          booked = obj
        }


      } else {
        booked = obj;
        or_state = "0";

      }


      let firma_id = "000000";

      //TODO: ID aus dem Iframe zusätzlich nehmen
      let user = await getAdminUser();
      firma_id = user.firma_id;


      let rooms = [];
      let rateplans = [];
      this.selectedRoom.map((item, index) => {
        rooms.push({room_name: item.room.name, quantity: item.quantity});
      });

      this.selectedRatePlan.map((it, n) => {
        rateplans.push({
          rateplan_name: it.name,
          room_price: it.preis.toString(),
          room_cancellation: it.stornierung
        })
      });


      let countPersons = [];
      let adults = 0;
      let childs = 0;
      this.gruppe.map(item => {
        countPersons.push({adults: item.erwachsene, childs: item.kinder})
        adults += item.erwachsene;
        childs += item.kinder;
      });

      let total_price_eur = 0;
      let price_per_group = this.price_per_group;

      if (this.place_eur !== "") {
        this.price_per_group.map(pr => {
          total_price_eur += pr.price;
        })
      }

      let total_price_try = 0;
      let price_per_group_try = this.price_per_group_try;

      if (this.place_try !== "") {
        this.price_per_group_try.map(pr => {
          total_price_try += pr.price;
        })
      }

      let hpr = await gethpr();

      let hotelinfos = {
        place_id: this.halalPlace.place.id,
        hotelname: this.halalPlace.place.name,
        hotellocation: this.halalPlace.place.location,
        hoteladress: this.place_adress,
        checkin: this.checkin,
        checkout: this.checkout,
        checkin_time: this.halalPlace.place.checkin_time,
        checkout_time: this.halalPlace.place.checkout_time,
        countPersons: countPersons,
        adults: adults,
        childs: childs,
        gruppe: this.gruppe,
        rooms: rooms,
        rateplans: rateplans,
        total_price: this.gesamtpreis.toString(),
        currency: this.halalPlace.currency,
        changed_price: "0",
        total_price_eur: total_price_eur,
        price_per_group: price_per_group,
        total_price_try: total_price_try,
        price_per_group_try: price_per_group_try,

        kunden_email: this.hauptgast.email,

        OHP: this.OHP,
        isConfirmed: confirmed,
        delay: delay,
        halalbookingProvision: hpr

      }


      let or = {
        firma_id: firma_id,
        order_id: this.order_id,
        // provision: "0",
        order_by: "1",
        order_state: or_state,
        comment: [],
        part_payment: [],

        // payed_by: "4" => Barzahlung
        payed_by: payed_by,
        rechnungsadresse: {
          email: this.hauptgast.email,
          str: this.hauptgast.str,
          nr: this.hauptgast.strnr,
          adressz: this.hauptgast.addresszusatz,
          plz: this.hauptgast.plz,
          ort: this.hauptgast.ort,
          tel: this.hauptgast.tel
        },
        hotelinfos: hotelinfos,
        halalerror: halalerror,

        order: booked,
        paypal_response: paypalresponse,
        hotel_owner: this.hotel_owner,
        stornierung: []
      };

      // --------------------------- E-Mail versenden -----------------------------

      or.rechtliches = this.pRechtliches;
      or.domain = this.pDomain;
      or.userinfo = this.userinfo;

      let logo = this.pLogo;
      logo = logo.replace("//", "");
      logo = logo.split("/")[1];
      or.logo = this.pLogo;
      or.filename = logo;


      // Wenn automatisch === false dann Eingangsbestätigung an Kunde
      if (halalerror !== "1") {
        if (this.order_approval === false) {
          await MailTocustomerbevorconfirmation7(or);
        } else {
          // Wenn automatisch === true
          // Wenn OHP dann Reservierungsanfrage an eigene Hotels
          if (this.hotel_owner === "1") {
            await MailTohotelbyall4(or);
            await MailTocustomerbevorconfirmation7(or);


            // Direkte Reservierung an Halalbooking. Was ist, wenn Halalbooking erst später die Reservierungsbestätigung schickt?
          } else {

            if (confirmed === "notConfirmed") {
              await MailTocustomerbevorconfirmation7(or);
            } else {
              await MailTocustomerbycustomer1(or);
            }

          }
        }
      } else {

      }



      // Admin/Reisebüro bekommt immer eine Mail
      or.admin = user;
      await MailToagencybyagency3(or);

      // --------------------------- E-Mail versenden ENDE -----------------------------


      let order = await postOrder(or);

      this.checkinputs = false;

      localStorage.removeItem("selectedroom");
      localStorage.removeItem("gruppe");
      localStorage.removeItem("__paypal_storage__");

      await this.$router.push("/successfulbooking");


    }
  },


  async created() {
    window.scrollTo(0, 2);
  },

  async mounted() {
    this.lang = localStorage.getItem("lang");
    if (this.lang === undefined) {
      this.lang = "DE";
    }

    let id = this.$route.query.id;
    this.groups = this.$route.query.groups;


    // ------------------------------------ DATUM anzeigen ------------------------------------
    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;

    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = moment(d).format("YYYY-MM-DD");
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null
    ) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = moment(d1).format("YYYY-MM-DD");
      this.selectedenddate = d1;
    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------


    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
      this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    } else {
      this.gruppe = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }

    this.groups = this.getGroups();


    // Berechnung der Nächte
    let days = null;
    let miliseconds = this.selectedenddate.getTime() - this.selectedstartdate.getTime();
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
    this.anzahlNaechte = days;


    let place = await onePlaceWithParameter(id, this.checkin, this.checkout, '&groups[]=' + this.groups);

    if (getCurrency() !== 'EUR'
    ) {
      this.place_eur = await onePlaceWithParameterLang(id, this.checkin, this.checkout, '&groups[]=' + this.groups, 'EUR');
    }

    this.halalPlace = await place;

    if (getCurrency() !== 'TRY') {
      if (this.halalPlace.place.location.country === "Türkei" || this.halalPlace.place.location.country === "Türkiye" || this.halalPlace.place.location.country === "Turkey") {
        this.place_try = await onePlaceWithParameterLang(id, this.checkin, this.checkout, '&groups[]=' + this.groups, 'TRY');
      }
    }


    this.selectedRoom = JSON.parse(localStorage.getItem("selectedroom"));


    let anrede = getAllAnrede(this.lang);
    anrede.map(item => this.anrede.push(item));


    for (let i = 0; this.gruppe.length > i; i++) {
      this.anzahlPersonen = this.anzahlPersonen + this.gruppe[i].erwachsene;
      this.anzahlPersonen = this.anzahlPersonen + this.gruppe[i].kinder;
    }


    this.hb_facts = this.halalPlace.place.facts.split("\n");
    await this.checkFacts();

    let room = [];
    let found = false;

    this.selectedRoom.map((selroom, index) => {
      let item = this.halalPlace.groups[index];

      if (!found) {
        item.offers.map(it => {
          if (!found) {

            if (selroom.room.id === it.room.id) {

              room.push(it);
              found = true;
            }
          }
        })
      }

      found = false;
    })

    this.selectedRoom = room;


// this.ratePlan = this.selectedRoom[0].rate_plan.id;
    this.selectedRoom.map((it) => {
      if (it.rate_plan.id !== null && it.rate_plan.id !== "") {
        this.ratePlan.push(it.rate_plan.id);
      } else {
        this.ratePlan.push(0);
      }
    })

    this.$root.$on("selectedroom", (val) => {
      this.selectedRoom = val;
    })

    this.calculatePrice();
    this.calculatePriceTRY();


    this.selectedRatePlan = [];
    let arr = [];
    this.gruppe.map((it, index) => {
      arr.push({
        name: this.selectedRoom[index].rate_plan.meal_plan_name,
        id: this.selectedRoom[index].rate_plan.id,
        preis: this.selectedRoom[index].price,
        stornierung: this.selectedRoom[index].rate_plan.cancellation_policy_html
      });
    });
    this.selectedRatePlan = arr;


    //Paypal Order_id generieren
    this.order_id = generateReservierungsnr("0000");
    this.orid = this.order_id.split('-')[2];

    this.$root.$on("paypal_response", async (order) => {
      await this.setbooking(order, "0");
    })


    let ohid="";
    try {
       ohid = await getOHPbyID(this.halalPlace.place.id)
    } catch (e) {

    }

    if (ohid._id !== undefined) {
      this.hotel_owner = "1";
      this.OHP = {ohp_email: ohid.email, ohp_provision: ohid.provision};
    } else {
      this.hotel_owner = "0";
    }

    let user = await getIDFromAdminUser();
    let setting = await getApprovalSettings();


    // Das Logo wird in der Mail benötigt
    // this.logo = await getLogo(user._id);

    this.userinfo = await getKontaktFromUserinfoByUserId(user._id);

    this.order_approval = setting.order_approval;


    this.place_adress = this.check_facts[5].text;


    await window.scrollTo(0, 5);

    this.loadingb = false;


  }

}
</script>
<style scoped>
@import "../css/Booking.css";
</style>