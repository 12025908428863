<template>
  <div class="div-overlayroominfos">
    <v-overlay :value="showOverlayRoomInfos" class="overlay-overlayroominfos">
      <div style="text-align: end; height: 50px; width: 100%">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn @click="closeOverlay" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Button.Schliessen") }}</span>
        </v-tooltip>
      </div>

      <div class="div-body-overlayroominfos">
        <div class="div-gallery-overlayroominfos">
          <GallerySmall :pics="roominfo.room.photos"></GallerySmall>
        </div>
        <div class="div-roominfos-overlayroominfos">

          <h2 v-html="roominfo.room.name"/>
          <br>
          <div v-html="roominfo.room.long_description" class="div-description-overlayroominfos"/>
        </div>
      </div>


    </v-overlay>
  </div>
</template>

<script>
import GallerySmall from "@/components/gallery/GallerySmall";
import {VueperSlide, VueperSlides} from "vueperslides";
import {parseRoominfoTR} from "@/util/placeParsingUtil";

export default {
  name: "OverlayRoomInfos",
  props: [
    'pics',
    'roominfo',
    'showOverlay'
  ],
  components: {
    VueperSlides,
    VueperSlide,
    GallerySmall
  },
  data() {
    return {
      showOverlayRoomInfos: false
    }
  },
  methods: {
    closeOverlay() {
      // this.showOverlay = false;
      this.$root.$emit("showOverlayRoomInfos", false);
      this.$root.$emit("stopScrolling", false);

    }
  },
  created() {

    this.showOverlayRoomInfos = this.showOverlay;
    parseRoominfoTR(this.roominfo.room.long_description);

    // this.$root.$on("showGallerie", (val) => {
    //   this.showOverlayRoomInfos = val;
    // });
  }
}
</script>

<style scoped>
@import "../../css/Overlay.css";
</style>