import Vue from 'vue';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import '@mdi/font/css/materialdesignicons.css';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import messages from './lang';
import VueCookie from 'vue-cookie';
import VueSocialSharing from 'vue-social-sharing';
import VueAnalytics from 'vue-analytics';
import VueMoment from 'vue-moment';
import moment from 'moment'

Vue.prototype.moment = moment;
Vue.use(VueMoment, {
    moment,
})

Vue.config.productionTip = false


Vue.use(VueI18n);
Vue.use(VueCookie);
Vue.use(VueSocialSharing);


export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'DE',
    fallbackLocale: 'DE',
    messages
});

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
// id: 'UA-158840006-1',
// router
// });

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
