import * as UrlUtil from "./UrlUtil";

export const getGroups = () => {


    let gruppe = "";
    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
        gruppe = JSON.parse(localStorage.getItem("gruppe"));
    } else {
        gruppe = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }


    let value = "";
    let url1 = "";
    gruppe.map((item, index) => {
        let groups = "";
        if (index > 0)
            UrlUtil.bind(url1);
        value = UrlUtil.binddata(value);

        let erw = item.erwachsene;
        let kinder = item.kinder;

        if (kinder > 0) {
            item.kinderalter.map(it => {
                if (groups !== "")
                    groups = groups.concat(",");

                groups = groups.concat(it.age.toString());
            });
        }

        if (kinder > 0) {
            url1 = UrlUtil.addGroups(url1, erw + "," + groups);
            value = UrlUtil.addGroupsData(value, erw + "," + groups);
        } else {
            url1 = UrlUtil.addGroups(url1, erw);
            value = UrlUtil.addGroupsData(value, erw);
        }
    });

    return value;


}