
export const getAge =(DOB,checkout) =>{

    let checkoutdate = "";
   if(checkout ==="" || checkout === undefined)
   {
       checkoutdate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
   }
   else
   {
       checkoutdate = new Date(checkout);
   }

    let birthDate = new Date(DOB);
    let age = checkoutdate.getFullYear() - birthDate.getFullYear();
    let m = checkoutdate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && checkoutdate.getDate() < birthDate.getDate())) {
        age = age - 1;
    }
    return age;
}