<template>
  <div>

    <div class="div-background-header"/>
    <!--    <div style="height: 500px; padding: 200px 10px 50px; margin: auto;" v-if="customer ==='holidaytimes'">-->
    <div class="div-body-iframe">
      <div class="div-inner-iframe">
        <iframe
            id="ibe"
            src="https://pauschalreise.schmetterling.de/search?taid=14428&agent=133564&ibe=pa"
            style="width: 100%;min-height: 1000px"
        >

        </iframe>
      </div>
    </div>


  </div>
</template>


<script>


export default {
  name: "Schmetterlingiframe",


  data() {
    return {
      customer: ""
    }
  },
  methods: {
    async gotoHome() {

      // await new Promise(resolve => setTimeout(resolve, 5000));
      // await this.$router.push("/");
    }

  },


  async created() {
    window.scrollTo(0, 2);

    this.customer = process.env.VUE_APP_CUSTOMER;

    window.addEventListener('message', function (message) {
      if (message.data.event === 'iberesized') {
        document.getElementById('ibe').style.height = message.data.height + 'px';
      }
    }, false);

  }
}

</script>
<style>
@import "../css/Schmetterlingiframe.css";
</style>