<template>
  <div>

    <div class="div-background-header"/>
    <div style="margin: 150px 0 500px 0">
      <div v-if="!url_expired" style="max-width: 500px; margin: auto">
        <span>
          <v-icon color="green" large>mdi-check</v-icon>
        </span>
        <span>
          Viele Dank für Ihre Bestätigung. <br>
          Die Reservierung mit der Nr.: {{ order_id }} ist bestätigt. <br>
          Sie können diese Seite nun wieder schließen!
        </span>
      </div>

      <div v-if="url_expired" style="max-width: 500px; margin: auto">
        <span>
          <v-icon color="error" large>mdi-close</v-icon>
        </span>
        <span>
          Leider ist der Link nicht mehr gültig. <br>
          Bitte kontaktieren Sie den Seitenbetreiber!
        </span>
      </div>

    </div>
  </div>
</template>

<script>


export default {
  name: "CornfirmReservation",
  methods: {},
  data() {
    return {
      order_id: "",
      url_expired: false
    }
  },

  async created() {
    window.scrollTo(0, 2);

    //TODO: Order_id UND Link_id aus der URL entnehmen

    //TODO: Wenn Link_id nicht mehr gültig ist dann
    // this.url_expired = true;

    //TODO: Order ermitteln und bestätigung hinterlegen

    //TODO: Order ermitteln und ABLEHNUNG hinterlegen

    //TODO: Link_id ungültig machen

  }
}

</script>
<style scoped>

</style>