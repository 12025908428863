import axios from "axios";
import {getToken, getUserToken} from "./userCredentials";

export const createSettings = async () => {
    let status = 0;
    let settings = {
        facilities: {
            general: [],
            activities: [],
            food: [],
            children: [],
            entertainment: [],
            internet: [],
            parking: [],
            shops: [],
            reception: [],
            business: [],
            cleaning: [],
            transport: [],
            beach: [],
            spa: [],
            pools: [],
        },
    }
    await axios.post(process.env.VUE_APP_DB + "/api2/settings", settings, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });

    return status;
}

export const getAllSettings = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/settings", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}
export const getApprovalSettings = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/appset", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}

export const getFavSetting = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/settingsfav", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}

export const getLandingpageSetting = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/settingslanding", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}

export const getgoogleSetting = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/gset", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}
export const getpclssSetting = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/pclass", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}

export const updateSettings = async (settings) => {
    let baseurl = process.env.VUE_APP_DB + "/api2/settings/" + settings._id;
    let status = 0;

    await axios.put(baseurl, settings, {headers: {"authorization": 'Bearer ' + await getToken()}}).then(res => status = res.status).catch(err => {
    });
    return status;
}

export const getSettingByFirmaID = async (id) => {
    let status = 0;
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/settingByFirmaID/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data;
    return entry;
}

export const gethpr = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/hpr", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0].halalprovision;
    return entry;
}

export const getdomain = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/dmn", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0].domain;
    return entry;
}
