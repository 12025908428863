import axios from "axios";
import {getToken} from "./userCredentials";


export const MailTocustomerbycustomer1 = async (sendValues) => {
// 1. E-Mail an Kunde bei Reservierung durch Kunde/Admin
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomerbycustomer1', sendValues,{headers: {"authorization": 'Bearer ' + await getToken()}});
}


export const MailToagencybyagency3 = async (sendValues) => {
// 3. E-Mail an Reisebüro bei Reservierung durch Reisebüro/iFrame
    await axios.post(process.env.VUE_APP_DB + '/api2/mailToagencybyagency3', sendValues,{headers: {"authorization": 'Bearer ' + await getToken()}});
}

export const MailTohotelbyall4 = async (sendValues) => {
// 4. E-Mail an eigene Hotels bei Reservierung
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTohotelbyall4', sendValues,{headers: {"authorization": 'Bearer ' + await getToken()}});
}


export const MailTocustomerbevorconfirmation7 = async (sendValues) => {
// 7. E-Mail an Kunde bei Reservierungseingang bevor Bestätigung (nur wenn keine automatische Reservierung)
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomerbevorconfirmation7', sendValues,{headers: {"authorization": 'Bearer ' + await getToken()}});
}


export const MailToAdminRegistration = async (sendValues) => {
// 7. E-Mail an Kunde bei Reservierungseingang bevor Bestätigung (nur wenn keine automatische Reservierung)
    await axios.post(process.env.VUE_APP_DB + '/api2/mailToAdminRegistration', sendValues,{headers: {"authorization": 'Bearer ' + await getToken()}});
}