<template>
  <div>
    <main class="main-startneu">
      <div class="div-search-startneu">
        <h1 style="display: none">{{pDomain}}</h1>

        <div class="div-background-startneu"/>

        <div class="div-carousel-startneu">
          <v-carousel
              hide-delimiters
              :show-arrows="false"
              cycle
              interval="6000"
              style="height: 400px;"
          >
            <v-carousel-item
                v-for="(item,i) in landingpagephotos"
                :key="i"
                :src="item.photo"
            ></v-carousel-item>
          </v-carousel>
        </div>
        <div class="div-suche-startneu">

          <Search :lang="$attrs.lang"/>
          <!--          <SearchNEU :lang="$attrs.lang"/>-->
        </div>


      </div>
      <div class="div-body-startneu">


        <div class="div-toiframe-startneu" v-if="pDomain === 'holidaytimes.de'">

          <v-btn
              @click="zuPauschalreisen"
              class="vbtn-totalprice vbtn-linear-gradient"
          >
            {{ $t('Start.ZudenPauschalreisen') }} -->

          </v-btn>


        </div>

        <!-- -------------------------------- Hotels --------------------------------------- -->

        <div class="div-block-startneu" style="background: #F1F4F6; padding-top: 50px">
          <h2 class="h1-title-favs-startneu">
            {{ $t('Start.TopUnterkuenfte') }}
          </h2>
          <div style="text-align: center; margin: 0 5px 40px 8px">

            {{ $t("Start.TextHotels") }}
          </div>


          <template v-if="showSkeleton" v-for="item in 5">
            <v-skeleton-loader
                class="mx-auto"
                min-width="175"
                max-width="300"
                type="card"
            />
          </template>

          <div class="div-vueperslide-startneu">
            <vueper-slides
                class="no-shadow"
                :visible-slides="4"
                slide-multiple
                :gap="1"
                :slide-ratio="1"
                :dragging-distance="50"
                fixed-height="480px"
                :bullets="false"
                :breakpoints="{
                  1450: {arrows:true, visibleSlides: 3, slideMultiple: 3 },
                  1050: {arrows:true, visibleSlides: 2, slideMultiple: 2 },
                  750: {arrows:true, visibleSlides: 1, slideMultiple: 1}}"
            >
              <vueper-slide v-for="(item) in settings.favorite_hotel" title="test">
                <template v-slot:content>
                  <CardPlace2
                      :hotelinfos="item"
                      :checkin="checkin"
                      :checkout="checkout"
                      :groups="groups"
                      :lang="$attrs.lang"
                  />
                </template>
              </vueper-slide>
            </vueper-slides>

          </div>
        </div>


        <!-- -------------------------------- Länder --------------------------------------- -->


        <div class="div-block-startneu">
          <h2 class="h1-title-favs-startneu">
            <!--            {{ $t("Home.BeliebteHotels") }}-->

            {{ $t("Home.BeliebteLaender") }}
          </h2>
          <div style="text-align: center; margin: 0 5px 40px 8px">
            {{ $t("Start.TextLander") }}

          </div>


          <template v-if="showSkeleton" v-for="item in 5">
            <v-skeleton-loader
                class="mx-auto"
                min-width="175"
                max-width="300"
                type="card"
            />
          </template>
          <FavCountries
              :item="settings.favorite_country"
              :lang="lang"
          />

        </div>


        <!-- -------------------------------- Regionen --------------------------------------- -->


        <div class="div-block-startneu">

          <template v-if="showSkeleton" v-for="item in 5">
            <v-skeleton-loader
                class="mx-auto"
                min-width="175"
                max-width="300"
                type="card"
            />
          </template>

          <FavRegions
              :item="settings.favorite_location"
              :lang="lang"
          />

        </div>


        <!-- -------------------------------- Städte --------------------------------------- -->


        <div class="div-block-startneu">
          <h2 class="h1-title-favs-startneu">
            {{ $t("Home.BeliebteStaedte") }}
          </h2>
          <div style="text-align: center; margin: 0 5px 40px 8px">

            {{ $t("Start.TextStadte") }}

          </div>

          <FavCities
              :item="settings.favorite_city"
              :lang="lang"
          />


        </div>

      </div>
    </main>
  </div>
</template>

<script>
import CardSmallLocation from "../components/card/CardSmallLocation";
import Search from "../components/search/Search";
import SearchNEU from "../components/search/SearchNEU";
import {getFavSetting, getLandingpageSetting} from "../util/settingsUtil";
import FavCountries from "../components/Landingpage/FavCountries";
import FavRegions from "../components/Landingpage/FavRegions";
import FavCities from "../components/Landingpage/FavCities";
import CardPlace2 from "../components/card/CardPlace2";
import {VueperSlide, VueperSlides} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


export default {
  name: "Start",
  props: [
    'pDomain',
  ],
  components: {
    SearchNEU,
    Search,
    CardSmallLocation,
    FavCountries,
    FavRegions,
    FavCities,
    CardPlace2,
    VueperSlides,
    VueperSlide
  },

  data() {
    return {

      lang: 'DE',
      // pics: [Meer3, Meer2, Meer4, Meer1, Meer5, Meer6, Meer7],
      halalHotels: "",
      settings: "",
      landingpagephotos: "",

      showSkeleton: true,
      checkin: "",
      checkout: "",
      groups: "&groups[]=2",
    }
  },

  methods: {
    zurSuche() {
      this.$router.push({name: "SearchFilter"});
      window.scrollTo(0, 2);
    },

    zuPauschalreisen() {
      this.$router.push({name: "Schmetterlingiframe"});
      window.scrollTo(0, 2);
    },

  },

  computed: {

    getLang() {
      return this.lang;
    }
  },

  async created() {

    window.scrollTo(0, 2);

    //Sprache setzen
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang');
    }
    this.settings = await getFavSetting();
    let photos = await getLandingpageSetting();
    this.landingpagephotos = photos.landingpagephotos;

    this.showSkeleton = false;


  }

}


</script>


<style lang="css" scoped>
@import "../css/Start.css";
</style>
