<template>

  <header>
    <div class="div-header">
      <router-link to="/">
        <span style="justify-self: flex-end">
          <img class="img-logo-header" :src="pLogo" :alt="'Logo '+  pDomain"/>
        </span>
      </router-link>

      <div class="div-tel-header">
        <span class="span-tel-header">
          <v-icon color="white">mdi-phone</v-icon>
          {{ tel }}
        </span>
        <br>
        <!--        <span class="span-tel-header">-->
        <!--          <v-icon color="green">mdi-whatsapp</v-icon>-->
        <!--          {{ mobile }}-->
        <!--        </span>-->
      </div>

      <Currency/>

      <LanguageSwitcher
          :lang="$attrs.lang"
      />
    </div>


    <!--    <div class="div-scroll-header" v-if="visible">-->
    <!--      <v-tooltip bottom>-->
    <!--        <template v-slot:activator="{ on }">-->
    <!--          <v-btn-->
    <!--              class="ma-2"-->
    <!--              outlined-->
    <!--              fab-->
    <!--              color=var(&#45;&#45;Primary)-->
    <!--              @click.native="scrollToTop"-->
    <!--              v-on="on"-->
    <!--          >-->
    <!--            <v-icon>mdi-arrow-collapse-up</v-icon>-->
    <!--          </v-btn>-->
    <!--        </template>-->
    <!--        <span>Nach oben</span>-->
    <!--      </v-tooltip>-->
    <!--    </div>-->

  </header>


</template>

<script>
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import Currency from "../Currency/Currency";
import {getAdminUser, getLogo} from "../../util/DBUtil";
import {getUserinfoByUserId} from "../../util/userinfoDBUtil";

export default {
  name: "Header",
  props: [
    'pLogo',
    'tel',
    'mobile',
    'pDomain'
  ],
  components: {
    LanguageSwitcher,
    Currency
  },
  data() {
    return {
      // logo: this.logo,
      visible: false,
      scrollpx: 0,
      userinfo: ""
    }
  },

  // methods: {
  //   zuStart() {
  //     this.$router.push("/");
  //   },
  //
  //   scrollToTop() {
  //     window.scrollTo(0, 0);
  //   },
  //   toggleVisibility() {
  //     if (window.scrollY > 800) {
  //       this.visible = true;
  //
  //     } else {
  //       this.visible = false;
  //     }
  //   }
  // },
  // async created() {
  //   // Nachdem gescrollt wurde soll etwas eingeblendet werden
  //   // window.addEventListener('scroll', this.toggleVisibility);
  //
  //
  // },

}

</script>

<style lang="css" scoped>
@import "../../css/Header.css";
</style>
