import Vue from 'vue';
import VueRouter from 'vue-router';
// import StartALT from "../views/StartALT";
import Start from "../views/Start";
import Imprint from "../views/Imprint";
import AGB from "../views/AGB";
import Privacy from "../views/Privacy";
import Home from '../views/Home.vue';
import Place from "../views/Place";
import Booking from "../views/Booking";
import SuccessfulBooking from "../views/SuccessfulBooking";
import ConfirmReservation from "../views/ConfirmReservation";
import ErrorPage from "../views/ErrorPage";
import Schmetterlingiframe from "../views/Schmetterlingiframe";
import RegistrationContact from "@/views/RegistrationContact";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Start',
        component: Start
    },
    // {
    //     path: '/startalt',
    //     name: 'StartAlt',
    //     component: StartALT
    // },
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint
    },
    {
        path: '/agb',
        name: 'AGB',
        component: AGB
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/place",
        name: "Place",
        component: Place
    },
    {
        path: "/booking",
        name: "Booking",
        component: Booking
    },
    {
        path: "/successfulbooking",
        name: "SuccessfulBooking",
        component: SuccessfulBooking
    },
    {
        path: "/confirmreservation",
        name: "ConfirmReservation",
        component: ConfirmReservation
    },
    {
        path: "*",
        name: "ErrorPAge",
        component: ErrorPage
    },
    {
        path: "/pauschalreisen",
        name: "Schmetterlingiframe",
        component: Schmetterlingiframe
    },
    {
        path: "/registrationcontact",
        name: "RegistrationContact",
        component: RegistrationContact
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
