const replaceText = (text, expected, replaceWith) => {
    text = text.replace(expected, replaceWith);
    return text;
}

export const arr_EN = [
    "GENERAL FACILITIES:",
    "ACTIVITIES:",
    "FOOD &amp; DRINKS:",
    "FOR CHILDREN:",
    "ENTERTAINMENT:",
    "INTERNET:",
    "PARKING",
    "SHOPS:",
    "RECEPTION SERVICES:",
    "BUSINESS FACILITIES:",
    "CLEANING SERVICES:",
    "TRANSPORT:",
    "BEACHES:",
    "WELLNESS & SPA:",
    "POOLS:",
    "IMPORTANT NOTES:",
];
export const arr_edited_EN = [
    "GENERAL_FACILITIES:",
    "ACTIVITIES:",
    "FOOD_&amp;_DRINKS:",
    "FOR_CHILDREN:",
    "ENTERTAINMENT:",
    "INTERNET:",
    "PARKING",
    "SHOPS:",
    "RECEPTION_SERVICES:",
    "BUSINESS_FACILITIES:",
    "CLEANING_SERVICES:",
    "TRANSPORT:",
    "BEACHES:",
    "WELLNESS_&_SPA:",
    "POOLS:",
    "IMPORTANT_NOTES:",
];

export const parseFacilities_EN = async (text) => {
    text = replaceAll_EN(text);
    text = text.replaceAll("\n\n", "\n\n ");
    text = text.replaceAll(":\n", ": ");
    text = text.replaceAll(": \n", ": ");
    let spText = await parseText(text);
    spText = await replaceAll_EN_Back(spText);
    return spText;
}

export const replaceAll_EN = (text) => {
    text = replaceText(text, "GENERAL FACILITIES:", "GENERAL_FACILITIES:");
    text = replaceText(text, "FOOD &amp; DRINKS:", "FOOD_&amp;_DRINKS:");
    text = replaceText(text, "FOR CHILDREN:", "FOR_CHILDREN:");
    text = replaceText(text, "RECEPTION SERVICES:", "RECEPTION_SERVICES:");
    text = replaceText(text, "BUSINESS FACILITIES:", "BUSINESS_FACILITIES:");
    text = replaceText(text, "CLEANING SERVICES:", "CLEANING_SERVICES:");
    text = replaceText(text, "WELLNESS & SPA:", "WELLNESS_&_SPA:");
    text = replaceText(text, "IMPORTANT NOTES:", "IMPORTANT_NOTES:");
    return text;
}

export const replaceAll_EN_Back = (text) => {
    let arr = [];
    text.map(item => {
        let inOf = arr_edited_EN.indexOf(item.title);
        if (inOf !== -1) {
            item.title = arr_EN[inOf];
        } else {
            item.title = item.title.replaceAll("_", " ");
        }
        arr.push({title: item.title, text: item.text});
    })
    return arr;
}

export const splitText = (text) => {
    let splittedText = text.split(" ");
    return splittedText;
}


export const parseText = async (text) => {
    let splittedText = splitText(text);
    let saveIndex = 0;
    let endIndex = 0;
    let n = await iterateArray(splittedText, 0);
    let next = await iterateArray(splittedText, 1);

    let last = false;
    let arrTex = [];
    splittedText.map((text, index) => {

        if (n !== 15) {
            if (text.includes(arr_edited_EN[n])) {
                saveIndex = index;
            }

            if (text.includes(arr_edited_EN[next])) {
                endIndex = index;

                let text = getString(splittedText, saveIndex, endIndex);
                saveIndex = index;
                arrTex.push(text);
                n = iterateArray(splittedText, n + 1);
                next = iterateArray(splittedText, n + 1);
            }

        } else {
            if (!last) {
                let text = getString(splittedText, index - 1, splittedText.length - 1);
                arrTex.push(text);
                last = true;
            }
        }
    });

    return arrTex;
}


const iterateArray = (array, index) => {
    let save = 0;
    let test = false;
    for (let i = index; i < arr_edited_EN.length; i++) {
        if (contains(array, arr_edited_EN[i])) {
            if (!test) {
                save = i;
                test = true;
                return save;
            }
        }
    }
    return save;
}


function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].includes(obj)) {
            return true;
        }
    }
    return false;
}


const getString = (splittedText, von, bis) => {
    let text = {title: "", text: ""};
    text.title = splittedText[von];
    for (let i = von + 1; i < bis; i++) {
        text.text += splittedText[i] + " ";
    }
    return text;
}