<template>
  <div>
    <div class="div-background-header"/>
    <div style="height: 500px; padding: 200px 10px 50px; margin: auto;">

      <v-icon large color="error" style="margin-right: 10px;">mdi-alert-circle</v-icon>
      <v-progress-circular
          :rotate="90"
          :size="50"
          :width="5"
          :value="value"
          color="red"
          style="margin-right: 10px;"
      >
        {{ value }}
      </v-progress-circular>
      <label style="font-size: 25px;"><b>404 Error </b></label>
      <br>
      <label style="font-size: 20px;">Diese Seite existiert leider nicht mehr, Sie werden nun weitergeleitet!</label>
      <br>


    </div>
  </div>
</template>

<script>


export default {
  name: "ErrorPage",


  data() {
    return {
      interval: {},
      value: 0,
    }
  },
  methods: {
    async gotoHome() {

      await new Promise(resolve => setTimeout(resolve, 5000));
      await this.$router.push("/");
    }

  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    window.scrollTo(0, 2);

    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 25
    }, 1000)
  },

  async created() {
    window.scrollTo(0, 0);
    await this.gotoHome();
  }
}

</script>
<style scoped>
</style>