const replaceText = (text, expected, replaceWith) => {
    text = text.replace(expected, replaceWith);

    return text;

}
export const arr_TR = [
    "GENEL TESİS OLANAKLARI:",
    "ETKİNLİKLER:",
    "YİYECEK &amp; İÇECEK:",
    "ÇOCUKLAR İÇİN:",
    "EĞLENCELER:",
    "İNTERNET:",
    "PARK YERİ:",
    "DÜKKANLAR:",
    "RESEPSİYON HİZMETLERİ:",
    "ÇALIŞMA ALANLARI:",
    "TEMİZLİK HİZMETLERİ:",
    "ULAŞIM:",
    "PLAJLAR:",
    "SAĞLIK & SPA:",
    "HAVUZLAR:",
    "ÖNEMLİ NOT VE KURALLAR:",
];
export const arr_edited_TR = [
    "GENEL_TESİS_OLANAKLARI:",
    "ETKİNLİKLER:",
    "YİYECEK_&amp;_İÇECEK:",
    "ÇOCUKLAR_İÇİN:",
    "EĞLENCELER:",
    "İNTERNET:",
    "PARK_YERİ:",
    "DÜKKANLAR:",
    "RESEPSİYON_HİZMETLERİ:",
    "ÇALIŞMA_ALANLARI:",
    "TEMİZLİK_HİZMETLERİ:",
    "ULAŞIM:",
    "PLAJLAR:",
    "SAĞLIK_&_SPA:",
    "HAVUZLAR:",
    "ÖNEMLİ_NOT_VE_KURALLAR:",
];

export const parseFacilities = async (text) => {
    text = replaceAll_TR(text);
    text = text.replaceAll("\n\n", "\n\n ");
    text = text.replaceAll(":\n", ": ");
    text = text.replaceAll(": \n", ": ");
    let spText = await parseText(text);
    spText = await replaceAll_TR_Back(spText);
    return spText;
}

export const replaceAll_TR = (text) => {
    text = replaceText(text, "GENEL TESİS OLANAKLARI:", "GENEL_TESİS_OLANAKLARI:");
    text = replaceText(text, "YİYECEK &amp; İÇECEK:", "YİYECEK_&amp;_İÇECEK:");
    text = replaceText(text, "ÇOCUKLAR İÇİN:", "ÇOCUKLAR_İÇİN:");
    text = replaceText(text, "PARK YERİ:", "PARK_YERİ:");
    text = replaceText(text, "RESEPSİYON HİZMETLERİ:", "RESEPSİYON_HİZMETLERİ:");
    text = replaceText(text, "ÇALIŞMA ALANLARI:", "ÇALIŞMA_ALANLARI:");
    text = replaceText(text, "TEMİZLİK HİZMETLERİ:", "TEMİZLİK_HİZMETLERİ:");
    text = replaceText(text, "SAĞLIK & SPA:", "SAĞLIK_&_SPA:");
    text = replaceText(text, "ÖNEMLİ NOT VE KURALLAR", "ÖNEMLİ_NOT_VE_KURALLAR:");
    return text;
}

export const replaceAll_TR_Back = (text) => {
    let arr = [];
    text.map(item => {
        let inOf = arr_edited_TR.indexOf(item.title);
        if (inOf !== -1) {
            item.title = arr_TR[inOf];
        } else {
            item.title = item.title.replaceAll("_", " ");
        }
        arr.push({title: item.title, text: item.text});
    })

    return arr;

}

export const splitText = (text) => {
    let splittedText = text.split(" ");
    return splittedText;
}

export const parseText = async (text) => {
    let splittedText = splitText(text);
    let saveIndex = 0;
    let endIndex = 0;
    let n = await iterateArray(splittedText, 0);
    let next = await iterateArray(splittedText, 1);
    let last = false;
    let arrTex = [];

    splittedText.map((text, index) => {
        if (n !== 15) {
            if (text.includes(arr_edited_TR[n])) {
                saveIndex = index;
            }

            if (text.includes(arr_edited_TR[next])) {
                endIndex = index;

                let text = getString(splittedText, saveIndex, endIndex);
                saveIndex = index;
                arrTex.push(text);
                n = iterateArray(splittedText, n + 1);
                next = iterateArray(splittedText, n + 1);
            }

        } else {
            if (!last) {
                let text = getString(splittedText, index - 1, splittedText.length - 1);
                arrTex.push(text);
                last = true;
            }
        }
    });
    return arrTex;
}


const iterateArray = (array, index) => {
    let save = 0;
    let test = false;
    for (let i = index; i < arr_edited_TR.length; i++) {
        if (contains(array, arr_edited_TR[i])) {
            if (!test) {
                save = i;
                test = true;
                return save;
            }
        }
    }
    return save;
}

function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].includes(obj)) {
            return true;
        }
    }
    return false;
}

const getString = (splittedText, von, bis) => {
    let text = {title: "", text: ""};
    text.title = splittedText[von];
    for (let i = von + 1; i < bis; i++) {
        text.text += splittedText[i] + " ";
    }
    return text;
}

export const parsePool = async (text) => {

    text = text.replaceAll("\n\n ", "\n");
    let textArray = text.split("\n");
    let poolArr = [];
    textArray.map(item => {
        let value = {
            title: "",
            desc: []
        }
        if (item.includes(":")) {
            let it = item.split(":");

            value.title = it[0] + ":";
            if (it[1].includes(",")) {
                value.desc = it[1].split(",");
            } else {
                value.desc = it[1];
            }

        } else {
            value.desc = item;

        }
        poolArr.push(value);
    })

    let finalpool = await parsePoolEx(poolArr);
    return finalpool;
}

const parsePoolEx = async (pool) => {
    let arr = [];
    let tmp = [];
    let title = "";
    pool.map((item, index) => {
        if (!item.title.includes(":")) {
            if (item.desc !== "")
                arr.push({title: "", desc: [], nontitle: item.desc});

        } else {
            if (item.title.includes(" ")) {
                let text = item.title.split(" ");

                if (!isNaN(parseInt(text[1].replace(":", "")))) {
                    if (tmp.length > 0) {
                        if (tmp[0].title !== "")
                            arr.push(tmp);
                        tmp = [];
                    }
                    tmp.push({title: item.title, desc: item.desc, nontitle: ""})
                } else {
                    if (item.desc.length > 0) {
                        tmp.push({title: item.title, desc: item.desc, nontitle: ""})
                    }
                }
            } else {
                tmp.push({title: item.title, desc: item.desc, nontitle: ""})
            }

        }
    });
    arr.push(tmp);
    return arr;
}


export const beachParse = async (text) => {
    let t = text.replaceAll("\n\n", "\n");
    let arr = t.split("\n");
    return arr;
}


export const beachSplit = async (beach) => {
    let arr = [];
    beach.map(async item => {
        if (item !== "") {

            if (item.includes(":")) {
                let title = await item.split(":");

                let commSplit = title[1].split(",");
                arr.push({title: title[0] + ":", desc: commSplit});

            } else {
                if (item !== " " && item !== "")
                    arr.push({title: "", desc: [item]});
            }
        }
    })
    return arr;
}


export const spa = async (text) => {
    text = text.replaceAll("\n", ":");
    let splitSpa = await text.split(":");

    let arr = [];
    let desc = []
    for (let i = 0; i < splitSpa.length; i = i + 3) {
        if (splitSpa[i + 2] !== "" && splitSpa[i + 2] !== " " && splitSpa[i + 2] !== undefined) {
            if (splitSpa[i + 2].includes(",")) {
                desc = await splitSpa[i + 2].split(",")


            } else {
                desc.push(splitSpa[i + 2]);
            }
        }
        if (splitSpa[i] !== "" && splitSpa[i] !== " ") {
            arr.push({title: splitSpa[i], subtitle: splitSpa[i + 1], desc: desc});
            desc = []
        }
    }
    return arr;
}

export const parseBeachTitle = (beach) => {

    beach.map(item => {
        let index = item.title.indexOf(":");
        if (index !== -1) {
            let title = item.title.substring(0, index);
            item.title = title;
        }

    });
    return beach;

}

export const parseBeachtext = (beach) => {
    let arr = [];

    beach = beach.replaceAll("\n\n", "\n");
    let it = beach;
    let start = 0;
    s
    for (let i = 0; i < beach.split(":").length; i++) {


        let indextitle = it.indexOf(":");
        //title :
        let title = it.substring(start, indextitle);
        it = it.substring(indextitle + 1, it.length);
        let back = it.indexOf("\n");
        let des = getObject(it.substring(0, back));
        it = it.substring(back, it.length);

        arr.push({title: title, desc: des});
    }

    //myArr.push(o1);
    //myArr.push(o2);
    //myArr.push(o3);
    //myArr.push(o4);

    //arr.push(myArr);


    return arr;
}

export const getObject = (desc) => {


    let descArr = []
    if (desc.includes(",")) {
        descArr = desc.split(",");
    } else {
        descArr.push(desc);
    }


    return descArr;
}


export const parseRoominfoTR = (room) => {
    //TODO: Cemil hier die Beschreibung vom Raum formatieren
    let arr = [];

    return arr;
}
