<template>
  <div>

    <div class="div-cardroom2" v-bind:class="[{selectedroom2 : isActiv},{notselectedroom2 :! isActiv}]">


      <v-icon v-bind:class="[{showChecked2: isActiv},{hideChecked2 :!isActiv}]" color="var(--Primary)" large>
        mdi-check
      </v-icon>

      <div class="div-img-cardroom2">
        <img
            :src="roominfo.room.photos[0]" alt="room-photo" class="img-cardroom2"
            @click="showGalleryPopup = true"
        >
        <GalleryPopup :pics="roominfo.room.photos" :show="showGalleryPopup"/>
      </div>


      <!--    <v-icon color="white" class="vicon-gallery-cardroom2">mdi-image-multiple</v-icon>-->

      <div class="div-price-name-cardroom2">

        <label class="label-price-cardroom2" @click="showInfos = true">
          {{ formatPrice(roominfo.price) }}
          <v-icon small color="var(--Primary)">{{ currencyicon }}</v-icon>
        </label>

        <label class="label-roomtitel-cardroom2" @click="showInfos = true">
          <span v-if="roominfo.quantity !== 1">{{ roominfo.quantity }} X </span> {{ roominfo.room.name }}
        </label>

      </div>

      <div class="div-vbtn-infos-cardroom2">
        <v-btn
            @click="showInfos = true"
            color="var(--Primary)"
            class="vbtn-infos-cardroom2"
            outlined
        >
          {{ $t("Button.Infos") }}
        </v-btn>
      </div>
    </div>


    <div class="div-infos-outer-cardroom2" v-if="showInfos">
      <div style="text-align: end; height: 50px;">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn @click="showInfos= false" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Button.Schliessen") }}</span>
        </v-tooltip>
      </div>

      <div class="div-infos-inner-cardroom2">


        <label class="label-title-info-cardroom2">
          {{ roominfo.room.name }}
        </label>
        <br>

        <div class="div-mealplan-cardroom2">
          <template v-for="(service, index) in roominfo.rate_plans">
            <div
                class="mealplan-cardroom2"
                v-bind:class="[mealPlan ===service.rate_plan.meal_plan_name? 'activ-mealplan-cardroom2' : 'mealplan-cardroom2' ]"
                @click="setMealPlan(service.rate_plan.meal_plan_name)"
            >

              <input type="checkbox" id="horns" :name="mealPlan" :checked="mealPlan===service.rate_plan.meal_plan_name">
              <label>
                {{ service.rate_plan.meal_plan_name }}
              </label>
              <label>
                {{ formatPrice(service.price) }}
                <v-icon small color="black">{{ currencyicon }}</v-icon>
              </label>
            </div>

            <div v-if="mealPlan===service.rate_plan.meal_plan_name" class="infos-mealplan-cardroom2">

              <div
                  v-html="roominfo.room.long_description"
                  style="white-space: pre-wrap; "
              ></div>
              <br>
              <label style="font-weight: bolder">{{ $t("Card.Stornierungsbedingungen") }}:</label>
              <div
                  v-html="roominfo.rate_plans[index].rate_plan.cancellation_policy_html"
                  style="text-align: start; margin-bottom: 100px;"
              ></div>

            </div>
            <div v-else style="height: 0"></div>
          </template>

        </div>
        <div style="height: 200px">

          <!--          <v-btn-->
          <!--              @click="selectRoom"-->
          <!--              href="#title-place"-->
          <!--              color="var(&#45;&#45;Primary)"-->
          <!--              class="vbtn-cardroom2"-->
          <!--          >-->
          <!--            {{ $t("Button.ZimmerAuswaehlen") }}-->
          <!--          </v-btn>-->

          <v-btn
              @click="selectRoom"
              href="#title-place"

              class="vbtn-cardroom2 vbtn-linear-gradient"
          >
            {{ $t("Button.ZimmerAuswaehlen") }}
          </v-btn>


        </div>

      </div>

    </div>

  </div>
</template>


<script>
import GalleryPopup from "@/components/gallery/GalleryPopup";
import {formatPrice, getCurrencyIcon} from "@/util/currency";


export default {
  name: "CardRoom2",
  props: [
    'roominfo',
    'place',
    'from',
    'index',
    'groupindex'
  ],
  components: {
    GalleryPopup
  },

  data() {
    return {
      currencyicon: getCurrencyIcon(),
      showGalleryPopup: false,
      showInfos: false,
      mealPlan: this.roominfo.rate_plans[0].rate_plan.meal_plan_name,

      isActiv: false
    }
  },
  methods: {
    getCurrencyIcon,
    formatPrice,
    selectRoom() {
      this.isActiv = true;
      this.$root.$emit("active", this.index, this.groupindex);
      this.$root.$emit("selectedroom", this.roominfo, this.groupindex);

      this.showInfos = false;


    },

    zuBooking(id, roomID) {
      let query = {
        ...this.$route.query,
        roomid: roomID
      };

      if (this.from !== 'design') {
        this.$store.dispatch('place', this.place);
        this.$router.push({path: 'Booking', query: query});
        window.scrollTo(0, 0);
      }
    },
    setMealPlan(meal) {
      this.mealPlan = meal;
    }


  },


  async created() {

    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });

    this.$root.$on("active", (val, grpindex) => {
      if (grpindex === this.groupindex) {
        if (val !== this.index) {
          this.isActiv = false;

        }
      }
    });

  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardRoom2.css";
</style>
