export const getCurrency = () => {
    let currency = localStorage.getItem("currency");

    if (currency === undefined)
        currency = "EUR";

    return currency;
}

export const getCurrencyIcon = (currency) => {

    if (currency === undefined) {
        currency = getCurrency();
    }
    switch (currency) {
        case "EUR":
            return "mdi-currency-eur";
        case "TRY":
            return "mdi-currency-try";
        case "USD":
            return "mdi-currency-usd";
        // case "GBP":
        //     return "mdi-currency-gbp";
        // case "AED":
        //     return "mdi-cash";
        // case "SAR":
        //     return "mdi-currency-rial";
        // case "RUB":
        //     return "mdi-currency-rub";
        default:
            return "mdi-currency-eur";
    }
}

export const formatPrice = (value) => {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

}