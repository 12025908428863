import axios from "axios";
import {getToken} from "./userCredentials";

export const getUserinfoByID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userinfo/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});

    return res.data.data;
}
export const getUserinfoByUserId = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userinfobyuid/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});

    return res.data.data;
}
export const getKontaktFromUserinfoByUserId = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userk/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});

    return res.data.data;
}

export const getRechtlichesByID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/rbid/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});

    return res.data.data.rechtliches;
}
