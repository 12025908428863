import axios from "axios";
import {getToken, getUserToken} from "../util/userCredentials";
import {getCurrency} from "../util/currency";
import moment from "moment";

const getAPI = () => {
    let lang = localStorage.getItem("lang");
    if (lang.toLowerCase() === "tr") {
        return "/api/"
        // process.env.VUE_APP_API_TR
    }
    if (lang.toLowerCase() === "de") {
        return "/api3/"
        // process.env.VUE_APP_API
    }
    if (lang.toLowerCase() === "en") {
        return "/api4/"
        // process.env.VUE_APP_API_EN
    }

    return process.env.VUE_APP_API;
}

const getAPIProd = () => {
    let lang = localStorage.getItem("lang");
    if (lang.toLowerCase() === "tr") {
        return process.env.VUE_APP_API_TR
        // process.env.VUE_APP_API_TR
    }
    if (lang.toLowerCase() === "de") {
        return process.env.VUE_APP_API
        // process.env.VUE_APP_API
    }
    if (lang.toLowerCase() === "en") {
        return process.env.VUE_APP_API_EN
        // process.env.VUE_APP_API_EN
    }

    return process.env.VUE_APP_API;
}

export const getInitPlaces = async (p) => {

    const token = await getUserToken()


    let currency = getCurrency();


    let d = {
        l: localStorage.getItem('lang'),
        currency: currency,
        page: p,
        token: token
    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halal", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;

    return data.data;
}


export const onePlace = async (id, groups) => {
    const token = await getUserToken()


    let currency = getCurrency();

    let d = {
        currency: currency,
        l: localStorage.getItem('lang'),
        token: token,
        groups: groups,
        id: id
    }
    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalone", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;
    return data;
}

export const onePlaceByID = async (id) => {
    const token = await getUserToken()


    let currency = getCurrency();

    let d = {
        currency: currency,
        l: localStorage.getItem('lang'),
        token: token,
        groups: "&groups[]=2",
        id: id
    }
    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalone", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;
    return data.data;
}

export const onePlaceWithParameter = async (id, checkin, checkout, groups) => {

    const token = await getUserToken();
    let currency = getCurrency();
    let res = "";

    let d = {
        token: token,
        currency: currency,
        id: id,
        // checkin: checkin,
        // checkout: checkout,
        checkin: moment(checkin).format("YYYY-MM-DD"),
        checkout: moment(checkout).format("YYYY-MM-DD"),
        groups: groups,
        l: localStorage.getItem('lang')
    }

    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halalonepara", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
        );
    } catch (e) {
        return "";
    }
    let data = res.data;

    return data.data;
}


export const onePlaceWithParameterLang = async (id, checkin, checkout, groups, currency) => {

    const token = await getUserToken();
    //let currency = getCurrency();
    let res = "";
    let d = {
        token: token,
        currency: currency,
        id: id,
        checkin: checkin,
        checkout: checkout,
        groups: groups,
        l: localStorage.getItem('lang')
    }
    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halalonepara", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
        );
    } catch (e) {
        return "";
    }
    let data = res.data;

    return data.data;
}
//-----------------------------------------------------------------------------------------------------------------
// wird bei der suche verwendet
export const callapi = async (url, page) => {
    const token = await getUserToken()

    let d = {
        token: token,
        l: localStorage.getItem('lang'),
        url: url.replaceAll("/","-"),
        page: page,
        currency: getCurrency()
    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalcall", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;
    return data.data;
}

//-----------------------------------------------------------------------------------------------------------------


export const getALLHalalPlaces = async (location, page) => {

    const token = await getUserToken()

    let d = {
        token: token,
        lang: localStorage.getItem('lang'),
        page: page,
        location: location,
        currency: getCurrency()

    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalcallall", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;
    return data.data;
}


export const getALLHalalPlacesWithgroups = async (location, page, groups) => {

    const token = await getUserToken()

    let d = {
        token: token,
        lang: localStorage.getItem('lang'),
        page: page,
        location: location,
        groups: groups,
        currency: getCurrency()

    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalcallg", d, {headers: {"authorization": 'Bearer ' + await getToken()}}
    );

    let data = res.data;
    return data.data;
}