<template>
  <div class="div-paypal">

    <div style="text-align: center; margin: 50px 0;" v-if="loading">
      <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </div>

    <div v-if="!paidFor">
      <h3 style="display: flex; align-items: baseline; justify-content: center">
        {{ descr }} - {{ formatPrice(price) }}
        <v-icon color="black">{{ currencyicon === 'mdi-currency-try' ? 'mdi-currency-eur' : currencyicon }}</v-icon>
      </h3>


    </div>

    <div v-if="paidFor">
      <h2>Ihre Buchung für das Hotel {{ descr }} war erfolgreich!</h2>
    </div>

    <div ref="paypal"></div>

  </div>
</template>

<script>

import {formatPrice, getCurrency, getCurrencyIcon} from "../../util/currency";
import {getAdminUser} from "../../util/DBUtil";
import {getAllSettings} from "../../util/settingsUtil";

export default {
  name: "Paypal",
  props: [
    'price',
    'descr',
    'order_id'
  ],

  data() {
    return {
      loading: true,

      loaded: false,
      paidFor: false,
      description: this.descr + ' - ' + this.order_id,

      currencyicon: getCurrencyIcon(),

    };
  },
  async mounted() {

    let settings = await getAllSettings();

    let paypalKey = settings.paypalKey;

    // let paypalKey = 'AfJxH3otJWYvsHBY5dluGI_-vxqaSCTWRtkioGoI5-kywSi-HWEacGwVzSgtvQzfl-9rRHTTOl40hAQo';

    const script = document.createElement("script");
    let currency = getCurrency();
    if (currency === "TRY") {
      currency = "EUR";
    }


    this.loading = false;

    script.src =
        "https://www.paypal.com/sdk/js?client-id=" + paypalKey + "&currency=" + currency;
    // "https://api-m.paypal.com/sdk/js?client-id=" + paypalKey + "&currency=" + currency;
    // "https://api-m.sandbox.paypal.com/sdk/js?client-id=" + paypalKey + "&currency=" + currency;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);


  },
  methods: {
    getCurrencyIcon,
    formatPrice,
    setLoaded() {
      this.description = this.descr + ' - ' + this.order_id;
      this.loaded = true;
      let currency = getCurrency();
      if (currency === "TRY") {
        currency = "EUR";
      }

      window.paypal
          .Buttons({

            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: this.description,
                    amount: {
                      currency_code: currency,
                      value: this.price
                    }

                  }
                ]
              });
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture();
              this.paidFor = true;

              this.$root.$emit("paypal_response", order.purchase_units[0].payments);
            },
            onError: err => {
              console.log(err);
            }
          })
          .render(this.$refs.paypal);
    }
  },
  
};
</script>


<style scoped>
@import "../../css/Paypal.css";
</style>