<template>
  <div>

    <div class="div-background-header"/>

    <div style="padding: 30px; margin: 100px auto 150px ; max-width: 1000px">
      <h3 style="font-size: 25px">Kooperation</h3>
      <div style="margin: 15px 0 " v-if="lang === 'DE'">
        Füllen Sie das Formular für eine Kooperation mit {{ pDomain }} aus und wir melden uns
        umgehend bei Ihnen!
      </div>
      <div style="margin: 15px 0 " v-if="lang === 'TR'">
        {{ pDomain.charAt(0).toUpperCase() + pDomain.slice(1) }} ile iş birliği için formu doldurun, size en kısa sürede
        dönüş yapalım!
      </div>

      <div style="margin: 15px 0 " v-if="lang === 'EN'">
        Fill out the form for a cooperation with {{ pDomain }} and we will contact you immediately!
      </div>

      <div>
        <v-text-field
            v-model="kunde.firma"
            :label="$t('Booking.Firma')+'*'"
            outlined
            @change="validateInputs"
            :error="checkFirma"
            class="vtext-firma-regi"
        />
        <div class="div-name-regi">


          <v-select
              v-model="kunde.anrede"
              :items="anrede"
              :item-value="'anredeid'"
              :label="$t('Booking.Anrede')+'*'"
              outlined
              @change="validateInputs"
              :error="checkAnrede"
          />
          <v-text-field
              v-model="kunde.vorname"
              :label="$t('Booking.Vorname')+'*'"
              outlined
              @change="validateInputs"
              :error="checkVorname"
          />
          <v-text-field
              v-model="kunde.nachname"
              :label="$t('Booking.Nachname')+'*'"
              outlined
              @change="validateInputs"
              :error="checkNachname"
          />
        </div>
        <div class="div-guest-mail-regi">
          <v-text-field
              v-model="kunde.email"
              :rules="[rules.required, rules.email]"
              :label="$t('Booking.E-Mail')+'*'"
              outlined
              prepend-inner-icon="mdi-at"
              @change="validateInputs"
              :error="checkEmail"
          />
          <v-text-field
              v-model="kunde.tel"
              type="number"
              :label="$t('Booking.Telefon')+'*'"
              outlined
              prepend-inner-icon="mdi-phone"
              @change="validateInputs"
              :error="checkTel"
              class="vtext-email-regi"

          />
          <v-text-field
              v-model="kunde.mobile"
              type="number"
              :label="$t('Booking.Mobile')"
              outlined
              prepend-inner-icon="mdi-cellphone"
              class="vtext-email-regi"
          />
          <v-text-field
              v-model="kunde.fax"
              type="number"
              :label="$t('Booking.Fax')"
              outlined
              prepend-inner-icon="mdi-fax"
              class="vtext-email-regi"
          />
        </div>

        <div>
          <v-text-field
              v-model="kunde.strnr"
              :label="$t('Booking.StraßeNr')+'*'"
              outlined
              @change="validateInputs"
              :error="checkStrnr"
              class="vtext-firma-regi"
          />

        </div>
        <div>

          <v-text-field
              v-model="kunde.addresszusatz"
              :label="$t('Booking.Adresszusatz')"
              outlined
              class="vtext-firma-regi"
          />
        </div>

        <div class="div-guest-plz-regi">
          <v-text-field
              v-model="kunde.plz"
              :label="$t('Booking.PLZ')+'*'"
              outlined
              type="number"
              @change="validateInputs"
              :error="checkPlz"
              class="vtext-firma-regi"
          />

          <v-text-field
              v-model="kunde.ort"
              :label="$t('Booking.Ort')+'*'"
              outlined
              @change="validateInputs"
              :error="checkOrt"
              class="vtext-firma-regi"
          />

        </div>


        <div class="div-notes-regi">
          <v-textarea
              v-model="notes"
              label="Information"
              outlined
              auto-grow

              class="vtext-firma-regi"

          />
        </div>
      </div>
      <div style="text-align: end; margin: 40px">
        <v-btn
            :disabled="checkinputs"
            @click="sendRegi"
            class="vbtn-cardroom vbtn-linear-gradient"
        >
          <v-icon v-if="checkinputs">
            mdi-alert
          </v-icon>
          <v-icon v-else>
            mdi-check
          </v-icon>
          {{ $t("Button.Senden") }}
        </v-btn>
      </div>

    </div>
  </div>
</template>

<script>

import {getCurrencyIcon} from "@/util/currency";
import {MailToAdminRegistration, MailTocustomerbevorconfirmation7} from "@/util/mailSend";
import {getAllAnrede} from "@/util/Anrede";

export default {
  name: "RegistrationContact",
  props: [
    'kontakt',
    'pDomain',
    'pLogo',
    'lang',
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      loading: false,

      anrede: [],
      kunde: {
        firma: "",
        anrede: "",
        vorname: "",
        nachname: "",
        // email: "info@ceeyuu.de",
        email: "",
        tel: "",
        mobile: "",
        fax: "",

        strnr: "",
        plz: "",
        ort: "",
        addresszusatz: "",

      },
      notes: "",


      checkinputs: true,
      checkFirma: false,
      checkAnrede: false,
      checkVorname: false,
      checkNachname: false,
      checkEmail: false,
      checkTel: false,
      checkStrnr: false,
      checkPlz: false,
      checkOrt: false,


      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },
    }
  },
  methods: {

    validateInputs() {
      let validate = true;

      if (this.kunde.firma === "") {
        this.checkFirma = true;
        validate = false;
      } else {
        this.checkFirma = false;
      }

      if (this.kunde.anrede === "") {
        this.checkAnrede = true;
        validate = false;
      } else {
        this.checkAnrede = false;
      }

      if (validate === true && this.kunde.vorname === "" ) {
        this.checkVorname = true;
        validate = false;
      } else {
        this.checkVorname = false;
      }

      if (validate === true && this.kunde.nachname === "") {
        this.checkNachname = true;
        validate = false;
      } else {
        this.checkNachname = false;
      }

      if (validate === true && this.kunde.email === "") {
        this.checkEmail = true;
        validate = false;
      } else {
        this.checkEmail = false;
      }


      if (validate === true && this.kunde.tel === "") {
        this.checkTel = true;
        validate = false;
      } else {
        this.checkTel = false;
      }


      if (validate === true && this.kunde.strnr === "") {
        this.checkStrnr = true;
        validate = false;
      } else {
        this.checkStrnr = false
      }


      if (validate === true && this.kunde.plz === "") {
        this.checkPlz = true;
        validate = false;
      } else {
        this.checkPlz = false;
      }


      if (validate === true && this.kunde.ort === "") {
        this.checkOrt = true;
        validate = false;
      } else {
        this.checkOrt = false;
      }


      if (validate === true) {
        this.checkinputs = false;
      }

      if (validate === false) {
        this.checkinputs = true;
      }

    },

    async sendRegi() {
      //Sende Mail an Admin
      let data = {

        firma: this.kunde.firma,
        anrede: this.kunde.anrede,
        vorname: this.kunde.vorname,
        nachname: this.kunde.nachname,

        email: this.kunde.email,
        tel: this.kunde.tel,
        mobile: this.kunde.mobile,
        fax: this.kunde.fax,

        strnr: this.kunde.strnr,
        plz: this.kunde.plz,
        ort: this.kunde.ort,
        addresszusatz: this.kunde.addresszusatz,
        notes: this.notes,
        adminmail: this.kontakt.email

      };


      let logo = this.pLogo;
      logo = logo.replace("//", "");
      logo = logo.split("/")[1];
      data.logo = this.pLogo;
      data.filename = logo;

      data.domain = this.pDomain;

      await MailToAdminRegistration(data);
    }

  },
  created() {
    window.scrollTo(0, 2);

    let anrede = getAllAnrede(this.lang);
    anrede.map(item => this.anrede.push(item));


  }
}
</script>

<style scoped>
@import "../css/RegistrationContact.css";
</style>
