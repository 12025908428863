<template>
  <div class="div-cardsmalllocation" @click="zuPlace()">
    <div class="div-img-cardsmalllocation">
      <img :src="item.photo" alt="Photo" class="img-cardsmalllocation">
      <h4 class="label-cardscmalllocation">
        {{ getTitle() }}
      </h4>
      <div class="div-background-cardsmalllocation"></div>
    </div>
  </div>
</template>


<script>

export default {
  name: "CardSmallLocation",
  props: [
    'item',

  ],
  data() {
    return {
      lang: 'DE',

    }
  },
  methods: {
    zuPlace() {
      if (this.item.fav_type === 'hotel') {
        localStorage.removeItem('desP');
        localStorage.removeItem('des');
        localStorage.removeItem('desID');

        localStorage.removeItem('ht');
        localStorage.removeItem('htP');
        localStorage.removeItem('htC');


        this.$router.push({
          path: 'place',
          query: {id: this.item.id}
        });
        window.scrollTo(0, 0);


      }

      if (this.item.fav_type !== 'hotel') {
        this.$router.push({
          path: 'home',
          query: {name: this.item.name}
        });
        window.scrollTo(0, 0);


        localStorage.setItem('desP', this.item.name_en);
        localStorage.setItem('des', this.getTitle());
        localStorage.setItem('desID', this.item.id);
      }


    },


    getTitle() {
      if (this.lang.toLowerCase() === 'de') {
        // return this.item.name_de.includes(",") ? this.item.name_de.split(",")[0] : this.item.name_de;
        if (this.item.fav_type === "city") {
          return this.item.name_de.includes(",") ? this.item.name_de.split(",")[0] : this.name_de;
        }
        return this.item.name_de;

      }
      if (this.lang.toLowerCase() === 'tr') {
        if (this.item.fav_type === "city") {
          return this.item.name_tr.includes(",") ? this.item.name_tr.split(",")[0] : this.name_tr;
        }
        return this.item.name_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        if (this.item.fav_type === "city") {
          return this.item.name_en.includes(",") ? this.item.name_en.split(",")[0] : this.name_en;
        }
        return this.item.name_en;
      }
    }

  },


  async created() {
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang');
    }
  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardSmallLocation.css";
</style>
