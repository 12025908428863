<template>
  <div>
    <div class="div-background-header"/>
    <div class="div-successfulbooking">
      <h2>
        <v-icon color="green" large>mdi-check</v-icon>
        Ihre Buchung war erfolgreich!
      </h2>
      <br>
      <h3 style="margin-bottom: 65px">Sie werden in kürze eine E-Mail erhalten</h3>

      <div class="div-body-successfulbooking">

        <div class="div-block-successfulbooking">
          <h3 class="h1-title-favs-successfulbooking">
            {{ $t("Home.BeliebteHotels") }}
          </h3>


          <div class="div-cardslocation-successfulbooking">

            <template v-if="showSkeleton" v-for="item in 5">
              <v-skeleton-loader
                  class="mx-auto"
                  min-width="175"
                  max-width="300"
                  type="card"
              />
            </template>

            <template v-for="(item) in settings.favorite_hotel">
              <div>
                <CardSmallLocation
                    :item="item"
                />
              </div>
            </template>


          </div>
        </div>

        <div class="div-block-successfulbooking">
          <h3 class="h1-title-favs-successfulbooking">
            {{ $t("Home.BeliebteRegionen") }}
          </h3>


          <div class="div-cardslocation-successfulbooking">

            <template v-if="showSkeleton" v-for="item in 5">
              <v-skeleton-loader
                  class="mx-auto"
                  min-width="175"
                  max-width="300"
                  type="card"
              />
            </template>

            <template v-for="(item,index) in settings.favorite_location">
              <div>
                <CardSmallLocation
                    :item="item"
                />
              </div>
            </template>


          </div>
        </div>

        <div class="div-block-successfulbooking">
          <h3 class="h1-title-favs-successfulbooking">
            {{ $t("Home.BeliebteLaender") }}
          </h3>


          <div class="div-cardslocation-successfulbooking">

            <template v-if="showSkeleton" v-for="item in 5">
              <v-skeleton-loader
                  class="mx-auto"
                  min-width="175"
                  max-width="300"
                  type="card"
              />
            </template>

            <template v-for="(item,index) in settings.favorite_country">
              <div>
                <CardSmallLocation
                    :item="item"
                />
              </div>
            </template>


          </div>
        </div>


        <div class="div-block-successfulbooking">
          <h3 class="h1-title-favs-successfulbooking">
            {{ $t("Home.BeliebteStaedte") }}
          </h3>


          <div class="div-cardslocation-successfulbooking">

            <template v-if="showSkeleton" v-for="item in 5">
              <v-skeleton-loader
                  class="mx-auto"
                  min-width="175"
                  max-width="300"
                  type="card"
              />
            </template>

            <template v-for="(item,index) in settings.favorite_city">
              <div>
                <CardSmallLocation
                    :item="item"
                />
              </div>
            </template>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import CardSmallLocation from "../components/card/CardSmallLocation";
import {getAllSettings} from "../util/settingsUtil";


export default {
  name: "SuccessfulBooking",
  components: {
    CardSmallLocation
  },


  data() {
    return {
      settings: "",
      showSkeleton: true
    }
  },
  methods: {
    async gotoHome() {

      // await new Promise(resolve => setTimeout(resolve, 3000));
      // await this.$router.push("/");
    }

  },
  async created() {
    window.scrollTo(0, 2);
    // await this.gotoHome();


    this.settings = await getAllSettings();
    this.showSkeleton = false;
  }
}

</script>
<style scoped>
@import "../css/SuccessfulBooking.css";

</style>