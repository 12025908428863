<template>
  <div class="div-language-header">
    <img class="img-flag-header" :src="getFlag(lang)" alt="flag"/>

    <v-select
        v-model="$i18n.locale"
        :items="items"
        :label="lang"
        solo
        style="padding: 0 !important"
        @change="changeLang"
        class="vselect-language"

    >
      <template slot="item" slot-scope="data">
        <img :src="getFlag(data.item)" alt="flag"/>
        {{ data.item }}
      </template>
    </v-select>
  </div>
</template>
<script>
import DE from "../../assets/Flags/de.png";
import TR from "../../assets/Flags/tr.png";
import EN from "../../assets/Flags/en.png";

export default {
  name: "LanguageSwitcher",
  props: [
    'lang'
  ],

  data() {
    return {
      de: DE,
      tr: TR,
      en: EN,
      items: ["DE", "TR", "EN"],
      flags: ["DE", "TR", "EN"]
    };
  },
  methods: {
    getFlag(item) {
      switch (item) {
        case "DE":
          return this.de;
        case "TR":
          return this.tr;
        case "EN":
          return this.en;
        default:
          return this.de;
      }
    },
    changeLang() {
      localStorage.setItem("lang", this.$i18n.locale);

      //Damit die Suche gelöscht wird.
      localStorage.removeItem('des');
      localStorage.removeItem('desP');
      localStorage.removeItem('desID');
      localStorage.removeItem('ht');
      localStorage.removeItem('htP');
      localStorage.removeItem('htC');

      location.reload();
    }
  }
};
</script>
<style scoped></style>
