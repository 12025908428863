<template>
  <div class="div-favcountries">
    <div v-for="(fav, index) in item"
         class="div-favcountries1"
         @mouseover="setClassActive(index)"
         @mouseleave="showtext = false"
         @click="zuPlace(index)"
    >


      <img :src="fav.photo" alt="Photo" class="img-favcountries">

      <div class="div-text-favcountries">
        {{ getTitle(index) }}
      </div>


      <div
          v-bind:class="[showtext && checkNR===index ?'div-showhovertext-favcountries':'div-hidehovertext-favcountries']">
        <div class="div-txthover-faveregions">
          <div v-if="fav" style="font-size: 26px;">
            {{ getTitle(index) }}
          </div>
          <div style="font-weight: 300">
            {{ getDescrText(index) }}
          </div>
          <br>
          <div style="font-size: 12px;">
            Ziele in {{ getTitle(index) }}
          </div>
          <label style="font-size: 18px;" v-if="fav.properties_count">
            {{ fav.properties_count }}
          </label>


          <div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Meer1 from "../../assets/Fotos/Meer1.jpg";
import Meer2 from "../../assets/Fotos/Meer2.jpg";
import Meer3 from "../../assets/Fotos/Meer3.jpg";
import Meer4 from "../../assets/Fotos/Meer4.jpg";
import Meer5 from "../../assets/Fotos/Meer5.jpg";
import Meer6 from "../../assets/Fotos/Meer6.jpg";
import Meer7 from "../../assets/Fotos/Meer7.jpg";
import Meer8 from "../../assets/Fotos/Meer7.jpg";

export default {
  name: "FavCountries",
  props: [
    'item',
    'lang'
  ],

  data() {
    return {
      // pics: [Meer1, Meer2, Meer3, Meer4, Meer5, Meer6, Meer7, Meer8],
      showtext: false,
      checkNR: ""


    }
  },
  methods: {
    setClassActive(index) {
      this.showtext = true;
      this.checkNR = index;

    },
    getTitle(index) {
      if (this.lang.toLowerCase() === 'de') {
        return this.item[index].name_de;
      }

      if (this.lang.toLowerCase() === 'tr') {
        return this.item[index].name_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        return this.item[index].name_en;
      }
    },
    getDescrText(index) {
      if (this.lang.toLowerCase() === 'de') {
        return this.item[index].desc_de;
      }

      if (this.lang.toLowerCase() === 'tr') {
        return this.item[index].desc_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        return this.item[index].desc_en;
      }
    },

    zuPlace(index) {
      if (this.item[index].fav_type === 'hotel') {
        localStorage.removeItem('desP');
        localStorage.removeItem('des');
        localStorage.removeItem('desID');

        localStorage.removeItem('ht');
        localStorage.removeItem('htP');
        localStorage.removeItem('htC');


        this.$router.push({
          path: 'place',
          query: {id: this.item[index].id}
        });
        window.scrollTo(0, 0);


      }

      if (this.item[index].fav_type !== 'hotel') {

        this.$router.push({
          path: 'home',
          query: {name: this.item[index].name}
        });
        window.scrollTo(0, 0);


        localStorage.setItem('desP', this.item[index].name_en);
        localStorage.setItem('des', this.getTitle(index));
        localStorage.setItem('desID', this.item[index].id);

      }


    },

  }
}
</script>

<style scoped>
@import "../../css/FavCountries.css";
</style>