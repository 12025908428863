<template>
  <div class="div-totalprice">

    <div style="border-bottom: 1px solid lightgray; margin-bottom: 15px;">
      <h3>
        {{ $t("Card.Uebersicht") }}
      </h3>
    </div>

    <div>
      <b>{{ $t("Card.Checkin") }}:</b> {{ moment(checkin, "YYYY-MM-DD").format('ddd, DD. MMMM') }}
    </div>
    <div>
      <b>{{ $t("Card.Checkout") }}:</b> {{ moment(checkout, "YYYY-MM-DD").format('ddd, DD. MMMM') }}
    </div>


    <div v-for="(item, index) in gruppe">
      <div style="margin-top: 15px">

        <b>{{ $t('Card.Gruppe') }} {{ index + 1 }} </b> <br>
        <span>{{ $t('Card.Personen') }}: </span>
        <span v-if="item.erwachsene !== undefined"> {{ item.erwachsene }} {{ $t('Card.Erwachsene') }}</span>
        <span v-if="item.kinder === 1">, {{ item.kinder }} {{ $t('Card.Kind') }}</span>
        <span v-if="item.kinder >1">, {{ item.kinder }} {{ $t('Card.Kinder') }}</span>


        <div v-if="selectedRoom[index].text !== undefined" style="color: red;">
          {{ selectedRoom[index].text }}
        </div>

        <div v-if="selectedRoom[index].room !== undefined" class="div-room-price-totalprice">
          <div class="div-room-price-totalprice1">
            {{ selectedRoom[index].quantity }} x {{ selectedRoom[index].room.name }}
          </div>

          <div class="div-room-price-totalprice2">
            <span style="display: flex; align-items: baseline; justify-content: flex-end">
              <span v-if="selectedRatePlan[index].preis === 0">
                {{ formatPrice(selectedRoom[index].price) }}
              </span>
              <span v-else>
                {{ formatPrice(selectedRatePlan[index].preis) }}
              </span>
              <v-icon color="black" small>{{ currencyicon }}</v-icon>
            </span>

            <span v-if="(europreisprogruppe !== undefined && europreisprogruppe.length > 0) && europreisprogruppe !== 0"
                  class="div-total-price-totalprice3">
              <span>
                ({{ formatPrice(europreisprogruppe[index].price) }} €)
              </span>
            </span>

            <span v-if="trypreisprogruppe !== undefined && trypreisprogruppe !== 0"
                  class="div-total-price-totalprice3">
              <span>
                (
                {{ formatPrice(trypreisprogruppe[index].price) }}
                <v-icon small>{{ getCurrencyIcon('TRY') }}</v-icon>
                )
              </span>
            </span>
          </div>

        </div>

        <div v-if="selectedRatePlan[index].name !== '' && selectedRatePlan[index].length !== 1">
          {{ selectedRatePlan[index].name }}
        </div>

      </div>

    </div>

    <div class="div-total-price-totalprice">

      <div>
        {{ $t('Card.Gesamtpreis') }}:
      </div>
      <div class="div-total-price-totalprice2">
        {{ formatPrice(gesamtpreis) }}
        <v-icon color="black" small>{{ currencyicon }}</v-icon>
      </div>
      <span></span>
      <div v-if="eurogesamtpreis !== undefined && eurogesamtpreis !== 0" class="div-total-price-totalprice3">
        ({{ formatPrice(eurogesamtpreis) }} €)
      </div>
      <div v-if="trygesamtpreis !== undefined && trygesamtpreis !== 0" class="div-total-price-totalprice3">
        (
        {{ formatPrice(trygesamtpreis) }}
        <v-icon small>{{ getCurrencyIcon('TRY') }}</v-icon>
        )
      </div>

    </div>

    <div class="div-btn-totalprice" v-if="showbutton">
      <!--      <v-btn-->
      <!--          @click="zuBooking"-->
      <!--          color="var(&#45;&#45;Primary)"-->
      <!--          class="vbtn-totalprice"-->
      <!--          :disabled="!checkinput"-->
      <!--      >-->
      <!--        {{ $t('Button.WeiterZurBuchung') }}-->
      <!--      </v-btn>-->
      <v-btn
          @click="zuBooking"
          class="vbtn-totalprice "
          :disabled="!checkinput"
          :color="!checkinput ? 'grey':'green'"

      >
        {{ $t('Button.WeiterZurBuchung') }}
      </v-btn>

    </div>

  </div>

</template>

<script>
import {formatPrice, getCurrencyIcon} from "../../util/currency";

export default {
  props: [
    'checkin',
    'checkout',
    'halalPlace',
    'halalPlaceGroups',
    'gruppe',
    'selectedRoom',
    'gesamtpreis',
    'checkinput',
    'selectedRatePlan',
    'showbutton',
    'eurogesamtpreis',
    'europreisprogruppe',
    'trygesamtpreis',
    'trypreisprogruppe',
    'totalprice_int'
  ],
  name: "TotalPrice",
  data() {
    return {
      currencyicon: getCurrencyIcon(),
      int: 0

    }
  },
  methods: {
    getCurrencyIcon,
    formatPrice,

    zuBooking() {

      localStorage.setItem("gruppe", JSON.stringify(this.gruppe));
      localStorage.setItem("selectedroom", JSON.stringify(this.selectedRoom));
      let query = {
        ...this.$route.query,
        id: this.halalPlace.place.id
      }
      this.$router.push({path: "/booking", query: query});
    },
  },

  created() {

    this.$moment.locale(localStorage.getItem('lang'));


  }
}
</script>

<style scoped>
@import "../../css/TotalPrice.css";
</style>