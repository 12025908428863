import axios from "axios";
import {getToken, getUserToken} from "./userCredentials";

export const getRoomAndHotelCard = async (userid) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/roomandhotel/" + userid, {headers: {"authorization": 'Bearer ' + await getToken()}});
    let data = res.data.data;

    return data;
}

export const getAdminUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/auser" , {headers: {"authorization": 'Bearer ' + await getToken()}});
    let user = res.data.data;

    return user;
}
export const getIDFromAdminUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/aid" , {headers: {"authorization": 'Bearer ' + await getToken()}});
    let user = res.data.data;

    return user;
}

export const getLogo = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/asdfr/"+id , {headers: {"authorization": 'Bearer ' + await getToken()}});
    let user = res.data.data;

    return user;
}