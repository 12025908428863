import jwt from "jsonwebtoken";

export const getUserToken = async () => {


    let username = process.env.VUE_APP_USERNAME;
    let password = process.env.VUE_APP_PASSWORD;

    
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token).toString('base64');

    return encodedToken;
}

export const getToken = async () => {

    let payload = {
        name: "kunde"
    }

    let token = jwt.sign(payload, process.env.VUE_APP_JWTSECRET, {expiresIn: 46800});
    return token;
}
