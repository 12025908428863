<template>
  <div class="div-currency">
    <v-icon color="white">{{ getCurrency(this.currency) }}</v-icon>

    <v-select
        id="selectcurrency"
        v-model="currency"
        :items="items"
        :label="getSelect"
        solo
        style="padding: 0 !important"
        @change="changeCurrency"
        class="vselect-currency"
    >
      <template slot="item" slot-scope="data">
        <v-icon color="black">{{ getCurrency(data.item) }}</v-icon>
        {{ data.item }}
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  name: "Currency",
  data() {
    return {

      currency: "EUR",
      // select: "EUR",
      eur: "mdi-currency-eur",
      try: "mdi-currency-try",
      usd: "mdi-currency-usd",
      gbp: "mdi-currency-gbp",
      aed: "mdi-cash",
      sar: "mdi-currency-rial",
      rub: "mdi-currency-rub",
      // items: ["EUR", "TRY", "USD", "GBP", "AED", "SAR", "RUB"],
      items: ["EUR", "USD", "TRY"],
      open: false
    };
  },
  methods: {
    getCurrency(cur) {
      switch (cur) {
        case "EUR":
          return this.eur;
        case "TRY":
          return this.try;
        case "USD":
          return this.usd;
          // case "GBP":
          //   return this.gbp;
          // case "AED":
          //   return this.aed;
          // case "SAR":
          //   return this.sar;
          // case "RUB":
          //   return this.rub;
        default:
          return this.eur;
      }
    },
    changeCurrency() {
      localStorage.setItem("currency", this.currency);
      this.$router.go();
    }
  },
  computed: {
    getSelect() {
      localStorage.setItem("currency", this.currency);
      return this.currency;

    }
  },
  created() {
    if (localStorage.getItem("currency")) {
      this.currency = localStorage.getItem("currency");
    }
  }
};

</script>

<style scoped>
@import "../../css/Currency.css";
</style>