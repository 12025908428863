<template>
  <div class="div-favregions">
    <h2 class="h1-title-favsregion">
      {{ $t("Home.BeliebteRegionen") }}
    </h2>
    <div style="text-align: center; margin: 0 5px 40px 8px">
      Check out the list of countries and places you can travel to by joining us.
    </div>
    <img :src="Beachfoto" class="img-favregions">
    <div class="div-favregions1">
      <template v-for="(region,index) in item">
        <!--      <div>-->
        <CardSmallLocation
            :item="region"
        />
        <!--      </div>-->
      </template>

    </div>

  </div>
</template>

<script>
import CardSmallLocation from "../card/CardSmallLocation";
import Beachfoto from "../../assets/Fotos/Beach_Vater_Tochter1.png";

export default {
  name: "FavRegions",
  props: [
    'item',
    'lang'
  ],

  components: {
    CardSmallLocation
  },


  data() {
    return {
      Beachfoto: Beachfoto,
      showtext: false,
      checkNR: ""
    }
  },


  methods: {
    setClassActive(index) {
      this.showtext = true;
      this.checkNR = index;

    },

    getTitle(index) {
      if (this.lang.toLowerCase() === 'de') {
        return this.item[index].name_de;
      }

      if (this.lang.toLowerCase() === 'tr') {
        return this.item[index].name_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        return this.item[index].name_en;
      }
    },

    zuPlace(index) {
      if (this.item[index].fav_type === 'hotel') {
        localStorage.removeItem('desP');
        localStorage.removeItem('des');
        localStorage.removeItem('desID');

        localStorage.removeItem('ht');
        localStorage.removeItem('htP');
        localStorage.removeItem('htC');


        this.$router.push({
          path: 'place',
          query: {id: this.item[index].id}
        });
        window.scrollTo(0, 0);


      }

      if (this.item[index].fav_type !== 'hotel') {

        this.$router.push({
          path: 'home',
          query: {name: this.item[index].name}
        });
        window.scrollTo(0, 0);


        localStorage.setItem('desP', this.item[index].name_en);
        localStorage.setItem('des', this.getTitle(index));
        localStorage.setItem('desID', this.item[index].id);

      }


    },

  }
}
</script>

<style scoped>
@import "../../css/FavRegions.css";
</style>