<template>
  <div>

    <div class="div-background-header"/>
    <div style="padding: 30px; margin-top: 60px; max-width: 1500px">
      <h2>Datenschutzerklärung</h2>
      <br>
      <label>Wir schützen Ihre Privatsphäre. Die gesetzeskonforme Erhebung, Verarbeitung und Nutzung Ihrer
        personenbezogenen Daten ist uns sehr wichtig und erfolgt im Einklang mit den Bestimmungen der
        Datenschutz-Grundverordnung (EU) 2016/679 (DSGVO). Diese Datenschutzerklärung informiert Sie im Folgenden über
        die
        Art, den Umfang und Zweck der Verarbeitung Ihrer personenbezogenen Daten bei dem Besuch unserer
        Webseite.</label>

      <br>
      <br>
      <h4>Personenbezogene Daten</h4>
      <label>Personenbezogene Daten nach den Vorgaben der DSGVO sind alle Informationen, die sich auf eine
        identifizierte
        oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine Person angesehen, die direkt
        oder
        indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten,
        zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
        der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
        dieser natürlichen Person sind (Art. 4 Nr. 1 DSGVO).</label>
      <label>Die Speicherung Ihrer personenbezogenen Daten erfolgt nur in den Fällen, soweit dies zur Erbringung der
        gebuchten Leistung, zur Einhaltung gesetzlicher Vorgaben oder zu einem der im Folgenden genannten Zwecke
        erforderlich ist.</label>

      <br>
      <br>
      <h4>Verantwortlicher:</h4>
      <label>Verantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten:</label>

      <br>
      <br>
      <h4>{{ pDomain }}</h4>
      <h4>{{ kontakt.strnr }}</h4>
      <h4>{{ kontakt.plz }} {{ kontakt.ort }}</h4>
      <h4>Geschäftsführung durch: {{ pRechtliches.geschaftsfuhrer }}</h4>

      <br>
      <br>

      <h4>Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten</h4>
      <label>Wir verarbeiten Ihre personenbezogenen Daten grundsätzlich nur, soweit dies zur Bereitstellung einer
        funktionsfähigen Webseite sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung Ihrer
        personenbezogenen Daten erfolgt nur nach Ihrer Einwilligung. Eine Ausnahme gilt in solchen Fällen, in denen eine
        vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der
        Daten
        durch gesetzliche Vorschriften gestattet ist.</label>
      <label>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung von Ihnen einholen, dient
        Art.
        6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</label>
      <label>Bei der Verarbeitung Ihrer personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
        Vertragspartei
        Sie sind, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
        Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</label>
      <label>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich
        ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.</label>
      <label>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person
        eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.</label>
      <label>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten
        erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
        Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.</label>
      <label>Die von uns gespeicherten personenbezogenen Daten nutzen wir auch zur Pflege der Kundenbeziehungen, zur
        Betreuung unserer Kunden (z.B. wichtige Informationen zu Ihrer Reise), zur Durchführung von eigenen Werbe- und
        Marketingmaßnahmen (z.B. dem Versand von Katalogen sonstigen Werbesendungen im gesetzlich zulässigen Rahmen,
        Rückfragen zur Kundenzufriedenheit) und zur Auftragsabwicklung.</label>
      <label>Bei der Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Bewerbungsverfahrens dient Art. 6 Abs. 1
        lit. f DSGVO als Rechtsgrundlage. Ihre Bewerbungsunterlagen werden nach Erhalt von den Mitarbeitern unserer
        Personalabteilung gesichtet und intern an die verantwortlichen Abteilungsleiter weitergeleitet. Im Rahmen des
        Bewerbungsverfahrens haben nur die Mitarbeiter Zugriff auf Ihre Daten, die für den ordnungsgemäßen Ablauf
        erforderlich sind.</label>

      <br>
      <br>

      <h4>Weitergabe von Daten</h4>
      <label>Die Weitergabe und Übermittlung der personenbezogenen Daten an Dritte zu anderen als der im Folgenden
        aufgeführten Zwecken findet nicht statt.</label>

      <br>
      <label>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</label>
      <ul>
        <li><label>eine Weitergabe gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung des
          Vertragsverhältnissen mit Ihnen erforderlich ist,</label></li>
        <li><label>für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
          besteht,</label>
        </li>
        <li><label>eine Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung
          von
          Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
          schutzwürdiges
          Interesse an der Nichtweitergabe Ihrer Daten haben, oder</label></li>
        <li><label>Sie uns für die Weitergabe Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu
          erteilt haben.</label></li>
      </ul>
      <br>

      <h4> Datenlöschung und Speicherdauer</h4>
      <label> Die personenbezogenen Daten der betroffenen Person werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht
        oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn
        dies
        durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
        Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten
        erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass
        eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung
        besteht.</label>

      <br>
      <br>
      <h4> Cookies</h4>
      <label>Wir setzen temporäre und permanente Cookies, d.h. kleine Dateien, die auf den Geräten der Nutzer
        gespeichert
        werden ein. Cookies sind Informationen, die von unserem Webserver oder Webservern Dritter an die Web-Browser der
        Nutzer übertragen und dort für einen späteren Abruf gespeichert werden. Bei Cookies kann es sich um kleine
        Dateien
        oder sonstige Arten der Informationsspeicherung handeln. Durch das Speichern der von Ihnen abgefragten Daten auf
        Ihrem Computer wird die Benutzung unserer Webseite für Sie erleichtert. Bei erneutem Aufrufen der Webseite
        werden
        die gespeicherten Daten wieder angezeigt. Die Benutzerdaten werden dabei zu keiner Zeit auf den Servern von
        e-hoi
        gespeichert.</label>
      <label> Zum Teil dienen die Cookies der Sicherheit oder sind zum Betrieb unseres Onlineangebotes erforderlich
        (z.B.,
        für die Darstellung der Webseite) oder um die Nutzerentscheidung bei der Bestätigung des Cookie-Banners zu
        speichern. Daneben setzen wir oder unsere Technologiepartner Cookies zur Reichweitenmessung und Marketingzwecken
        ein, worüber die Nutzer im Laufe der Datenschutzerklärung informiert werden.</label>
      <label> Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
        bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
        http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Falls Sie
        nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in
        den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen
        des
        Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes
        führen.</label>
      <label> Wir verwenden „Session-Cookies“, die nur für die Zeitdauer des aktuellen Besuchs auf unserer Onlinepräsenz
        abgelegt werden (z.B. um die Speicherung Ihres Login-Status oder die Warenkorbfunktion und somit die Nutzung
        unseres Onlineangebotes überhaupt ermöglichen zu können). In einem Session-Cookie wird eine zufällig erzeugte
        eindeutige Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe
        über
        seine Herkunft und die Speicherfrist. Diese Cookies können keine anderen Daten speichern. Session-Cookies werden
        gelöscht, wenn Sie die Nutzung unseres Onlineangebotes beendet haben und sich z.B. ausloggen oder den Browser
        schließen.</label>

      <br>
      <br>
      <h4>Speicherung der IP-Adresse</h4>
      <label> Eine IP-Adresse ist eine eindeutige Nummer, die Ihr Internetprovider Ihrem Computer zuteilt, sobald Sie
        eine
        Verbindung ins Internet aufbauen. Beim Besuch unserer Webseite wird die Ihrem Rechner zugeordnete IP-Adresse von
        unserem Webserver dauerhaft gespeichert. Diese IP-Adresse kann im Rahmen statistischer Auswertungen von uns
        genutzt werden und wird in regelmäßigen Abständen (jedes halbe Jahr) von unseren Servern gelöscht und
        archiviert.
        Ihre personenbezogenen Daten fließen jedoch stets anonymisiert in diese Auswertungen ein. Ihre IP-Adresse wird
        nur
        an Dritte weitergegeben, sofern dies gesetzlich vorgeschrieben ist, oder die Daten von Dritten im Auftrag
        verarbeitet werden.</label>

      <br>
      <br>
      <h4> Google Analytics</h4>
      <label> Wir setzen auf der Grundlage unserer berechtigten Interessen (d. h. Interesse an der Analyse, Optimierung
        und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics,
        einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten
        Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google
        in den USA übertragen und dort gespeichert.</label>
      <label> Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
        europäische Datenschutzrecht einzuhalten
        (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).</label>
      <label> Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch
        die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und
        um
        weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns
        gegenüber
        zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
        werden.</label>
      <label> Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner
        geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
        haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten
        Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog. „Remarketing-“, bzw.
        „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten wir auch sicherstellen, dass unsere
        Anzeigen dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken.</label>
      <label> Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der
        Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
        Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
        einen
        Server von Google in den USA übertragen und dort gekürzt.</label>
      <label> Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
        zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer
        Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und
        auf
        ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem sie das unter folgendem Link verfügbare Browser-Plug-In herunterladen und installieren:
        https://tools.google.com/dlpage/gaoptout?hl=de.</label>
      <label> Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren
        Sie auf den Webseiten von Google: https://www.google.com/intl/de/policies/privacy/partners („Datennutzung durch
        Google bei Ihrer Nutzung von Webseiten oder Apps unserer Partner“), https://policies.google.com/technologies/ads
        („Datennutzung zu Werbezwecken“), https://adssettings.google.com/authenticated („Informationen verwalten, die
        Google verwendet, um Ihnen Werbung einzublenden“).</label>

      <br>
      <br>
      <h4> Google-Re/Marketing-Services</h4>
      <label> Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Marketing- und
        Remarketing-Dienste (kurz „Google-Marketing-Services”) der Google LLC, 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA, („Google“).</label>
      <label> Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
        europäische Datenschutzrecht einzuhalten
        (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).</label>
      <label> Die Google-Marketing-Services erlauben uns Werbeanzeigen für und auf unserer Webseite gezielter
        anzuzeigen,
        um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B.
        Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Webseiten interessiert hat, spricht man
        hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen
        Google-Marketing-Services aktiv sind, unmittelbar durch Google ein Code von Google ausgeführt und es werden sog.
        (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als "Web Beacons" bezeichnet) in die Webseite
        eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei
        abgespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). Die Cookies können von
        verschiedenen Domains gesetzt werden, unter anderem von google.com, doubleclick.net, invitemedia.com,
        admeld.com,
        googlesyndication.com oder googleadservices.com. In dieser Datei wird vermerkt, welche Webseiten der Nutzer
        aufgesucht, für welche Inhalte er sich interessiert und welche Angebote er geklickt hat, ferner technische
        Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur
        Nutzung
        des Onlineangebotes. Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei wir im Rahmen von
        Google-Analytics
        mitteilen, dass die IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und nur in Ausnahmefällen ganz an
        einen Server von Google in den USA übertragen und dort gekürzt wird. Die IP-Adresse wird nicht mit Daten des
        Nutzers innerhalb von anderen Angeboten von Google zusammengeführt. Die vorstehend genannten Informationen
        können
        seitens Google auch mit solchen Informationen aus anderen Quellen verbunden werden. Wenn der Nutzer anschließend
        andere Webseiten besucht, können ihm entsprechend seiner Interessen die auf ihn abgestimmten Anzeigen angezeigt
        werden.</label>
      <label> Die Daten der Nutzer werden im Rahmen der Google-Marketing-Services pseudonym verarbeitet. D.h. Google
        speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten
        Daten Cookie-bezogen innerhalb pseudonymer Nutzer-Profile. D.h. aus der Sicht von Google werden die Anzeigen
        nicht
        für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon
        wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne
        diese Pseudonymisierung zu verarbeiten. Die von Google-Marketing-Services über die Nutzer gesammelten
        Informationen werden an Google übermittelt und auf Googles Servern in den USA gespeichert.</label>
      <label> Zu den von uns eingesetzten Google-Marketing-Services gehört u.a. das Online-Werbeprogramm „Google
        AdWords“.
        Im Fall von Google AdWords, erhält jeder AdWords-Kunde ein anderes „Conversion-Cookie“. Cookies können somit
        nicht
        über die Webseiten von AdWords-Kunden nachverfolgt werden. Die mit Hilfe des Cookies eingeholten Informationen
        dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking
        entschieden
        haben. Die AdWords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
        mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
        Informationen,
        mit denen sich Nutzer persönlich identifizieren lassen.</label>
      <label> Wir können auf Grundlage des Google-Marketing-Services "DoubleClick" Werbeanzeigen Dritter einbinden.
        DoubleClick verwendet Cookies, mit denen Google und seinen Partner-Webseiten, die Schaltung von Anzeigen auf
        Basis
        der Besuche von Nutzern auf dieser Webseite bzw. anderen Webseiten im Internet ermöglicht wird.</label>
      <label> Wir können auf Grundlage des Google-Marketing-Services „AdSense“ Werbeanzeigen Dritter einbinden. AdSense
        verwendet Cookies, mit denen Google und seinen Partner-Webseiten, die Schaltung von Anzeigen auf Basis der
        Besuche
        von Nutzern auf dieser Webseite bzw. anderen Webseiten im Internet ermöglicht wird.</label>
      <label> Ebenfalls können wir den Dienst „Google Optimize“ einsetzen. Google Optimize erlaubt uns im Rahmen so
        genannten "A/B-Testings" nachzuvollziehen, wie sich verschiedene Änderungen einer Webseite auswirken (z.B.
        Veränderungen der Eingabefelder, des Designs, etc.). Für diese Testzwecke werden Cookies auf den Geräten der
        Nutzer abgelegt. Dabei werden nur pseudonyme Daten der Nutzer verarbeitet.</label>
      <label> Ferner können wir den "Google Tag Manager" einsetzen, um die Google Analyse- und Marketing-Dienste in
        unsere
        Webseite einzubinden und zu verwalten.</label>
      <label> Weitere Informationen zur Datennutzung zu Marketingzwecken durch Google, erfahren Sie auf der
        Übersichtsseite: https://policies.google.com/technologies/ads, die Datenschutzerklärung von Google ist unter
        https://policies.google.com/privacy abrufbar.</label>
      <label> Wenn Sie der interessensbezogenen Werbung durch Google-Marketing-Services widersprechen möchten, können
        Sie
        die von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten nutzen:
        https://adssettings.google.com/authenticated.</label>


      <br>
      <br>
      <h4>Facebook Social Plug-Ins</h4>
      <label> Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plug-Ins
        ("Plug-Ins") des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square,
        Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"). Die Plug-Ins können Interaktionselemente oder
        Inhalte (z.B. Videos, Grafiken oder Textbeiträge) darstellen und sind an einem der Facebook Logos erkennbar
        (weißes „f“ auf blauer Kachel, den Begriffen "Like", "Gefällt mir" oder einem „Daumen hoch“-Zeichen) oder sind
        mit
        dem Zusatz "Facebook Social Plug-In" gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plug-Ins
        kann
        hier eingesehen werden: https://developers.facebook.com/docs/Plug-Ins/.</label>
      <label> Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
        europäische Datenschutzrecht einzuhalten
        (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active).</label>
      <label> Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plug-In enthält, baut sein
        Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plug-Ins wird von Facebook direkt
        an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können aus den
        verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang
        der
        Daten, die Facebook mit Hilfe dieses Plug-Ins erhebt und informiert die Nutzer daher entsprechend unserem
        Kenntnisstand.</label>
      <label> Durch die Einbindung der Plug-Ins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite
        des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
        Facebook-Konto zuordnen. Wenn Nutzer mit den Plug-Ins interagieren, zum Beispiel den Like Button betätigen oder
        einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt und
        dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass
        Facebook
        seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte
        IP-Adresse gespeichert.</label>
      <label> Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
        die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können diese
        den
        Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.</label>
      <label> Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über
        ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung
        unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und Widersprüche
        zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich:
        https://www.facebook.com/settings?tab=ads oder über die US-amerikanische Seite http://www.aboutads.info/choices/
        oder die EU-Seite http://www.youronlinechoices.com/. Die Einstellungen erfolgen plattformunabhängig, d.h. sie
        werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.</label>

      <br>
      <br>
      <h4>Bewertungsaufforderung</h4>
      <label> Wir senden Ihnen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
        und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) eine
        Bewertungsaufforderung. Wenn Sie über uns eine Reisebuchung tätigen, senden wir Ihnen nach Ihrer Rückkehr eine
        E-Mail indem Sie per Link die Möglichkeit haben, eine Bewertung zu Ihrer Reisebuchung abzugeben. Die Bewertung
        wird auf unserer Webseite veröffentlicht.</label>

      <br>
      <br>
      <h4>Sicherheitsmaßnahmen</h4>
      <label> Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der
        Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher
        Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
        gewährleisten. Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
        Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden
        Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
        Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf
        Gefährdung der Daten gewährleisen. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
        Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
        durch
        Technikgestaltung und durch datenschutzfreundliche Voreinstellungen berücksichtigt (Art. 25 DSGVO).</label>
      <label> Zu den Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte Übertragung von Daten zwischen Ihrem
        Browser und unserem Server.</label>
      <label> Dank des Verschlüsselungsverfahrens SSL (Secure Socket Layer) sind Ihre per Internet übertragenen
        persönlichen Daten (Name, Adresse und Telefonnummer) sowie ggf. Bank- oder Kreditkarteninformationen vor
        unberechtigtem Zugriff optimal geschützt.</label>

      <br>
      <br>
      <h4>Rechte der betroffenen Personen</h4>
      <label> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und
        auf
        Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15
        DSGVO.</label>
      <label> Sie haben entsprechend Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
        Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</label>
      <label> Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich
        gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu
        verlangen.</label>
      <label> Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
        Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.</label>
      <label> Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
        einzureichen.</label>

      <br>
      <br>
      <h4>Widerspruchsrecht</h4>
      <label> Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
        widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
        erfolgen.</label>

      <br>
      <br>
      <h4>{{ pDomain }}</h4>
      <h4>{{ kontakt.strnr }}</h4>
      <h4>{{ kontakt.plz }} {{ kontakt.ort }}</h4>
      <h4>Geschäftsführung durch: {{ pRechtliches.geschaftsfuhrer }}</h4>

      <br>
      <br>

      <label>Diese Datenschutzerklärung hat den Stand Mai 2018. Durch mögliche Weiterentwicklungen unserer Webseite und
        unserer Angebote oder aufgrund geänderter gesetzlicher Vorgaben kann es erforderlich sein, diese
        Datenschutzerklärung zu ändern.</label>

    </div>
  </div>
</template>

<script>

export default {
  name: "Privacy",
  props: [
    'kontakt',
    'pDomain',
    'pRechtliches',
  ],
  methods: {},
  mounted() {
    window.scrollTo(0, 2);
  }
}
</script>

<style scoped>

</style>
