<template>
  <div class="div-favcities">
    <!--    <h1 class="h1-title-favsregion">-->
    <!--      {{ $t("Home.BeliebteStaedte") }}-->
    <!--    </h1>-->
    <!--    <div style="text-align: center; margin: 0 5px 40px 8px">-->
    <!--      Check out the list of countries and places you can travel to by joining us.-->
    <!--    </div>-->
    <img :src="CityFoto" class="img-favcities">
    <div class="div-favcities1">
      <template v-for="(region,index) in item">
        <!--      <div>-->
        <CardSmallLocation
            :item="region"
        />
        <!--      </div>-->
      </template>

    </div>

  </div>
</template>

<script>
import CardSmallLocation from "../card/CardSmallLocation";
import CityFoto from "../../assets/Fotos/istanbul-1261194_1280_V2.png";

export default {
  name: "FavCities",
  props: [
    'item',
    'lang'
  ],

  components: {
    CardSmallLocation
  },


  data() {
    return {
      CityFoto: CityFoto,
      showtext: false,
      checkNR: ""
    }
  },


  methods: {
    setClassActive(index) {
      this.showtext = true;
      this.checkNR = index;

    },

    getTitle(index) {
      if (this.lang.toLowerCase() === 'de') {
        return this.item[index].name_de;
      }

      if (this.lang.toLowerCase() === 'tr') {
        return this.item[index].name_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        return this.item[index].name_en;
      }
    },

    zuPlace(index) {
      if (this.item[index].fav_type === 'hotel') {
        localStorage.removeItem('desP');
        localStorage.removeItem('des');
        localStorage.removeItem('desID');

        localStorage.removeItem('ht');
        localStorage.removeItem('htP');
        localStorage.removeItem('htC');


        this.$router.push({
          path: 'place',
          query: {id: this.item[index].id}
        });
        window.scrollTo(0, 0);


      }

      if (this.item[index].fav_type !== 'hotel') {

        this.$router.push({
          path: 'home',
          query: {name: this.item[index].name}
        });
        window.scrollTo(0, 0);


        localStorage.setItem('desP', this.item[index].name_en);
        localStorage.setItem('des', this.getTitle(index));
        localStorage.setItem('desID', this.item[index].id);

      }


    },

  }
}
</script>

<style scoped>
@import "../../css/FavCities.css";
</style>