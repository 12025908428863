

export const getAnrede = (lang) => {
    switch (lang) {
        case 'DE':
            return Anrede_de;
        case 'EN':
            return Anrede_en;
        case 'TR':
            return Anrede_tr;
        default:
            return Anrede_en;
    }
}

export const getoneAnrede = (lang, anredeid) => {
    return getAnrede(lang).find(item => item.anredeid === anredeid).text;
}

export const getAllAnrede = (lang) =>{
    return getAnrede(lang);
}


const Anrede_de = [
    {text: 'Herr', anredeid: 'Mr'},
    {text: 'Frau', anredeid: 'Mrs'},
]


const Anrede_tr = [
    {text: 'Bay', anredeid: 'Mr'},
    {text: 'Bayan', anredeid: 'Mrs'},
]


const Anrede_en = [
    {text: 'Mr.', anredeid: 'Mr'},
    {text: 'Mrs.', anredeid: 'Mrs'},
]


