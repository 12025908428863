<template>
  <div class="div-cardplace2" @click="zuPlace(hotelinfos.id)">

    <div class="div-inner-cardplace2">
      <div class="div-img-cardplace2">
        <img class="img-cardplace2"
             :src='hotelinfos.photo'
             alt="Foto-Hotel"
        />

      </div>


      <div class="div-inner-infos-cardplace2">
        <div class="div-inner-infos1-cardplace2">


          <div class="div-hotelname-cardplace2">
            <label class="label-hoteltitel-cardplace2">
              <!--              {{ hotelinfos.place.name }}-->
              {{ getTitle() }}
            </label>
          </div>

          <div v-if="hotelinfos.infos.place.stars >0 " class="div-rating-cardplace2">

            <v-rating
                v-model="hotelinfos.infos.place.stars"
                background-color="transparent"
                color="#FFDF00"

                readonly
            ></v-rating>
          </div>
          <div v-else style="height: 30px"></div>


          <div class="div-location-cardplace2">
            <v-icon small>mdi-map-marker</v-icon>
            <label>{{ hotelinfos.infos.place.location.city }}, </label>
            <label>{{ hotelinfos.infos.place.location.subregion }}, </label>
            <label>{{ hotelinfos.infos.place.location.country }}</label>
          </div>

          <div class="div-textoverview-cardplace2" v-html="hotelinfos.infos.place.overview"/>
          <!--          <div class="div-price-cardplace2">ab 399 €</div>-->

          <!--          <v-btn-->
          <!--              small-->
          <!--              color="var(&#45;&#45;Primary)"-->
          <!--              class="vbtn-cardplace2"-->
          <!--          >-->
          <v-btn
              small
              class="vbtn-cardplace2 vbtn-linear-gradient"
          >
            {{ $t("Button.Details") }}
          </v-btn>

        </div>


        <!--        <div>-->
        <!--          <v-btn-->
        <!--              small-->
        <!--              color="var(&#45;&#45;Primary)"-->
        <!--              class="vbtn-cardplace2"-->
        <!--              @click="zuPlace(hotelinfos.place.id)"-->
        <!--          >-->
        <!--            {{ $t("Button.Details") }}-->
        <!--          </v-btn>-->
        <!--        </div>-->

      </div>

    </div>

  </div>
</template>


<script>
import {formatPrice, getCurrencyIcon} from "../../util/currency";
import {onePlaceByID} from "../../util/halalapi";

export default {
  name: "CardPlace2",
  props: [
    'hotelinfos',
    'checkin',
    'checkout',
    'groups',
    'from',
    'lang'
  ],
  data() {
    return {
      // selectLang: 'DE',
      datetoday: new Date().toISOString(),
      currencyicon: getCurrencyIcon(),
      place: {},
      photo: ""
    }
  },
  methods: {
    getCurrencyIcon,
    formatPrice,
    zuPlace(id) {
      // if (this.from !== 'design') {
      this.$router.push({
        path: 'place',
        query: {id: id, checkin: this.checkin, checkout: this.checkout, groups: this.groups}
      });
      window.scrollTo(0, 0);
    },

    getTitle() {
      if (this.lang.toLowerCase() === 'de') {
        return this.hotelinfos.name_de;
      }

      if (this.lang.toLowerCase() === 'tr') {
        return this.hotelinfos.name_tr;
      }

      if (this.lang.toLowerCase() === 'en') {
        return this.hotelinfos.name_en;
      }
    },

  },
  async created() {

  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardPlace2.css";
</style>
