<template>
  <div class="div-skeleteonCardHorizontal">
    <div v-for="i in 12" >
      <div>
        <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
        >
          <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card-avatar, actions"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkleteonCardHorizontal",
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },

    }
  },
  inject: {
    theme: {
      default: {isDark: false},
    },
  },
  methods: {}
}
</script>

<style scoped>
@import "../../css/Skleteon.css";
</style>