const replaceText = (text, expected, replaceWith) => {
    text = text.replace(expected, replaceWith);
    return text;
}

export const arr_DE = [
    "ALLGEMEINE EINRICHTUNGEN:",
    "AKTIVITÄTEN:",
    "SPEISEN &amp; GETRÄNKE:",
    "FÜR KINDER:",
    "UNTERHALTUNG:",
    "INTERNET:",
    "PARKPLATZ:",
    "GESCHÄFTE:",
    "REZEPTIONSSERVICE:",
    "BUSINESS-EINRICHTUNGEN:",
    "REINIGUNGSSERVICE:",
    "TRANSPORT:",
    "STRÄNDE:",
    "WELLNESS & SPA:",
    "POOLS:",
    "WICHTIGE HINWEISE:",
];
export const arr_edited_DE = [
    "ALLGEMEINE_EINRICHTUNGEN:",
    "AKTIVITÄTEN:",
    "SPEISEN_&amp;_GETRÄNKE:",
    "FÜR_KINDER:",
    "UNTERHALTUNG:",
    "INTERNET:",
    "PARKPLATZ:",
    "GESCHÄFTE:",
    "REZEPTIONSSERVICE:",
    "BUSINESS-EINRICHTUNGEN:",
    "REINIGUNGSSERVICE:",
    "TRANSPORT:",
    "STRÄNDE:",
    "WELLNESS_&_SPA:",
    "POOLS:",
    "WICHTIGE_HINWEISE:",
];

export const parseFacilities_DE = async (text) => {
    text = replaceAll_DE(text);
    text = text.replaceAll("\n\n", "\n\n ");
    text = text.replaceAll(":\n", ": ");
    text = text.replaceAll(": \n", ": ");
    let spText = await parseText(text);
    spText = await replaceAll_DE_Back(spText);
    return spText;
}

export const replaceAll_DE = (text) => {
    text = replaceText(text, "ALLGEMEINE EINRICHTUNGEN:", "ALLGEMEINE_EINRICHTUNGEN:");
    text = replaceText(text, "SPEISEN &amp; GETRÄNKE:", "SPEISEN_&amp;_GETRÄNKE:");
    text = replaceText(text, "FÜR KINDER:", "FÜR_KINDER:");
    text = replaceText(text, "WELLNESS & SPA:", "WELLNESS_&_SPA:");
    text = replaceText(text, "WICHTIGE HINWEISE", "WICHTIGE_HINWEISE:");
    return text;
}

export const replaceAll_DE_Back = (text) => {
    let arr = [];
    text.map(item => {
        let inOf = arr_edited_DE.indexOf(item.title);
        if (inOf !== -1) {
            item.title = arr_DE[inOf];
        } else {
            item.title = item.title.replaceAll("_", " ");
        }
        arr.push({title: item.title, text: item.text});
    })

    return arr;

}

export const splitText = (text) => {
    let splittedText = text.split(" ");
    return splittedText;
}


export const parseText = async (text) => {
    let splittedText = splitText(text);
    let saveIndex = 0;
    let endIndex = 0;
    let n = await iterateArray(splittedText, 0);
    let next = await iterateArray(splittedText, 1);
    let last = false;
    let arrTex = [];

    splittedText.map((text, index) => {
        if (n !== 15) {
            if (text.includes(arr_edited_DE[n])) {
                saveIndex = index;
            }
            if (text.includes(arr_edited_DE[next])) {
                endIndex = index;

                let text = getString(splittedText, saveIndex, endIndex);
                saveIndex = index;
                arrTex.push(text);
                n = iterateArray(splittedText, n + 1);
                next = iterateArray(splittedText, n + 1);
            }
        } else {
            if (!last) {
                let text = getString(splittedText, index - 1, splittedText.length - 1);
                arrTex.push(text);
                last = true;
            }
        }
    });

    return arrTex;
}


const iterateArray = (array, index) => {
    let save = 0;
    let test = false;
    for (let i = index; i < arr_edited_DE.length; i++) {
        if (contains(array, arr_edited_DE[i])) {
            if (!test) {
                save = i;
                test = true;
                return save;
            }
        }
    }
    return save;
}


function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].includes(obj)) {
            return true;
        }
    }
    return false;
}


const getString = (splittedText, von, bis) => {
    let text = {title: "", text: ""};
    text.title = splittedText[von];
    for (let i = von + 1; i < bis; i++) {
        text.text += splittedText[i] + " ";
    }
    return text;
}

