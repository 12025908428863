<template>
  <footer>
    <div class="div-footer-nr2">
      <div class="div-inner-footer-nr2">
        <div class="div-content-footer-nr2">
          <h4>
            Adresse und Kontakt
          </h4>
          <label class="label-footer-nr2">
            {{ kontakt.firma }}, {{ kontakt.strnr }}, {{ kontakt.plz }}, {{ kontakt.ort }}
          </label>
          <div>
            <v-icon color="white" style="margin-right: 10px">mdi-email-outline</v-icon>
            <a class="a-mail-footer-nr2" :href="'mailto:' +kontakt.email">{{ kontakt.email }}</a>
          </div>

          <div v-if=" kontakt.tel !== ''">
            <v-icon color="white" style="margin-right: 10px">mdi-phone-in-talk-outline</v-icon>
            <label class="label2-footer-nr2">{{ kontakt.tel }}</label>
          </div>

          <div v-if="kontakt.fax !== ''">
            <v-icon color="white" style="margin-right: 10px">mdi-fax</v-icon>
            <label class="label2-footer-nr2">{{ kontakt.fax }}</label>
          </div>

          <div v-if="kontakt.mobile !== ''">
            <v-icon color="white" style="margin-right: 10px">mdi-cellphone</v-icon>
            <label class="label2-footer-nr2">{{ kontakt.mobile }}</label>
          </div>

        </div>
        <!--        <div class="div-content-footer-nr2 center-footer-nr2">-->
        <div class="div-content-footer-nr2">
          <h4>
            Über uns
          </h4>

          <label class="label3-footer-nr2" @click="zuImpressum">
            Impressum
          </label>
          <label class="label3-footer-nr2" @click="zuAGB">
            AGB
          </label>
          <label class="label3-footer-nr2" @click="zuDatenschutz">
            Datenschutz
          </label>
          <label class="label3-footer-nr2" @click="zuRegistrationContact">
            Kooperation
          </label>
          <label class="label3-footer-nr2" @click="zuLogin">
            Login
          </label>
        </div>

        <!--        <div class="div-content-footer-nr2 right-footer-nr2">-->
        <div class="div-content-footer-nr2">
          <h4>
            Kooperationen
          </h4>

          <a class="label3-footer-nr2" href="http://www.efetour.de" target="_blank">
            <img :src="efetour" class="img-coop-footer-nr2"/>
          </a>
          <a class="label3-footer-nr2" href="https://www.efehotels.com" target="_blank">
            <img :src="islamioteller" class="img-coop-footer-nr2"/>
          </a>
          <a class="label3-footer-nr2" href="http://www.efeshop.de" target="_blank">
            <img :src="efeshop" class="img-coop-footer-nr2"/>
          </a>
          <a class="label3-footer-nr2" href="https://www.mytour-travel.com" target="_blank">
            <img :src="mytour" class="img-coop-footer-nr2"/>
          </a>
        </div>


      </div>
      <v-divider></v-divider>
      <div class="div-social-footer-nr2">
        <label class="label-rights-footer-nr2">
          2022 {{ pDomain }} All Rights Reserved.
        </label>


        <div class="div-follow-footer-nr2"
             v-if="kontakt.facebook !== '' ||kontakt.instagram !== '' ||kontakt.youtube !== '' ||kontakt.twitter !== ''">
          {{ $t("Footer.FolgenSieUns") }}
          <a class="a-href-footer-nr2"
             :href="'https://www.facebook.com/'+kontakt.facebook"
             target="_blank"
             rel="noopener"
             v-if="kontakt.facebook !== ''"
          >
            <v-icon class="icon-footer-nr2" color="white">mdi-facebook</v-icon>
          </a>

          <a class="a-href-footer-nr2"
             :href="'https://www.instagram.com/'+ kontakt.instagram"
             target="_blank"
             rel="noopener"
             v-if="kontakt.instagram !== ''"
          >
            <v-icon class="icon-footer-nr2" color="white">mdi-instagram</v-icon>
          </a>

          <a class="a-href-footer-nr2"
             :href="'https://www.youtube.com/'+ kontakt.youtube"
             target="_blank"
             rel="noopener"
             v-if="kontakt.youtube !== ''"
          >
            <v-icon class="icon-footer-nr2" color="white">mdi-youtube</v-icon>
          </a>
          <a class="a-href-footer-nr2"
             :href="'https://www.twitter.com/'+ kontakt.twitter"
             target="_blank"
             rel="noopener"
             v-if="kontakt.twitter !== ''"
          >
            <v-icon class="icon-footer-nr2" color="white">mdi-twitter</v-icon>
          </a>
        </div>
      </div>


      <!--      <div class="div-footer2">-->

      <!--      </div>-->
    </div>

    <CeeYuuFooter></CeeYuuFooter>

  </footer>
</template>
<script>
import CeeYuuFooter from "../../components/headerfooter/CeeYuuFooter";
import efetour from "../../assets/logo_efetour.png";
import islamioteller from "../../assets/logo_islami-oteller.png";
import efeshop from "../../assets/efeshop1.png";
import mytour from "../../assets/tourtravel1.png";

export default {
  name: "Footer2",
  props: [
    'kontakt',
    'pDomain'
  ],
  data() {
    return {
      efetour: efetour,
      islamioteller: islamioteller,
      efeshop: efeshop,
      mytour: mytour
    }
  },

  components: {
    CeeYuuFooter
  },

  methods: {
    // zuStart() {
    //
    //   this.$router.push("/");
    //   window.scrollTo(0, 0);
    // },

    zuKontakt() {
      window.scrollTo(0, 1);
      this.$router.push("/contact");
    },

    zuImpressum() {
      window.scrollTo(0, 1);
      this.$router.push("/imprint");
    },

    zuAGB() {
      window.scrollTo(0, 1);
      this.$router.push("/agb");
    },

    zuDatenschutz() {
      window.scrollTo(0, 1);
      this.$router.push("/privacy");
    },
    zuRegistrationContact() {
      window.scrollTo(0, 1);
      this.$router.push("/registrationcontact");
    },

    zuLogin() {
      window.scrollTo(0, 1);
      window.open('https://admin.'+this.pDomain);
    },

    zuReiseveranstalter() {
      window.scrollTo(0, 1);
      this.$router.push("/operator");
    },

    zuReiseburo() {
      window.scrollTo(0, 1);
      this.$router.push("/agency");
    }
  },


}

</script>

<style lang="css" scoped>
@import "../../css/Footer2.css";
</style>
