

export const add = (url) => {
    return url.concat("?");
}

export const bind = (url) => {
    return url.concat("&");
}
export const binddata = (value) => {
    return value.concat("&");
}

export const addHolidayType = (url,htype) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("holiday_type=", htype);
}

export const addCurrency = (url,currency) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("currency=", currency);
}
export const addCheckin = (url,checkin) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("checkin=", checkin);
}
export const addCheckout = (url,checkout) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("checkout=", checkout);
}
export const addGroups = (url,groups) => {
    if(!url.includes("?"))
        url = add(url);

    return url.concat("&groups[]=", groups);
}
export const addLocation = (url,location) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("location=", location);
}
export const addPage = (url,page) => {
    if(!url.includes("?"))
        url = add(url);
    return url.concat("page=", page);
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export const addGroupsData = (value,groups) => {
    return value.concat("groups[]=", groups);
}