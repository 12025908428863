export  const generateID = () => {
  return Math.floor(100000 + Math.random() * 900000);
}

export const generateReservierungsnr=(firma_id) =>{
  let date = new Date();

  return firma_id +"-"+date.getTime()+"-"+generateID();
}




export  const generateTripID = () => {
  return Math.floor(1000000 + Math.random() * 9000000);
}