<template>
  <div>

    <div class="div-background-header"/>

    <div style="padding: 30px; margin-top: 60px; max-width: 1500px">
      <h2>Impressum</h2>
      <br>
      <br>

      <h4>{{ kontakt.firma }}</h4>
      <h4>{{ kontakt.strnr }}</h4>
      <h4>{{ kontakt.plz }} {{ kontakt.ort }}</h4>
      <br>

      <h4>Tel.: {{ kontakt.tel }}</h4>
      <h4>Fax: {{ kontakt.fax }}</h4>
      <br>

      <h4>E-Mail: {{ kontakt.email }}</h4>
      <br><br>

      <h4>Geschäftsführung durch: {{ pRechtliches.geschaftsfuhrer }}</h4>
      <br>


      <h4>Steuernummer: {{ pRechtliches.umsatzsteuerid }}</h4>
      <br>

      <h4>Bank: {{ pRechtliches.bank }}</h4>
      <h4>IBAN: {{ pRechtliches.iban }}</h4>
      <h4>BIC: {{ pRechtliches.bic }}</h4>
      <br><br>
    </div>
  </div>
</template>

<script>

export default {
  name: "Imprint",
  props: [
    'kontakt',
    'pDomain',
    'pRechtliches',
  ],
  methods: {},
  mounted() {
    window.scrollTo(0, 2);
  }
}
</script>

<style scoped>

</style>
