<template>
  <div>

    <div class="div-background-header"/>
    <div style="padding: 30px; margin: 60px auto; max-width: 1500px">
      <h2>BUCHUNGSBEDINGUNGEN</h2>
      <h4>Unsere allgemeinen Geschäftsbedingungen für Ihre Urlaubsbuchung</h4>
      <br>

      <label>
        Bitte lesen Sie unsere Geschäftsbedingungen bevor Sie mit uns entweder durch das {{ pDomain }} Buchungssystem,
        einen API Link, iFrame oder traditionellen, manuellen Buchungsmethoden buchen. Bitte berücksichtigen Sie diese
        Bedingungen mit Sorgfalt, da Sie an sie gebunden sein werden. Diese Bedingungen geben unsere jeweiligen Rechte
        und
        Pflichten an, und erklären wie Sie am besten Zugang zu unseren Produkten finden und wie die verschiedenen
        Buchungsprozesse funktionieren.
      </label>

      <br>
      <br>
      <label>
        {{ kontakt.firma }} willigt ein, Ihnen Zugang zu {{ pDomain }} Produkten via das {{ pDomain }} Buchungssystem,
        API Link
        oder
        Email zu gewähren. {{ kontakt.firma }} agiert als ein Vermittler für Ihre Lieferanten (z.B. Unterkunftsanbieter,
        Fluglinien,
        Autovermietungsunternehmen, Transferunternehmen, Abfertigungsgesellschaften, Stadtführungsanbieter) mit denen
        Sie
        buchen. Somit gelten die jeweiligen Geschäftsbedingungen der Lieferanten. Kopien sind auf Anfrage erhältlich.
        Die
        allgemeinen Geschäftsbedingungen der EPS können Sie hier abrufen. Bitte stellen Sie sicher, dass Sie sich auf
        die
        jeweiligen, für Sie relevanten und Ihren Zugang zu {{ kontakt.firma }} betreffenden Absätze in diesen
        Geschäftsbedingungen
        beziehen.
        <br>
        {{ kontakt.firma }} behält sich das Recht vor, diese Geschäftsbedingungen ohne Vorbescheid zu verändern oder zu
        erneuern.
        Dies hat keine Auswirkungen auf gegenwärtige Buchungen, solange es nicht angegeben ist (z.B. Änderungen zu
        Steuern
        o.ä.). Die neueste Version wird stets auf dem {{ pDomain }} Buchungssystem verfügbar sein. Durch die Nutzung
        unserer Seite oder dem Zugang unserer Produkte akzeptieren Sie die neueste Version. Jene die keinen Zugang zum
        {{ pDomain }} Buchungssystem haben werden dementsprechend beraten.
        <br>
        Bitte beachten Sie, dass in ALLEN Fällen der Hauptgast/-Passagier der Buchung, zum Zeitpunkt der Buchung das 18.
        Lebensjahr vollendet haben muss. Der Hauptgast wird mit Lieferanten in Kontakt stehen und für alle, dem Agenten
        angegebenen Informationen über alle beteiligten Gäste/Passagiere verantwortlich sein: dies beinhaltet die
        korrekte
        Rechtschreibung der Namen, Titel, Geburtsdaten, Reisepässe und Visa Informationen und der Sicherstellung, dass
        alle beteiligten Mitglieder mit einer gültigen Reiseversicherung ausgestattet sind.


      </label>


      <br>
      <br>
      <h4>Haftungsausschluss</h4>

      <label>
        Die Information, Software, Produkte und Services die auf dieser Webseite aufgeführt sind, können Ungenauigkeiten
        oder typographische Fehler beinhalten. {{ kontakt.firma }} garantiert insbesondere keine Fehlerfreiheit und
        haftet nicht
        für
        Ungenauigkeiten im Zusammenhang mit Informationen und Beschreibungen von Hotels, Resorts, Fluglinien, Villen,
        Auto- und Flughafentransfer und andere Reiseprodukten auf dieser Webseite (inklusive, und ohne Begrenzung auf
        Fotos, Listen von Hotelausstattungen, allgemeine Produktbeschreibungen, usw.), welche meist vom jeweiligen
        Lieferanten zur Verfügung gestellt werden. Alle auf dieser Webseite aufgeführten Hotel, Resort, Villa
        Bewertungen
        dienen nur als generelle Richtlinien und {{ kontakt.firma }} haftet nicht für die Richtigkeit dieser
        Bewertungen.
        Änderungen
        werden diesen Informationen regelmäßig hinzugefügt. {{ kontakt.firma }} und/oder ihre jeweiligen Lieferanten
        können
        jederzeit Verbesserungen bzw. Veränderungen auf dieser Webseite vornehmen.
        <br>
        {{ kontakt.firma }} und seine jeweiligen Lieferanten äußern sich in keiner Weise über die Angemessenheit der
        Information,
        Software, Produkte, und Dienstleistung, die sich aus jeglichem Grund auf dieser Website befinden und die
        Einbindung oder die zum Verkauf angebotenen Produkte und Dienstleistungen auf dieser Website stellen keinerlei
        Billigung oder Empfehlung dieser Produkte und Dienstleistungen seitens {{ kontakt.firma }} dar. {{
          kontakt.firma
        }} wird
        angemessene
        Sorgfalt und Qualifikation in der Durchführung der Dienstleistungen auf dieser Website anwenden.

        <br>
        Im Rahmen der gesetzlichen Vorschriften haften weder {{ kontakt.firma }} noch die jeweiligen Lieferanten, für
        Gesetzleistungen, Geschäftsbedingungen im Zusammenhang mit dieser Information, Software, Produkte, und
        Dienstleistungen, inklusive allen gesetzlichen Gewährleistungen und Bedingungen, mit angemessener Qualität,
        Eignung für einen bestimmten Zweck, Titel, und keiner Rechtsverletzung.

        <br>
        Die Transportunternehmen, Resorts, Hotel, Villen und andere Dienstleistende, welche für {{ kontakt.firma }}
        Reisen oder
        andere Dienstleistungen liefern, sind unabhängige Auftragnehmer und keine Agenten oder Mitarbeiter von
        {{ kontakt.firma }}.
        {{ kontakt.firma }} haftet nicht für deren Fehlverhalten, Fehler, Vernachlässigung, Repräsentationen,
        Gewährleistungen,
        die
        Rechtsverletzung oder Nachlässigkeit jeglicher Anbieter oder für jegliche Personenschaden, Todesfall,
        Sachschaden,
        oder andere Schaden oder daraus resultierenden Kosten.
        <br>
        {{ kontakt.firma }} haftet nicht und übernimmt keine Kostenerstattung im Fall von Verzögerungen, Stornierungen,
        Überbuchungen, Streik, höhere Gewalt oder andere, nicht beeinflussbare Ereignisse und übernimmt keine
        Verantwortung für jeglichen Mehraufwand, Versäumnisse, Verspätungen, Umleitung oder jeglichen Regierungsakt. Auf
        keinen Fall haftet {{ kontakt.firma }} bzw. Zulieferer für jegliche indirekten, zufälligen, sträflichen,
        besonderen
        Schäden
        oder Folgeschäden die aus oder in Zusammenhang mit der Nutzung dieser Website oder mit der verspäteten oder
        unfähigen Nutzung dieser Website, oder für jegliche Information, Software, Produkte, und Dienstleistungen
        bezogen
        von dieser Website, oder aus der Nutzung dieser Website entstandene, (inklusive, jedoch nicht beschränkt mit
        Nutzungsverlust, Datenverlust, Gewinnausfall, Geldverlust, Gelegenheitsverlust), ob beruhend auf Vertrag,
        Unrecht,
        Erfolgshaftung oder sonstiges, bis zu maximal dem Gesamtwert der Transaktion worunter der Anspruch auftritt, je
        Schadensereignis oder einer Reihe von miteinander verbundenen Ereignissen. Dies beeinflusst nicht Ihre
        gesetzlichen Verbraucherrechte.
        <br>
        Wichtig: Diese Geschäftsbedingungen und dieser Haftungsausschluss wirken sich nicht auf verbindliche legale
        Rechte
        aus, welche unter geltendem Recht nicht ausgeschlossen werden können (inklusive Ihrem gesetzlichen Recht unter
        jeglicher anwendbarer nationaler oder lokaler Gesetzgebung welches das EC "package travel directive"
        (90/314/eec)
        implementiert). Sollten Sie sich entscheiden, irgendein Reisepaket von dieser Website zu kaufen, werden separate
        Geschäftsbedingungen gelten.
        <br>
      </label>


      <br>
      <h4>Zahlungen</h4>
      <label>
        Der Preis von Dienstleistungen, die auf der Website ( {{ pDomain }}) oder telefonisch gebucht werden, muss
        entweder an (i) {{ kontakt.firma }} gezahlt werden, der diese Zahlungen im Namen der Anbieter akzeptiert,
        und/oder
        direkt
        an
        den Anbietern. Die Zahlung kann von mehr als einer Partei berechnet werden (wie von der Bank des Kunden
        angegeben
        oder auf dem Kreditkartenauszug angezeigt), der Gesamtbetrag wird jedoch den Gesamtpreis der Dienstleistungen
        nicht übersteigen.

        <br>
        Der Kunde legt seine Kreditkartendaten vor und der Lieferant oder {{ kontakt.firma }} wird häufig, i) die
        Gültigkeit der
        Zahlungskarte (durch einen Nennwert, der entweder innerhalb weniger Tage zurückerstattet wird oder durch den
        Anbieter von der Restzahlung abgezogen wird), (ii) die Verfügbarkeit von Mitteln auf der Zahlungskarte (die von
        der entsprechenden Bank zu bestätigen ist) prüfen müssen.

        <br>

      </label>

      <br>
      <br>
      <h4>Entschädigungsleistung</h4>
      <label>
        Sie stimmen zu, {{ kontakt.firma }}, seine Partner und/oder seine jeweiligen Lieferanten Geschäftsführer,
        Angestellte,
        Vertreter gegen jegliche Ansprüche, Klagegegenstände, Forderungen, Einziehungen, Verluste, Schäden, Bußgelder,
        Strafen oder andere Kosten und Ausgaben jeglicher Art oder Natur, inklusive, jedoch nicht beschränkt mit
        angemessenen legalen und Gebührenabrechnungen zu verteidigen und zu entschädigen, welche durch Drittpersonen aus
        folgenden Gründen hervorgebracht wurden:
        <br>
        - a. Ihre Verletzung dieser Allgemeinen Geschäftsbedingungen und Hinweise auf der Website oder der hierin
        genannten Dokumente
        <br>
        - b. Ihre Gesetzesverletzung oder Verletzung der Rechte von Drittpersonen; oder
        <br>
        - c. Ihre Nutzung dieser Webseite.
        <br>
        Nicht illegale oder verbotene Nutzung
        <br>
        Als Bedingung Ihrer Nutzung dieser Webseite verbürgen Sie {{ kontakt.firma }}, dass Sie diese Webseite nicht für
        unter
        diesen Geschäftsbedingungen illegal oder verboten geltende Zwecke und Mitteilungen Nutzen werden.

      </label>

      <br>
      <br>
      <h4>Links zu dritten Seiten</h4>
      <label>
        Diese Webseite kann Hyperlinks zu Webseiten enthalten die nicht durch {{ kontakt.firma }} sondern anderen
        Parteien
        verwaltet
        werden. Solche Hyperlinks dienen lediglich als Referenz für Sie. {{ kontakt.firma }} kontrolliert diese
        Webseiten nicht
        und
        ist demnach nicht verantwortlich für deren Inhalt oder Ihrer Nutzung dieser Webseiten. Dass {{ kontakt.firma }}
        diese
        Hyperlinks auf seiner Website aufnimmt, bedeutet nicht, dass es das Material auf diesen Webseiten befürwortet
        oder
        in Assoziation mit dessen Operatoren ist.
      </label>

      <br>
      <br>
      <h4>Versicherung</h4>
      <label>
        {{ kontakt.firma }} bietet keine Versicherung für Passagiere, Material oder Gesundheitsverlust während des
        Trips.
        Passagieren wird empfohlen, ihre eigene Krankenversicherung abzuschließen während sie reisen. Jeder Passagier
        sollte mit angemessenem Versicherungsschutz reisen. Diese Versicherung sollte sicherstellen, dass Sie gegen
        unvorhersehbare Stornierungsgebühren, entstandenen medizinische Kosten während Ihrer Abwesenheit von zu Hause
        (genehmigungspflichtig), persönlichem Haftpflichtanspruch, Geldverlust, usw. abgedeckt sind. Wir empfehlen, dass
        Sie Ihre Versicherung zum Zeitpunkt der Buchung Ihres Urlaubes abgeschlossen haben, um gegen mögliche
        Stornierungsgebühren abgedeckt zu sein.
      </label>

      <br>
      <br>
      <h4>Reisepässe und Visa</h4>
      <label>
        Alle Passagiere müssen Sie über einen gültigen Reisepass verfügen. Jene, die einen Familienpass haben, müssen
        gemeinsam mit dem Reisepass-Inhaber reisen. Es steht in Ihrer Verantwortung Visa Voraussetzungen im Konsulat in
        Ihrem Land zu überprüfen, je nach dem in welchem Land Sie Ihren Urlaub planen. Sie können dies auch auf der
        offiziellen Internetseite des jeweiligen Konsulats Online überprüfen.
        <br>
        Falls Sie eine späte Buchung tätigen, stellen Sie bitte sicher, dass Sie genug Zeit haben, die notwendigen Visa
        einzuholen. Es ist Ihre Verantwortung dafür zu sorgen, dass Sie rechtzeitig alle gültigen Reisepässe und Visa
        besitzen. Weder wir, noch unsere Lieferanten übernehmen keinerlei Verantwortung, wenn Sie, aufgrund mangelnder
        Einhaltung der Reisepass-, Visa- oder Immigrationsvoraussetzungen, Ihre Reise nicht antreten können.

      </label>

      <br>
      <br>
      <h4>Preise</h4>
      <label>
        Alle Preise unterliegen der Verfügbarkeit und der letztendlichen Bestätigung durch den Lieferanten. Weitere
        Informationen über die Preispolitik der einzelnen Lieferanten kann in ihren Buchungskonditionen gefunden werden.
        Wir behalten uns das Recht vor, die Preise für Feiertage auf unserer Website zu ändern. Sie werden über den
        aktuellen Preis des Urlaubs informiert, den Sie buchen möchten, bevor Ihr Vertrag bestätigt wird.
        <br>
        Wir behalten uns das Recht vor, Buchungen abzulehnen.
        <br>
        Wir sind nicht verpflichtet, Tickets oder Dokumente im Zusammenhang mit der Buchung zu liefern, bis die
        vollständige Zahlung bei uns eingegangen ist.
        <br>
        Falls wir die Bezahlung nicht rechtzeitig erhalten, können wir die Buchung als vom Kunden storniert betrachten.
        In
        einem solchen Fall wird die Einlage oder der vorgezahlte Betrag bezüglich unserer Stornierungsgebühren,
        verfallen.
        Manche Hotels können zu bestimmten Zeiten verschiedene Zahlungspläne erheben und es besteht die Möglichkeit,
        dass
        diese uns bis nach der Bestätigung Ihrer Buchung nicht informieren. Sollte dies in Ihrem Fall geschehen, werden
        wir Sie Informieren, sobald das Hotel uns über die Sachlage informiert.
        <br>
        Preise für Resorts, Hotels, Villen, maßgeschneiderte Urlaube und Touren mit Führung können sich in Zusammenhang
        mit Wechselkursschwankungen ändern und die auf unserer Webseite aufgeführten Preise dienen lediglich zur Ihrer
        Orientierung. Die aktuellsten Preise finden Sie unter {{ pDomain }}.


      </label>


      <br>
      <br>
      <h4>Online-Buchungsbestätigung</h4>
      <label>
        Alle auf dieser Webseite aufgeführten Reiseprodukte unterliegen deren Verfügbarkeit. Es ist sehr
        unwahrscheinlich,
        dass wir nachdem Sie Ihre Online-Buchung mit uns getätigt haben, eine Notiz der Nichtverfügbarkeit der
        Unterkunft
        erhalten. Da jedoch diese Möglichkeit besteht, behalten wir das Recht vor, in einem solchen Fall Ihre
        Online-Buchung innerhalb von 24 Stunden zu stornieren und Ihrem Bankkonto den uns bezahlten vollen Betrag zurück
        zu erstatten. Daher empfehlen wir Ihnen dringendst, auf unsere Buchungsbestätigung zu warten, bevor Sie Ihren
        Flug
        buchen. Sie werden die Bestätigung innerhalb von 24 Stunden nach Ihrer Online-Buchung erhalten. Ihre Zahlung
        wird
        erst eingezogen, nachdem wir Ihre Buchung bestätigt haben.
        <br>
        Alle per Scheck geleisteten Zahlungen sind an „{{ kontakt.firma }}“ zu leisten.
      </label>


      <br>
      <br>
      <h4>Optionale Exkursion & Touren</h4>
      <label>
        Optionale Ausflüge, Touren, Mietwagen und ähnliche Dienstleistungen außerhalb der im Voraus gebuchten und
        bezahlten Reiseroute werden auf eigenes Risiko des Kunden durchgeführt. {{ kontakt.firma }} trägt keine
        rechtliche
        Verantwortung für Touren, Ausflüge und Mietwagen, die vor Ort bei lokalen {{ kontakt.firma }} Partnerunternehmen
        oder
        Vertretungsunternehmen gebucht werden.

      </label>

      <br>
      <br>
      <h4>Flugzeiten und Urlaubsänderung</h4>
      <label>
        Alle Flugzeiten sind provisorisch und freibleibend. Sie werden jedoch im Voraus über wesentliche Veränderungen
        gemäß der Buchungsbedingungen Ihres Lieferanten verständigt. Von Zeit zu Zeit kann es vorkommen, dass Ihr
        Lieferant es für nötig hält, Veränderungen an Ihrem Urlaub durchzunehmen, wie beispielsweise
        Unterkunftsortwechsel, Service oder Datum. In einem solchen Fall wird Ihr Lieferant klare Richtlinien in ihren
        Buchungsbedingungen darlegen.
      </label>


      <br>
      <br>
      <h4>Stornierung</h4>
      <label>
        Sollten Sie es für nötig halten, Ihren Urlaub zu stornieren oder zu verändern, bitte kontaktieren Sie umgehend
        {{ kontakt.firma }}. Wir werden eine schriftliche Bestätigung der Stornierung/Änderung vom Hauptgast auf der
        Buchung
        anfordern. Es fallen Stornierungs-/Änderungskosten an. Diese Gebühren erhöhen sich, je näher die Abreise /
        Änderung erfolgt, und können bis zu 100% betragen. Die Höhe der anzuwendenden Stornierungs- / Änderungsgebühren
        wird in den Buchungsbedingungen Ihres Lieferanten angegeben. Die Gebühren werden Ihnen nach Erhalt Ihrer
        schriftlichen Anweisung zur Stornierung oder Änderung Ihres Urlaubs bestätigt. Für Stornierungen gelten die
        Stornierungsregeln unserer Lieferanten sowie die Verwaltungsgebühren von {{ kontakt.firma }}. Für
        Rückerstattungen auf
        Bank-
        / Kreditkarten können bis zu 2,9% Zuschläge zur Deckung der Gebühren von Kreditkartenunternehmen erhoben werden.
        <br>
        Die Stornierungsgebühren und Informationen der Lieferanten finden Sie auf den einzelnen Hotel- und Villenseiten.
        Bitte beachten Sie, dass diese sich von Unterkunft zu Unterkunft unterscheiden.
        <br>

        Rückerstattungen werden Ihnen immer in der Währung ausgestellt, in der Sie für die Buchung gezahlt haben. Wie
        auf
        der Check-out-Seite und in der Bestätigungs-E-Mail angegeben, „sind die Preise in Ihrer lokalen Währung reine
        Richtwerte und basieren auf dem aktuellen Wechselkurs. Die tatsächlichen Preise werden in der Währung der
        Unterkunft angezeigt, welche auch Ihre Buchungsvertragswährung ist.“ Die Höhe Ihrer Rückerstattung hängt vom
        Wechselkurs ab. Wenn wir Ihnen den Betrag zurückerstatten, spiegelt der Betrag, den Sie zurückerhalten, den
        Wechselkurs an dem Tag wider, an dem wir die Rückerstattung ausstellen, und nicht den Wechselkurs zum Zeitpunkt
        Ihrer ursprünglichen Buchung. Die Wechselkurse schwanken ständig und daher stimmt der Betrag, den Sie als
        Rückerstattung erhalten, nicht mit dem Betrag überein, den Sie gezahlt haben.
        <br>

        Wir behalten uns das Recht vor, Ihre Buchung zu stornieren, wenn die volle Zahlung nicht in einem angemessenen
        Zeitrahmen eingeht.

      </label>


      <br>
      <br>
      <h4>Auf dieser Webseite vorhandene Software</h4>
      <label>
        Jegliche Software das auf dieser Webseite zum Download bereitsteht („Software“) ist urheberrechtlich geschütztes
        Werk von {{ kontakt.firma }}, seinen Lieferanten. Ihre Nutzung der Software ist geregelt durch den Endnutzer
        Lizenzvertrag,
        falls vorhanden, welches in dem Software Lizenzvertrag mit enthalten ist. Sie dürfen keine Software, die ein
        Lizenzvertrag hat, installieren, kopieren oder benutzen, es sei denn Sie stimmen den Bedingungen im
        Lizenzvertrag
        zu.
        Für jegliche Software, die keinen Lizenzvertrag beinhaltet, gewährt {{ kontakt.firma }} Ihnen, dem Nutzer,
        hiermit eine
        persönliche, nicht übertragbare Lizenz zur Nutzung der Software und ansonsten zur Nutzung dieser Webseite gemäß
        der Bedingungen und Konditionen und für keinen anderen Zweck. Durch die Installierung, Kopierung und sonstige
        Nutzung dieser Software willigen Sie den Bedingungen und Konditionen der Lizenz ein.
        Bitte beachten Sie, dass jegliche Software, inklusive und ohne Beschränkung alle HTML codes, Active X controls,
        und andere Scripts auf dieser Webseite, Eigentum von {{ kontakt.firma }}, und dessen Lieferanten ist, und
        urheberrechtlich
        sowie durch internationale Vertragsprovisionen geschützt ist. Sie können Kopien dieser Software lediglich zu
        Backup- oder Archivierungszwecken machen. Jegliche Reproduktion oder Verteilung der Software ist ausdrücklich,
        rechtlich verboten und kann zu ernsthaften Bußgeldstrafen oder Kriminalstrafen führen. Übertreter des Gesetzes
        werden mit der höchstmöglichen Strafe angeklagt. Sie dürfen die Software nicht zerlegen, dekompilieren, oder
        disassemblieren, es sei denn dies ist ausdrücklich, gesetzlich erlaubt. Sie bestätigen, dass diese Software und
        jegliche dazugehörige Dokumentation und/oder technische Information dem zutreffenden Gesetz und Regulation der
        Ausfuhrkontrolle von England und Wales unterliegt.
      </label>

      <br>
      <br>
      <h4>Reiseziele</h4>
      <label>

        Obwohl die meisten Reisen, inklusive Reisen mit internationalen Zielen, meist ohne Zwischenfälle abgeschlossen
        werden, können Reisen zu bestimmten Zielen ein größeres Risiko darstellen als andere. {{ kontakt.firma }}.
        fordert die
        Passagiere dringend auf, alle Reiseverbote, Warnungen, Ankündigungen und Hinweise der Reisehinweise des Foreign
        and Commonwealth Office zu überprüfen, bevor sie Reisen zu internationalen Zielen buchen. Informationen zu den
        Bedingungen in verschiedenen Ländern und zum Risiko von Reisen zu bestimmten internationalen Zielen finden Sie
        auf
        der Webseite des Auswärtiges Amtes derzeit unter dem Link http://www.auswaertiges-amt.de gefunden werden. Online
        medizinische Beratung für Reisende befindet sich auf der Webseite des Gesundheitsministerium unter dem Link
        http://www.bmg.bund.de Andernfalls wenden Sie sich an Ihren Hausarzt oder das Gesundheitsministerium, um
        medizinische Beratung zu Ihrer Reise zu erhalten.
        Durch das Angebot von Reisen zu bestimmten internationalen Zielen zum Verkauf stellt {{ kontakt.firma }}. nicht
        dar oder
        garantiert, dass Reisen zu solchen Punkten ratsam oder ohne Risiko sind, und haftet nicht für Schäden oder
        Verluste, die durch Reisen zu solchen Zielen entstehen können.
        <br>

        Durch den Verkauf verschiedener Reisen in internationale Länder garantiert {{ kontakt.firma }} nicht, dass die
        Reise in
        solche Länder zu empfehlen oder ohne Risiko ist. Daher haftet {{ kontakt.firma }} für keinerlei Schäden oder
        Verluste,
        die
        durch eine solche Reise entstehen können.

      </label>

      <br>
      <br>
      <h4>Medizinische Beratung und Impfung</h4>
      <label>

        Bitte lassen Sie sich in Bezug auf Ihre Reise von Ihrem Arzt beraten.
      </label>

      <br>
      <br>
      <h4>Hotel / Resort / Villa Einrichtungen</h4>
      <label>

        Sie willigen ein, dass die Verfügbarkeit der Einrichtungen und Eigenschaften der einzelnen, aufgelisteten
        Hotel/Resort/Villa unter deren eigenen, direkten Kontrolle stehen und daher {{ kontakt.firma }} nicht
        verantwortlich
        gehalten werden kann, falls Sie während Ihres Aufenthaltes im Hotel/Resort/Villa, keinen Zugriff auf diese
        Einrichtungen und Ausstattungen bekommen sollten. Es wird keinerlei Verantwortung getragen für
        Hotel/Resort/Villa
        Beschreibungen. Falls eine Einrichtung oder Ausstattung für Sie von besonderer Bedeutung ist, sollten Sie mit
        {{ kontakt.firma }} oder direkt mit dem Hotel/Resort/Villa (wir übernehmen keine Verantwortung, wenn sie das
        Hotel/Resort/Villa direkt kontaktieren) überprüfen, ob diese Ausstattung oder Einrichtung während Ihres
        Aufenthalts verfügbar ist.
        <br>

        Bitte beachten Sie, dass Check-in Zeiten von Land zu Land und Hotel zu Hotel unterschiedlich sein können. Dies
        liegt meist zwischen 12:00 Uhr & 16:00 Uhr.
        Fotos und Bilder können eine indikative und/oder allgemeine Repräsentation unseres Produktes sein. Alle Bilder
        unserer Hotels/Resorts/Villen sind im allgemeinen in der Sommersaison (Juni-August) gemacht worden.
        {{ kontakt.firma }}
        bemüht sich, dass die aufgeführten Fotos einen guten Hinweis auf unsere Produkte liefern und aktuell sind.
        Jedoch
        können wir nicht deren Exaktheit garantieren. Fotos sind nicht immer von der gleichen Saison und die Zimmer, die
        den Gästen zur Verfügung gestellt werden, können von den Bildern abweichen (z.B. Standard, Superior, Deluxe,
        usw.)
        {{ kontakt.firma }} wird sich stets bemühen, die gebuchten Räume zur Verfügung zu stellen, jedoch kann es
        vorkommen,
        dass
        statt eines Zimmers mit Doppelbett, ein Zimmer mit zwei Einzelbetten bereitgestellt wird.
        Obwohl {{ kontakt.firma }} sich bemüht seine Kunden vorher zu informieren, ist sie nicht verantwortlich dafür,
        aktuelle
        Informationen über jegliche Bauarbeiten um die auf {{ pDomain }} aufgelisteten Hotels, Resorts und Villen herum
        zu
        liefern. Bitte beachten Sie, dass es in den Küstenregionen in der Türkei erlaubt ist, zwischen dem 31. Oktober
        bis
        zum 15. Mai Bauarbeiten durchzuführen. Daher kann {{ kontakt.firma }} solch Bauarbeiten nicht verhindern oder
        voraussehen.
        Falls das von Ihnen gebuchte Hotel/Resort/Villa selbst Bauarbeiten innerhalb des Geländes durchführt und Sie im
        Voraus nicht darüber informiert waren, und falls dies Sie daran hindert, Ihren Urlaub zu genießen, informieren
        Sie
        uns bitte umgehend (via Email: {{ kontakt.email }} oder via Telefon: {{ tel }}) und wir bieten
        Ihnen ein
        anderes Hotel in der Nähe oder andere Optionen an. Dies gibt uns die Möglichkeit, Probleme sofort zu lösen.
        Versäumnisse der Benachrichtigung führt zur Entkräftung jeglichen Anspruchs dass in der Zukunft aufkommen kann.

      </label>

      <br>
      <br>
      <h4>Nutzerbewertungen, Kommentare und Inhalt</h4>
      <label>
        {{ kontakt.firma }} kann den Nutzern dieser Webseite die Möglichkeit anbieten, Bewertungen, Kommentare und
        anderes
        Material
        im Zusammenhang mit Hotels und andere Urlaubs- und Reiseerfahrungen („User Materials“) auf dieser Webseite zu
        posten. Sie verzichten auf jegliche Eigentumsrechte, die Sie eventuell auf diese „User Materials“ haben, und
        diese
        können durch {{ kontakt.firma }}, ohne Ihre Erlaubnis, benutzt, kopiert, verteilt oder in anderen Medien zur
        Verfügung
        gestellt werden. Im Fall des Angebots einer solchen Postingeinrichtung stimmen Sie ausdrücklich zu, dass Sie nur
        User Materials gemäß dem Service und im Einklang mit den Geschäftsbedingungen und anderen zugehörigen
        Richtlinien
        zustellen. Falls {{ kontakt.firma }} durch Drittpersonen angeklagt wird im Zusammenhang mit Ihrem Missbrauch
        oder Ihrer
        Verletzung der Geschäftsbedingungen bei der Nutzung dieses Services, stimmen Sie zu, jegliche dadurch
        entstandenen
        Schäden, Verluste, Kosten und Ausgaben des {{ kontakt.firma }} zu ersetzen.
        <br>
        Durch die Nutzung eines solchen Service repräsentieren und garantieren Sie vor Allem dass:
        <br>
        - a) Sie alle Rechte der von Ihnen geposteten Materialien besitzen oder anderwärtig kontrollieren;
        <br>
        - b) Die von Ihnen geposteten User Materials zu diesem Zeitpunkt korrekt sind;
        <br>
        - c) Die von Ihnen geposteten User Materials nicht die Geschäftsbedingungen, Richtlinien oder Grundsätze
        übertreten;
        <br>
        - d) Sie absichtlich oder rücksichtslos, keinerlei Information posten das anderen Personen oder deren Betriebe
        schaden oder verletzen könnte, oder insbesondere: Sie werden keinerlei Kommentare, Informationen oder Material
        posten, das unwahr, arglistig, verleumderisch, beleidigend, obszön ist oder als solches empfunden werden könnte;
        <br>
        - e) Sie sich in keiner Weise betrügerisch Verhalten werden und zu keinerlei betrügerischen oder illegalen
        Aktivitäten ermutigen oder selbst teilnehmen werden;
        <br>
        - f) Sie keine Materialien von Drittpersonen, ohne ein schriftliches Einverständnis, posten oder Verteilen
        werden.
        <br>
        {{ kontakt.firma }} editiert gepostete User Materials in keiner Weise und ist rechtlich nicht verantwortlich,
        und haftet
        nicht für diese User Materials oder deren nachträgliche Sendung oder Verteilung. Außerdem prüft, bestätigt oder
        genehmigt {{ kontakt.firma }} in keiner Weise die Meinungen und Kommentare, welche in User Materials gepostet
        werden.
        Diese
        sind individuelle Meinungen derer, die sie posten. Jegliche Entscheidungen, die Sie anhand dieser Kommentare
        treffen, unterliegt Ihrem eigenen Risiko.
        {{ kontakt.firma }} behält sich das Recht vor, aus welchem Grund auch immer, ein Post abzulehnen oder (ohne
        Ankündigung)
        zu
        löschen. Dies kann vor Allem geschehen, wenn {{ kontakt.firma }} eine Beschwerde von einer Drittperson erhält
        und/oder
        glaubt, dass die allgemeinen Geschäftsbedingungen überschritten worden sind. Falls Sie gegen eines der Posts
        eine
        Beschwerde haben, sollten Sie diese schriftlich an {{ kontakt.email }} senden.
        Bitte beachten Sie, dass sich {{ kontakt.firma }} das Recht vorbehält, weitere Informationen in Bezug auf die
        Art dieser
        Beschwerde anzufordern und Sie gegebenenfalls aufzufordern, Ihre Identität zu überprüfen.

      </label>


      <br>
      <br>
      <h4>Kundenservice und die Handhabung von Beschwerden</h4>
      <label>
        Anfragen oder Wünsche für Information sollten an die folgende E-mail gesendet werden:
        {{ kontakt.email }} oder
        telefonisch an {{ tel }}. Beschwerden senden Sie bitte via Email an {{ kontakt.email }}, welche
        diese im
        Namen von den Lieferanten empfängt. Für eine einfache Handhabung und schnelle Lösung wird den Kunden empfohlen,
        innerhalb von 28 Tagen nach Ende der Reise Ihre Beschwerden einzureichen.
        Im Allgemeinen werden Beschwerden bearbeitet, wenn die in der E-mail erwähnte Angelegenheit zuvor telefonisch
        gemeldet wurde ({{ tel }}) oder wenn während der Reisen eine E-mail an {{ kontakt.email }} gesendet
        wurde
        (oder so schnell wie möglich); oder dem Carrier, falls der Disput während der Hin- oder Rückreise entstand; so
        dass Maβnahmen getroffen werden, um das Problem zu lösen und um den Schaden des Kunden zu minimieren. Jedoch
        können, je nach Situation, Ausnahmen gemacht werden.
        Beschwerden über den Verlust, Diebstahl oder Schaden des Gepäcks, der Kleidung oder persönlichen Gegenständen
        außerhalb der Kontrolle des Kunden sollten an die Fluglinie oder das Hotel gerichtet werden.


      </label>


      <br>
      <br>
      <h4>Falls Sie eine Beschwerde haben</h4>
      <label>

        Falls Sie während Ihres Urlaubes ein Problem haben sollten, bitte informieren Sie umgehend den relevanten
        Anbieter
        (zum Beispiel Ihr Hotelier, lokalen Tour-Organisator, oder Ihren Flughafen Transfer Anbieter) und Ihren Resort
        Repräsentanten, der sich bemühen wird Abhilfe zu schaffen. Falls Ihre Beschwerde lokal nicht gelöst werden kann,
        bitte gehen Sie dieser Angelegenheit innerhalb von 28 Tagen nach Ihrer Heimkehr nach, indem Sie unsere
        Kundenberatungsabteilung unter {{ kontakt.firma }}, {{ kontakt.strnr }}, {{ kontakt.plz }} {{ kontakt.ort }}
        schreiben. Bitte geben Sie Ihre
        Referenznummer
        und alle anderen relevanten Informationen an. Bitte halten Sie Ihren Brief kurz und auf den Punkt gebracht. Dies
        wird uns helfen, Ihre Anfrage schnell zu identifizieren und die Antwortzeit zu verkürzen. Es wird strengstens
        empfohlen, dass Sie jegliche Beschwerden an Ihren Lieferanten des jeweiligen Service und an unseren
        Repräsentanten
        richten und, dass Sie einen Antrag ausfüllen, während Sie sich im Resort befinden. Falls Sie es versäumen, diese
        einfache Prozedur zu verfolgen, wird es für uns leider nicht möglich sein, Ihre Beschwerde noch vor Ort zu
        untersuchen und zu lösen und dies kann sich auf Ihre Rechte im Vertrag auswirken.
        Anträge, die darauf basieren, dass die Durchführung der Reise nicht übereinstimmt mit dem Vertrag, sollten
        innerhalb von 28 Tagen nach dem vertraglichen Ende der Reise eingesendet werden. In einem solchen Fall,
        kontaktieren Sie {{ kontakt.email }} oder rufen Sie unsere Service Nummer an unter {{ tel }}.
        Anträge
        sollten im Interesse des Kunden schriftlich gemacht werden. Die Verjährungsfrist beginnt am letzten Tag der
        Reise,
        gemäß dem Vertrag.

      </label>

      <br>
      <br>
      <h4>Allgemein</h4>
      <label>
        Dieser Vertrag unterliegt im Rahmen der gesetzlichen Vorschriften dem Recht von England und Wales und ist gemäß
        diesem Recht auszulegen. Sie willigen der exklusiven Gerichtsbarkeit der zuständigen englischen Gerichte für
        alle
        Streitigkeiten, die sich aus oder in Zusammenhang mit der Nutzung dieser Webseite und unserer Dienstleistung
        ergeben, ein. Die Nutzung dieser Webseite ist unbefugt innerhalb jener Gerichtsbarkeit, die nicht alle
        Vorschriften dieser Geschäftsbedingung, inklusive und ohne Beschränkung dieses Paragraphen, gewähr gibt.
        <br>
        <br>

        Für Angelegenheiten der Kundenberatung, kontaktieren bitte Sie {{ kontakt.email }}. Für sonstiges,
        schreiben Sie
        bitte an:
        <br>


        <h4> {{ kontakt.firma }}</h4>
        <h4>{{ kontakt.strnr }},</h4>
        <h4>{{ kontakt.plz }} {{ kontakt.ort }}</h4>

        <br>
        <br>

        {{ kontakt.firma }} ist berechtigt hierunter Rechte, Aufträge, oder Pflichten zu erteilen, zu übertragen, einen
        Untervertrag
        abzuschließen oder zu delegieren. Sie sind hierzu nicht berechtigt.
        Sie stimmen zu, dass zwischen Ihnen und {{ kontakt.firma }} keinerlei Arbeitsgemeinschaft (Joint Venture),
        Partnerschaft,
        Angestelltenverhältnis oder Agenturenverhältnis besteht infolge dieses Vertrags, oder der Nutzung dieser
        Webseite.
        <br>

        Die Erfüllung von dieser Vereinbarung durch {{ kontakt.firma }} unterliegt dem geltenden Recht und
        Gerichtsverfahren.
        Nichts
        das sich in diesem Vertrag befindet, führt zur Beeinträchtigung des Rechts von {{ kontakt.firma }} auf
        Zwangsvollstreckungsanforderungen oder Ansprüche in Zusammenhang mit Ihrer Nutzung dieser Webseite; oder für
        {{ kontakt.firma }} bereitgestellte Informationen; oder von {{ kontakt.firma }} erfasste Informationen im
        Zusammenhang mit
        dieser
        Nutzung.
        <br>
        Sollte irgend ein Teil dieser Vereinbarung, gemäß dem geltenden Recht, sich für ungültig oder nicht
        vollstreckbar
        erweisen, inklusive, jedoch nicht beschränkt auf, Haftungsausschluss und Haftungsbegrenzung, dann wird die
        ungültige oder nicht vollstreckbare Regelung durch eine gültige, vollziehbare Regelung ersetzt, dass dem
        Original
        am Nächsten liegt. Der Rest des Vertrags wird seine Gültigkeit bewahren.

        <br>
        Dieser Vertrag bildet den kompletten Vertrag zwischen, Ihnen, dem Kunden und {{ kontakt.firma }}, was diese
        Webseite
        anbelangt; und ersetzt alle vorhergehenden oder zeitgleichen Kommunikationen und Anträge, in elektronischer,
        mündlicher oder schriftlicher Form zwischen dem Kunden und {{ kontakt.firma }}, was diese Webseite betrifft.
        Eine
        gedruckte
        Version dieser Abmachung und jegliche in elektronischer Form eingereichte Mitteilungen sind in rechtlichen oder
        administrativen Verfahren akzeptabel, die auf diese Abmachung basieren oder im Zusammenhang stehen. Dies gilt
        zum
        gleichen Ausmaß und ist abhängig gemäß gleicher Bedingungen wie andere Geschäftsunterlagen und Akten die
        ursprünglich in gedruckter Form entstanden und als solche geführt wurden.
        Hierin erwähnte, fiktive Namen oder Unternehmen, Produkte, Menschen, Eigenschaften und/oder Daten dienen nicht
        als
        Repräsentation realer Individuen, Unternehmen, Produkte oder Ereignisse. Alle Rechte, die hier nicht
        ausdrücklich
        gewährt wurden, sind vorbehalten.
        <br>

      </label>

      <br>
      <br>
      <h4>Nutzung von {{ pDomain }} und aller länderspezifischen {{ kontakt.firma }}-Domains</h4>
      <label>
        {{ pDomain }} wurde lediglich erstellt, um den Kunden in Bezug auf die Verfügbarkeit von Reise-bezogenen
        Produkten
        und Dienstleistungen zu assistieren, und um legitime Reservationen zu tätigen oder, ansonsten, um mit
        Lieferanten
        Geschäfte abzuschließen – sonst für keinen anderen Zweck. Sie garantieren, dass Sie mindestens 18 Jahre alt
        sind,
        und über die rechtliche Befugnis verfügen, diesen Vertrag zu schließen und diese Webseite gemäß der gesamten
        Geschäftsbedingungen zu nutzen. Sie willigen ein, dass Sie für jegliche Nutzung dieser Webseite die finanzielle
        Verantwortung tragen (zuzüglich der Nutzung Ihres Accounts durch andere, inklusive und nicht ausschließlich
        durch
        Minderjährige (unter 18 Jahren) in Ihrem Haushalt). Sie willigen ein, jegliche Nutzung dieser Webseite durch
        Minderjährige, unter Ihrem Namen oder Account, zu beaufsichtigen. Des Weiteren haften Sie für die Richtigkeit
        jeglicher, durch diese Webseite von Ihnen oder in Ihrem Haushalt lebenden Personen gelieferten Information.
        Jegliche spekulative, falsche oder betrügerische Reservation oder jegliche Reservation in Erwartung des Bedarfs
        ist verboten. Sie willigen ein, dass die reisebezogenen Kundendiensteinrichtungen dieser Webseite nur benutzt
        werden können, um für sich selbst oder andere Personen für die Sie bevollmächtigt sind zu handeln, legitime
        Reservationen oder Einkäufe zu tätigen. Sie verstehen, dass der übermäßige Gebrauch oder Missbrauch der
        reisebezogenen Kundendiensteinrichtungen dieser Webseite dazu führen kann, dass Ihnen der Zugriff zu diesen
        Einrichtungen verweigert wird.
        <br>
        Separate Geschäftsbedingungen gelten für Ihre Reservation und Kauf von reisebezogenen Produkten und Diensten
        Ihrer
        Wahl. Sie stimmen zu, sich der jeweiligen Geschäftsbedingungen der Lieferanten Ihrer Wahl, im Falle eines Kaufes
        zu fügen, inklusive und ohne Beschränkung auf die Zahlung aller Summen, wenn diese fällig sind und die
        Einhaltung
        aller Regeln und Einschränkungen bezüglich der Verfügbarkeit und der Nutzung von Fahrtkosten, Produkten oder
        Dienstleistungen. Spezielle Richtlinien und Beschränkungen für Fahrtkosten, Produkte oder Dienstleistungen Ihrer
        Wahl können gelten. Sie verstehen, dass jegliche Verletzung der Geschäftsbedingungen von Lieferanten, zur
        Annullierung Ihrer Reservation(en) oder Ihres Einkaufs führen kann und Ihnen diesbezüglich der Zugang zu Flügen,
        Hotels, Resorts, Villen oder Fahrzeugen verweigert werden kann. Dies kann auch zum Verlust jeglicher bezahlter
        Gelder Ihrerseits für beispielsweise Reservierungen oder Käufe führen. Außerdem kann eine solche Verletzung zur
        Folge haben, dass {{ kontakt.firma }} Ihr Konto für jegliche {{ kontakt.firma }} entstandenen Kosten belastet.
        Sie sind für
        jegliche,
        in Folge der Nutzung dieser Webseite entstandenen Kosten, Gebühren, Pflichten, Steuern und Taxierungen
        vollständig
        verantwortlich.
        <br>
        <br>
        Währungsrechner
        <br>
        Die Währungskurse basieren auf verschiedene öffentliche Quellen und sollten nur als Richtlinien gesehen werden.
        Die Wechselkurse werden nicht genau überprüft und können sich aktuell ändern. Währungsquoten werden nicht
        täglich
        aktualisiert. Bitte überprüfen Sie daher das Datum auf dem Währungsrechner, um zu sehen, wann die Daten das
        letzte
        Mal aktualisiert wurden. Die von dieser Applikation gelieferten Informationen werden für akkurat gehalten;
        {{ kontakt.firma }} oder seine Lieferanten haften jedoch nicht für dessen Korrektheit. Falls diese Information
        für
        finanzielle Zwecke genutzt wird, rät {{ kontakt.firma }} den Kunden, sich mit einem qualifizierten Experten zu
        beraten,
        um
        die Genauigkeit der Währungskurse zu verifizieren. {{ kontakt.firma }} oder seine Partner und/oder Lieferanten
        autorisieren
        in keiner Weise die Nutzung dieser Informationen für Zwecke außerhalb der individuellen Nutzung und verbietet
        ausdrücklich jeglichen Weiterverkauf, Verteilung und Nutzung dieser Information für kommerzielle Zwecke.


      </label>

      <br>
      <br>
      <h4>Hinweise zur Copyright und Handelsmarke</h4>
      <label>
        Alle Rechte auf dieser Webseite sind: Copyright© 2009 - 2021 {{ kontakt.firma }} Alle Rechte vorbehalten. Andere
        hier
        erwähnte Produkt- oder Unternehmensnamen sind eventuell Handelsmarken der betreffenden Eigentümer. Bilder auf
        dieser Webseite dienen lediglich der Besichtigung und dürfen in keiner Weise gespeichert oder heruntergeladen
        werden.
        <br>
        © 2009 - 2021 {{ kontakt.firma }}. Alle Rechte vorbehalten. {{ kontakt.firma }} - {{ kontakt.strnr }},
        {{ kontakt.plz }}
        {{ kontakt.ort }}.

      </label>


      <br>
      <br>
      <h4>Anwendbares Recht, Gerichtsstand, Nebenabreden</h4>
      <label>
        Es gilt deutsches Recht. Anderenfalls gilt der gesetzliche Gerichtsstand. Mündliche Nebenabreden bestehen nicht.
        Änderungen und Ergänzungen bedürfen der Schriftform.
      </label>


      <br>
      <br>
      <h4>Salvatorische Klausel</h4>
      <label>
        Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise nicht Vertragsbestandteil geworden oder unwirksam
        sein, so bleibt der Vertrag im Übrigen wirksam. Soweit die Bestimmungen nicht Vertragsbestandteil geworden oder
        unwirksam sind, richtet sich der Inhalt des Vertrages nach den gesetzlichen Vorschriften.
      </label>


      <br>
      <br>

      <h4> {{ kontakt.firma }}</h4>
      <h4>{{ kontakt.strnr }}, {{ kontakt.plz }} {{ kontakt.ort }}</h4>
      <h4>Tel.: {{ kontakt.tel }}</h4>
      <h4>Fax.: {{ kontakt.fax }}</h4>
      <h4>Geschäftsführung: {{ pRechtliches.geschaftsfuhrer }}</h4>


      <br>
      <label>Stand 10 Oktober 2021</label>

    </div>
  </div>
</template>

<script>

export default {
  name: "AGB",
  props: [
    'tel',
    'mobile',
    'pDomain',
    'kontakt',
    'pRechtliches'
  ],
  data() {
    return {}
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 2);


  },
  async created() {

    await new Promise(resolve => setTimeout(resolve, 500));
  }
}
</script>

<style scoped>

</style>
