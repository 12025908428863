import axios from "axios";
import {getToken, getUserToken} from "../util/userCredentials";
import {getCurrency} from "./currency";


export const postBooking = async (booking) => {

    const token = await getUserToken();

    let res = "";
    let data = "";
    let d = {
        token: token,

        booking: booking,
        lang: localStorage.getItem('lang')
    }
    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halaladd", d,{headers: {"authorization": 'Bearer ' + await getToken()}}
        );
        data = res.data;
    } catch (e) {
        return {msg: "booking error",error:true};
    }

    return data.data;
}