<template>
  <div>
    <div class="div-search">

      <v-snackbar
          v-model="notificationsnackbar"
          :timeout="6000"
          :color="notificationcolor"
          :top=true
          :right=true
          :vertical=true
      >
        {{ notificationtext }}
        <v-btn
            color="white"
            text
            @click="notificationsnackbar = false"
        >
          {{ $t("Notification.Schliessen") }}
        </v-btn>
      </v-snackbar>

      <div class="div-destination-search">
        <div @click="openDestination" class="div-labels-destination-search">
          <!--          <v-icon color="var(&#45;&#45;Primary)">mdi-magnify</v-icon>-->
          <v-icon color="var(--Primary)">mdi-magnify</v-icon>

          <div class="div-labels-destination-search1">
            <label v-if="searchtext==='' || searchtext === null" class="label-searchheader-search text-align-left">
              {{ $t("Search.AlleReiseziele") }}
            </label>
            <label v-else class="label-searchheader-search text-align-left">{{ searchtext }}</label>

            <label v-if="selectedholidayType===''" class="label-typ-search text-align-left">
              {{ $t("Search.AlleThemen") }}
            </label>
            <template v-if="searchParameterType !== 'property'">
              <label v-if="selectedholidayType !== ''" class="label-typ-search text-align-left">
                {{ selectedholidayType }}
              </label>
            </template>
          </div>

        </div>
        <transition name="fade">
          <div v-if="showDestination" class="div-dd-destination-search1">

            <v-text-field
                :label="$t('Search.OrtoderHotel')"
                :placeholder="$t('Search.OrtoderHotel')"
                ref="searchtext"
                solo
                clearable
                v-model="searchtext"
                v-on:keyup.enter="setHalalParameter"
                @input="searchOwnHalalPlaces"
                @click:clear="clearSearchParameter"
                style="margin-top: 10px"
            >
              <template v-slot:prepend-inner>
                <v-icon color="var(--Primary)">
                  mdi-magnify
                </v-icon>
              </template>

            </v-text-field>

            <div class="div-search-results-search">
              <v-list
                  nav
                  dense
              >
                <v-list-item-group
                    color="primary"
                >


                  <!--                  v-if="item['search-type'] !=='region'"-->
                  <v-list-item
                      v-for="(item, index) in  halalsearchresult.results"
                      :key="index"
                      @click="setHalalParameterIndex(index)"
                  >
                    <v-list-item-icon>
                      <!--                      <v-icon>mdi-map-marker</v-icon>-->
                      <v-icon color="var(--Primary)">mdi-map-marker</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>


            </div>

            <template
                v-if="searchtext === '' || searchtext === null || searchParameterType ==='location'|| searchParameterType ==='region'"
            >
              <div class="div-holidayType-search">
                {{ $t("Search.Urlaubsthema") }}
              </div>

              <v-chip-group
                  mandatory
                  column
                  active-class="activ-chip-search"
                  class="vchipgroup-search "
                  v-model="chiphHolidayType"

              >
                <v-chip
                    v-for="(tag,i) in holidayTypes"
                    :key="tag.id"
                    color="white"
                    class="vchip-search"
                    @click="setSelectedHolidayType(i)"
                    filter
                >
                  {{ tag.text }}
                </v-chip>
              </v-chip-group>
            </template>


            <div class="div-vbtn-fertig-search">
              <!--              <v-btn class="vbtn-fertig-search" @click="closeDestination" color="var(&#45;&#45;Primary)">-->
              <v-btn class="vbtn-fertig-search vbtn-linear-gradient" @click="closeDestination">
                {{ $t("Button.Fertig") }}
              </v-btn>
            </div>
          </div>
        </transition>
      </div>


      <!-- ------------------------------ DATES Anfang  ------------------------------ -->


      <div class="div-date-search" @click="closePersons">


        <v-icon color="var(--Primary)">mdi-calendar-month</v-icon>
<!--        <v-icon class="vicon-color-linear-gradient">mdi-calendar-month</v-icon>-->

        <HotelDatePicker
            :i18n="getLangDatePicker"
            format='DD.MM.YYYY'
            :startDate="minDate"
            :minNights=1
            @check-in-changed="updateCheckIn"
            @check-out-changed="updateCheckOut"
            :firstDayOfWeek=1
            :startingDateValue="selectedcheckin ==='' ? undefined : selectedcheckin"
            :endingDateValue="selectedcheckout ==='' ? undefined : selectedcheckout"

        />
      </div>


      <!-- ------------------------------ DATES Ende  ------------------------------ -->


      <!-- ------------------------------ Persons Anfang  ------------------------------ -->

      <div class="div-persons-search">
        <div @click="openPersons" class="div-labels-persons-search">

          <!--          <v-icon color="var(&#45;&#45;Primary)">mdi-account-supervisor</v-icon>-->
          <v-icon color="var(--Primary)">mdi-account-supervisor</v-icon>
          <div class="div-labels-persons-search1">
            <label class="label-searchpersonsheader-search">
              {{ anzahlPersonen !== 1 ? anzahlPersonen + ' ' + $t("Search.Personen") : 1 + ' ' + $t("Search.Person") }}
              <br>
              {{ gruppen.length > 1 ? '(' + gruppen.length + ' ' + $t("Search.Gruppen") + ')' : '' }}
            </label>
          </div>

        </div>

        <transition name="fade">
          <div v-if="showPersons" class="div-dd-persons-search">

            <template v-for="(item,index) in gruppen">
              <div class="div-grid-persons-search">
                <div class="div-personsgroup-search">
                  <label class="label-group-search">{{ $t("Search.Gruppe") }} {{ index + 1 }}</label>
                  <span></span>
                  <v-btn
                      class="vbtn-entfernen-search"
                      x-small
                      color="error"
                      @click="removeGroup(index)"
                      :disabled="gruppen.length === 1"
                  >
                    {{ $t("Button.Entfernen") }}
                  </v-btn>
                  <!--            <v-btn x-small color="success">Bearbeiten</v-btn>-->
                </div>
                <div class="div-addpersons-search">
                  <div>
                    {{ $t("Search.Erwachsene") }}

                    <div class="div-addpersons-search1">

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="removeAdult(index)"
                          :disabled="gruppen[index].erwachsene === 1"
                      >
                        <v-icon dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                      <label>{{ item.erwachsene }}</label>

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="addAdult(index)"
                          :disabled="gruppen[index].erwachsene === 10"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>


                  <div>
                    {{ $t("Search.Kinder") }}
                    <div class="div-addpersons-search1">

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="removeChild(index)"
                          :disabled="gruppen[index].kinder < 1"
                      >
                        <v-icon dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                      <label>{{ item.kinder }}</label>

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="addChild(index)"
                          :disabled="gruppen[index].kinder ===7"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>


                <div>
                  <label v-if="gruppen[index].kinder>0">
                    {{ $t("Search.GebKinder") }}
                  </label>
                  <div class="div-addChild-search">
                    <template v-for="(info,i) in item.kinderalter">

                      <BirthdayPicker
                          :selecteddate="item.kinderalter[i].date"
                          :gruppe="index"
                          :id="i"
                      />

                    </template>
                  </div>
                </div>


              </div>
            </template>

            <v-btn
                class="vbtn-addgroup-search"
                small
                color="var(--Secondary)"
                @click="addGroup"
                :disabled="gruppen.length ===10"
            >
              <!--            <v-btn-->
              <!--                class="vbtn-addgroup-search vbtn-linear-gradient"-->
              <!--                small-->
              <!--                @click="addGroup"-->
              <!--                :disabled="gruppen.length ===10"-->
              <!--            >-->
              <v-icon color="white" small>mdi-plus</v-icon>
              {{ $t("Search.WeitereGruppe") }}
            </v-btn>

            <div class="div-vbtn-fertig-search">
              <!--              <v-btn class="vbtn-fertig-search" @click="closePersons" color="var(&#45;&#45;Primary)">-->
              <!--                {{ $t("Button.Fertig") }}-->
              <!--              </v-btn>-->
              <v-btn class="vbtn-fertig-search vbtn-linear-gradient" @click="closePersons">
                {{ $t("Button.Fertig") }}
              </v-btn>
            </div>
          </div>
        </transition>
      </div>

      <!-- ------------------------------ Persons ENDE  ------------------------------ -->

      <div class="div-button-search">
        <!--        <v-btn class="vbtn-search" color="var(&#45;&#45;Primary)" @click="search">-->
        <!--          {{ $t("Button.Suchen") }}-->
        <!--        </v-btn>-->
        <v-btn class="vbtn-search vbtn-linear-gradient" @click="search">
          {{ $t("Button.Suchen") }}
        </v-btn>
      </div>

    </div>

    <div v-if="loadingsearch" style="margin: 50px auto;">
      <!--    <div v-if="true" style="margin: 50px auto;">-->
      <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
          :width="7"

      />
      <SkeletonCardHorizontal/>
    </div>

  </div>
</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker';
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import * as UrlUtil from '../../util/UrlUtil';
import {onePlaceWithParameter} from "../../util/halalapi";
import BirthdayPicker from "../../components/DatePicker/BirthdayPicker";
import {getCurrency} from "../../util/currency";
import {halalsearch} from "../../util/searchUtil";
import {getAge} from "../../util/Datez";
import SkeletonCardHorizontal from "../../components/skeletons/SkeletonCardHorizontal";
import moment from "moment";

export default {
  name: "Search",
  props: [
    'propsSearchtext',
    'propsHolidayType',
    'halalHotels',
    'from',
    'groups',
    'lang'
  ],

  components: {
    HotelDatePicker,
    BirthdayPicker,
    SkeletonCardHorizontal
  },
  data() {
    return {
      loadingsearch: false,
      showDestination: false,
      showPersons: false,
      places: "",
      searchtext: "",
      holidayTypes: [
        {id: "all", text: this.$t("Search.AlleThemen")},
        {id: "resort", text: this.$t("Search.Resorts")},
        {id: "hotel", text: this.$t("Search.CityHotels")},
        {id: "thermal", text: this.$t("Search.WellnessHotels")},
        {id: "villa", text: this.$t("Search.Villen")}
      ],
      selectedholidayType: this.$t('Search.AlleThemen'),
      chiphHolidayType: "",
      holiday_type: "",

      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),


      anzahlGruppen: 1,
      anzahlErwachsene: 2,
      anzahlKinder: 0,
      alterKinder: [],
      anzahlPersonen: 0,


      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",


      gruppen: [{name: "Gruppe 1", erwachsene: 2, kinder: 0, kinderalter: []}],
      newgroups: "",

      selectedstartdate: "",
      selectedenddate: "",

      selectedcheckin: "",
      selectedcheckout: "",
      halalsearchresult: [],
      searchParameter: "",
      searchParameterID: "",
      searchParameterType: "",


      SearchDatePickerDE: {
        night: 'Nacht',
        nights: 'Nächte',
        'day-names': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        'check-in': 'Check-in',
        'check-out': 'Check-out',
        'month-names': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerTR: {
        night: 'Gece',
        nights: 'Geceler',
        'day-names': ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
        'check-in': 'Giriş',
        'check-out': 'Çıkış',
        'month-names': ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerEN: {
        night: 'Night',
        nights: 'Nights',
        'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'check-in': 'Check-in',
        'check-out': 'Check-out',
        'month-names': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum",
        },
      }

    };

  },

  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    openDestination() {

      this.showDestination = !this.showDestination;
      this.showPersons = false;
      if (this.showDestination === true) {
        this.$nextTick(() => this.$refs.searchtext.focus());
      }

      this.searchOwnHalalPlaces();

    },

    closeDestination() {
      this.showDestination = false;
      this.showPersons = false;

      if (this.searchParameterID === "") {
        this.setHalalParameter();
      }
    },

    setHalalParameter() {
      // if (this.searchtext !== null) {
      //   this.searchParameter = this.halalsearchresult.results[0]["name-en"].includes(",") ? this.halalsearchresult.results[0]["name-en"].split(",")[0] : this.halalsearchresult.results[0]["name-en"];
      //
      //   this.searchtext = this.halalsearchresult.results[0]["name"];
      //   this.searchParameterID = this.halalsearchresult.results[0].id;
      // }
      //
      //
      //
      // if (this.searchParameterType === "location") {
      //
      // }
      //


      let searchParameter = this.halalsearchresult.results.find(item => item.id === parseInt(this.searchParameterID));
      this.searchParameter = searchParameter["name-en"].includes(",") ? searchParameter["name-en"].split(",")[0] : searchParameter["name-en"];
      this.searchParameterType = searchParameter["search-type"];

      if (this.searchParameterType === "property") {
        this.showDestination = false;
        this.showPersons = false;
      }
    },

    setHalalParameterIndex(index) {


      this.searchParameter = this.halalsearchresult.results[index]["name-en"].includes(",") ? this.halalsearchresult.results[index]["name-en"].split(",")[0] : this.halalsearchresult.results[index]["name-en"];
      // this.test = this.searchParameter;
      this.searchParameterID = this.halalsearchresult.results[index].id;
      this.searchtext = this.halalsearchresult.results[index]["name"];
      this.searchParameterType = this.halalsearchresult.results[index]["search-type"];

      if (this.searchParameterType === "location") {
      }

      if (this.searchParameterType === "property") {
        this.showDestination = false;
        this.showPersons = false;
      }


      localStorage.setItem('des', this.searchtext);
      localStorage.setItem('desP', this.searchParameter);
      localStorage.setItem('desID', this.searchParameterID);


    },

    clearSearchParameter() {

      this.searchtext = null;
      this.searchParameter = "";
      this.searchParameterType = "";
      this.searchParameterID = "";
      this.halalsearchresult = [];

      localStorage.removeItem('des');
      localStorage.removeItem('desP');
      localStorage.removeItem('desID');
    },

    openPersons() {
      this.showDestination = false;
      this.showPersons = !this.showPersons;
    },

    closePersons() {
      this.showDestination = false;
      this.showPersons = false;
    },


    addAdult(i) {
      if (this.gruppen[i].erwachsene < 10) {
        let g = this.gruppen[i];
        g.erwachsene = g.erwachsene + 1;
        this.gruppen[i] = g;
        this.anzahlPersonen = this.anzahlPersonen + 1;
      }
    },

    removeAdult(i) {
      if (this.gruppen[i].erwachsene > 1) {
        let g = this.gruppen[i];
        g.erwachsene = g.erwachsene - 1;
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen - 1;
      }
    },

    addChild(i) {
      if (this.gruppen[i].kinder < 7) {
        let g = this.gruppen[i];
        g.kinder = g.kinder + 1;
        g.kinderalter.push({age: "0", date: ""});
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen + 1;
      }
    },

    removeChild(i) {
      if (this.gruppen[i].kinder > 0) {
        let g = this.gruppen[i];

        g.kinder = g.kinder - 1;
        g.kinderalter.pop();
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen - 1;
      }
    },

    addGroup() {
      if (this.gruppen.length < 10) {
        let length = this.gruppen.length;
        let v = "Gruppe " + (length + 1);
        this.gruppen.push({name: v, erwachsene: 2, kinder: 0, kinderalter: []});

        this.anzahlPersonen = this.anzahlPersonen + 2;
      }
    },

    removeGroup(i) {
      if (this.gruppen.length > 1) {
        this.anzahlPersonen -= (this.gruppen[i].erwachsene + this.gruppen[i].kinder);
        this.gruppen = this.gruppen.filter((item, index) => index !== i);
      }
    },

    setSelectedHolidayType(i) {
      this.selectedholidayType = this.holidayTypes[i].text;
      this.chiphHolidayType = this.selectedholidayType;

      let holiday_type = this.selectedholidayType;

      if (holiday_type === "Tüm temalar" || holiday_type === "Alle Themen" || holiday_type === "Any theme") {
        this.holiday_type = "xx";
      }
      if (holiday_type === "Termal" || holiday_type === "Wellness" || holiday_type === "Wellness") {
        this.holiday_type = "thermal";
      }
      if (holiday_type === "Villalar" || holiday_type === "Villen" || holiday_type === "Villas") {
        this.holiday_type = "villa";
      }
      if (holiday_type === "Tatil köyleri" || holiday_type === "Resorts" || holiday_type === "Resorts") {
        this.holiday_type = "resort";
      }

      if (holiday_type === "Şehir tatili" || holiday_type === "Städtereise" || holiday_type === "City Break") {
        this.holiday_type = "hotel";
      }

      localStorage.setItem('ht', this.holiday_type);
      localStorage.setItem('htP', this.holidayTypes[i].text);
      localStorage.setItem('htC', i);

    },

    async searchOwnHalalPlaces() {
      if (this.searchtext !== '' && this.searchtext !== null) {
        // this.halalsearchresult = await halalsearch(this.searchtext);
        // if (this.searchParameter !== "") {
        //   this.halalsearchresult = await halalsearch(this.searchParameter);
        // } else {

        this.searchtext = this.searchtext.replace(/ *\([^)]*\) */g, "");
        this.halalsearchresult = await halalsearch(this.searchtext);
        //   }
        // } else {
        //
      }

    }
    ,

    async search() {


      this.showDestination = false;
      this.showPersons = false;
      this.loadingsearch = true;
      //let url = process.env.VUE_APP_API;
      let checkin = "";
      let checkout = "";

      let value = "";
      let url = "";

      if (this.$route.query.name !== undefined) {
        this.searchtext = this.$route.query.name;
        this.searchParameter = this.$route.query.name;
      }

      //Wenn das Gesuchte ein Ort ist.
      if (this.searchParameterType === "location" || this.searchParameterType === "region" || this.searchParameterType === "") {
        if (this.searchtext !== "") {
          url = UrlUtil.addLocation(url, this.searchParameter);
          url = UrlUtil.bind(url);
        }


        if (this.selectedholidayType !== "Alle Themen" && this.selectedholidayType !== "Tüm temalar" && this.selectedholidayType !== "Any theme") {
          let v = this.holidayTypes.find(item => item.text === this.selectedholidayType);

          url = UrlUtil.addHolidayType(url, v.id);
          // url = UrlUtil.addHolidayType(url, "resort");
          url = UrlUtil.bind(url);
        } else {

          url = UrlUtil.addHolidayType(url, "xx");
          url = UrlUtil.bind(url);
        }

        if (this.selectedstartdate === "" || this.selectedstartdate === null) {

          let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
          checkin = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
          url = UrlUtil.addCheckin(url, checkin);
          url = UrlUtil.bind(url);

        } else {

          let d = new Date(this.selectedstartdate);
          checkin = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
          url = UrlUtil.addCheckin(url, checkin);
          url = UrlUtil.bind(url);
        }

        if (this.selectedenddate === "" || this.selectedenddate === null) {
          let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
          checkout = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
          url = UrlUtil.addCheckout(url, checkout);

        } else {
          let d = new Date(this.selectedenddate);
          checkout = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
          url = UrlUtil.addCheckout(url, checkout);
          // url = UrlUtil.bind(url);
        }


        this.gruppen.map((item, index) => {
          let groups = "";
          if (index > 0)
            UrlUtil.bind(url);
          value = UrlUtil.binddata(value);

          let erw = item.erwachsene;
          let kinder = item.kinder;

          if (kinder > 0) {
            item.kinderalter.map(it => {
              if (groups !== "")
                groups = groups.concat(",");

              groups = groups.concat(it.age.toString());
            });
          }

          if (kinder > 0) {
            url = UrlUtil.addGroups(url, erw + "," + groups);
            value = UrlUtil.addGroupsData(value, erw + "," + groups);
          } else {
            url = UrlUtil.addGroups(url, erw);
            value = UrlUtil.addGroupsData(value, erw);
          }


        });


        url = UrlUtil.bind(url);
        let currency = getCurrency();
        url = UrlUtil.addCurrency(url, currency);

        this.$root.$emit("checkin", checkin);
        this.$root.$emit("checkout", checkout);
        this.$root.$emit("groups", value);
        this.$root.$emit("loading", true);
        this.$root.$emit("loading", false);
        let p = 1;
        this.$root.$emit("url", url, p);


        this.zuHome(this.searchParameter, this.holiday_type, checkin, checkout, value);


      }


      //Wenn das Gesuchte ein Hotel ist.
      if (this.searchParameterType === "property") {

        if (this.selectedstartdate !== "") {
          let d = new Date(this.selectedstartdate);
          checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
          url = UrlUtil.addCheckin(url, checkin);
          url = UrlUtil.bind(url);
        } else {
          let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
          checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
          url = UrlUtil.addCheckin(url, checkin);
          url = UrlUtil.bind(url);
        }

        if (this.selectedenddate !== "") {
          let d = new Date(this.selectedenddate);
          checkout = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
          url = UrlUtil.addCheckout(url, checkout);
          url = UrlUtil.bind(url);

        } else {
          let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
          checkout = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
          url = UrlUtil.addCheckout(url, checkout);
          url = UrlUtil.bind(url);
        }

        this.gruppen.map((item, index) => {
          let groups = "";
          if (index > 0)

            value = UrlUtil.binddata(value);

          let erw = item.erwachsene;
          let kinder = item.kinder;

          if (kinder > 0) {
            item.kinderalter.map(it => {
              if (groups !== "")
                groups = groups.concat(",");
              groups = groups.concat(it.age.toString());
            });
          }

          if (kinder > 0) {

            value = UrlUtil.addGroupsData(value, erw + "," + groups);
          } else {

            value = UrlUtil.addGroupsData(value, erw);
          }


        });

        //TODO: Bei der Suche müssen wir doch keine Suche übergeben oder??
        // let place = await onePlaceWithParameter(this.searchParameterID, checkin, checkout, "&" + value);
        // this.$root.$emit("place", place);


        this.zuPlace(this.searchParameterID, checkin, checkout, "&" + value);


      }


      this.loadingsearch = false;
    }
    ,

    zuPlace(id, checkin, checkout, groups) {
      localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      this.$router.push({
        path: 'place',
        query: {id: id, checkin, checkout, groups}
      });
      window.scrollTo(0, 0);
    }
    ,

    zuHome(searchtext, holidayt, checkin, checkout, groups) {
      localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      let holiday_type = "";
      if (holidayt === undefined || holidayt === "") {
        holiday_type = "XX";
      } else {
        holiday_type = holidayt;
      }

      if (searchtext !== "") {
        this.$router.push({
          path: 'home',
          query: {searchtext, holiday_type, checkin, checkout}
        }).catch(failure => {
          location.reload();
        })
      } else {
        this.$router.push({
          path: 'home',
          query: {holiday_type, checkin, checkout}
        }).catch(failure => {
          location.reload();
        })
      }


      location.reload();
      window.scrollTo(0, 0);
    }
    ,

    updateCheckIn(date) {
      this.selectedstartdate = date;
      this.selectedcheckin = date;

      localStorage.setItem('c-in', date);

    },

    updateCheckOut(date) {
      this.selectedenddate = date;
      this.selectedcheckout = date;

      localStorage.setItem('c-out', date);


      this.gruppen.map((item, index) => {
        item.kinderalter.map((it, i) => {
          let age = getAge(it.date, this.selectedenddate);

          this.gruppen[index].kinderalter[i].age = age.toString();
        });
      });


    },
  },

  computed: {
    getLangDatePicker() {

      switch (this.lang) {
        case "EN":
          return this.SearchDatePickerEN;
        case "TR":
          return this.SearchDatePickerTR;
        case "DE":
          return this.SearchDatePickerDE;
        default:
          return this.SearchDatePickerDE;
      }


    }
  },

  async created() {


    if (localStorage.getItem('des') !== null) {
      this.searchtext = localStorage.getItem('des');
      this.searchParameter = localStorage.getItem('desP');
      this.searchParameterID = localStorage.getItem('desID')

      await this.searchOwnHalalPlaces();
      await this.setHalalParameter();
    }

    if (localStorage.getItem('ht') !== null) {
      this.holiday_type = localStorage.getItem('ht');
      this.selectedholidayType = localStorage.getItem('htP');
      this.chiphHolidayType = parseInt(localStorage.getItem('htC'));

    }


    // ------------------------------------ DATUM anzeigen ------------------------------------

    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));
      this.selectedcheckin = c_in;


    } else {
      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.selectedcheckout = c_out;


    } else {
      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.selectedenddate = d1;

    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------


    let d = new Date().getFullYear();
    let arr = [];
    for (let i = 0; i <= 17; i++) {

      arr.push({text: (d - i).toString(), id: i});
    }
    this.alterKinder = arr;


    if (this.propsHolidayType !== undefined) {
      let type = this.holidayTypes.find(item => item.id === this.propsHolidayType);
      if (type !== undefined) {
        this.selectedholidayType = type.text;
      }

      this.holidayTypes.map((item, index) => {
        if (item.id === this.propsHolidayType) {
          this.chiphHolidayType = index;
        }
      });
    } else {
      if (this.$route.query.holiday_type !== undefined) {
        this.selectedholidayType = this.$route.query.holiday_type;

        this.holidayTypes.map((item, index) => {
          if (item.id === this.propsHolidayType) {
            this.chiphHolidayType = index;
          }
        });
      }
    }


    this.$root.$on("geburtstag", (gruppe, id, date) => {


      let age = getAge(date, this.selectedenddate);

      this.gruppen[gruppe].kinderalter[id].age = age.toString();
      this.gruppen[gruppe].kinderalter[id].date = date;
    });


    if (this.groups === "") {
      this.groups = this.$route.query.groups;
    }
    if (this.groups !== undefined) {
      let split = this.groups.split("&groups[]=");
      //2,3,5
      if (split.length === 2) {
        split.map((item, index) => {
          if (index !== 0) {
            let gruppe = item.split(",");
            let kinder = [];
            gruppe.map((it, ind) => {
              if (ind !== 0) {
                kinder.push({age: it, date: ""});
              }
            });
            let element = {
              erwachsene: parseInt(gruppe[0]),
              kinderalter: kinder,
              kinder: 1,
              name: "Gruppe 1"
            };
            if (split.length === 2) {
              this.gruppen = [element];
              // this.anzahlPersonen = parseInt(gruppe[0]) + kinder.length;
            } else {
              // this.anzahlPersonen += parseInt(gruppe[0]) + kinder.length;
              this.gruppen.push(element);

            }

            if (gruppe.length > 2) {
              let kinder = [];
              gruppe.map((it, ind) => {
                if (ind !== 0) {
                  kinder.push({age: it, date: ""});
                }
              });
              let element = {
                erwachsene: parseInt(gruppe[0]),
                kinderalter: kinder,
                kinder: kinder.length,
                name: "Gruppe " + (index + 1)
              };
              if (split.length === 2)
                this.gruppen = [element];
              else {
                this.gruppen.push(element);

              }
            }
          } else {
            this.gruppen = [];
          }
        });
      }
    }


    // this.gruppen = JSON.parse(localStorage.getItem("gruppe"));
    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
      this.gruppen = JSON.parse(localStorage.getItem("gruppe"));
    } else {
      this.gruppen = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }

    this.gruppen.map(item => {
      this.anzahlPersonen += item.erwachsene;
      this.anzahlPersonen += item.kinderalter.length;
    })


    if (this.anzahlPersonen === 0) {
      this.anzahlPersonen = 2;

    }

    let value = "";
    let url = "";
    this.gruppen.map((item, index) => {
      let groups = "";
      if (index > 0)
        UrlUtil.bind(url);
      value = UrlUtil.binddata(value);

      let erw = item.erwachsene;
      let kinder = item.kinder;

      if (kinder > 0) {
        item.kinderalter.map(it => {
          if (groups !== "")
            groups = groups.concat(",");

          groups = groups.concat(it.age.toString());
        });
      }

      if (kinder > 0) {
        url = UrlUtil.addGroups(url, erw + "," + groups);
        value = UrlUtil.addGroupsData(value, erw + "," + groups);
      } else {
        url = UrlUtil.addGroups(url, erw);
        value = UrlUtil.addGroupsData(value, erw);
      }


    });

    this.newgroups = value;

    this.$root.$emit("groups", this.newgroups);


    if (localStorage.getItem('c-out') === "null" || localStorage.getItem('c-out') === undefined || localStorage.getItem('c-out') === null) {
      localStorage.removeItem('c-in');
      localStorage.removeItem('c-out');
      this.selectedcheckin = "";
      this.selectedstartdate = "";

      this.selectedcheckout = "";
      this.selectedenddate = "";

    }


  }

}

</script>

<style scoped>
@import "../../css/Search.css";
</style>